import React from "react";
import { Button } from "ffbad-components";

interface IProps {
  onCancel: () => void;
  onSave: () => void;
}

const Actions: React.FC<IProps> = (props: IProps) => {
  const { onCancel, onSave } = props;

  const buttons = [
    {
      label: "Annuler",
      onClick: onCancel,
    },
    {
      label: "Sauvegarder",
      onClick: onSave,
    },
  ];

  return (
    <div className="sponsor-form__actions">
      {buttons.map((button) => (
        <Button
          key={button.label}
          onClick={button.onClick}
          safetySpacing={false}
          isEditor
        >
          {button.label}
        </Button>
      ))}
    </div>
  );
};

export default Actions;
