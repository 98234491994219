import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { ChromePicker as ColorPicker, ColorResult } from "react-color";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";

import { IWebsiteConfig } from "core/interfaces.config";
import { ConfigService } from "services";
import {
  DISPLAY_NOTIFICATION,
  SET_CONFIGURATION,
} from "core/redux/reducer/main.reducer";
import useMainState from "hooks/useMainState";
import {
  buildClassName,
  applyWebsiteConfiguration,
  updateWebsiteColor,
} from "utils/dom/dom.utils";

import "./index.scss";

const service = new ConfigService();

const INDEXES = ["primaire", "secondaire", "texte", "à définir", "à définir"];

// https://casesandberg.github.io/react-color/#usage-include
const Colors: React.FC = () => {
  const dispatch = useDispatch();
  const { websiteConfiguration } = useMainState();
  const [colors, setColors] = useState<string[]>([]);
  const [currentColor, setCurrentColor] = useState<string>();
  const [canSave, setCanSave] = useState(false);
  const [selectedColorIndex, setSelectedColorIndex] = useState<
    number | undefined
  >();

  const handleOnCancel = useCallback(() => {
    if (!websiteConfiguration) {
      return;
    }

    const {
      configuration: { colors },
    } = websiteConfiguration;
    const newColors = [colors.primary, colors.secondary, colors.text];
    setColors(newColors);
    updateColors(newColors);
    setCanSave(false);
    setSelectedColorIndex(undefined);
    setCurrentColor(undefined);
  }, [websiteConfiguration]);

  useEffect(() => {
    handleOnCancel();
    return handleOnCancel;
  }, [handleOnCancel]);

  const handleOnSelectColorIndex = (index: number) => {
    if (selectedColorIndex === index) {
      setSelectedColorIndex(undefined);
    } else {
      setSelectedColorIndex(index);
    }

    setCurrentColor(colors[index]);
  };

  const handleOnSelectColor = (color: ColorResult) => {
    setCurrentColor(color.hex);
    if (selectedColorIndex === undefined) {
      return;
    }

    const newColors = [...colors];
    newColors[selectedColorIndex] = color.hex;
    setColors(newColors);
    setCanSave(true);

    updateColors(newColors);
  };

  const updateColors = (newColors: string[]) => {
    const [primary, secondary, text] = newColors;
    updateWebsiteColor("primary", primary);
    updateWebsiteColor("secondary", secondary);
    updateWebsiteColor("text", text);
  };

  const handleOnSave = () => {
    setCanSave(false);
    setSelectedColorIndex(undefined);
    setCurrentColor(undefined);

    const [primary, secondary, text] = colors;
    const newConfiguration: IWebsiteConfig = {
      ...(websiteConfiguration as IWebsiteConfig),
    };
    newConfiguration.configuration.colors = {
      primary,
      secondary,
      text,
    };

    const { promise } = service.updateWebsiteConfiguration(newConfiguration);
    promise.then((updatedConfiguration) => {
      dispatch({
        type: DISPLAY_NOTIFICATION,
        payload: {
          message: "Les couleurs ont bien été sauvegardées",
        },
      });

      applyWebsiteConfiguration(updatedConfiguration);
      dispatch({ type: SET_CONFIGURATION, payload: updatedConfiguration });
    });
  };

  return (
    <div className="colors">
      <h2>Les couleurs de votre site</h2>
      <p>Cliquez sur une couleur pour la modifier</p>

      <div className="colors__listing">
        {colors.map((color, index) => {
          const className = buildClassName("color", [
            [index === selectedColorIndex, "selected"],
          ]);

          return (
            <div
              key={index}
              onClick={() => handleOnSelectColorIndex(index)}
              className={className}
              style={{ backgroundColor: color }}
              title="Modifier la couleur"
            >
              <div className="color--name">{INDEXES[index]}</div>
              <div className="color--color">{color}</div>
            </div>
          );
        })}
        <div
          role="button"
          tabIndex={canSave ? 0 : undefined}
          className="colors__save-button"
          data-disabled={!canSave}
          title="Sauvegarder les modifications"
          onClick={handleOnSave}
        >
          <FontAwesomeIcon icon={faSave as any} />
        </div>
        &nbsp;
        <div
          role="button"
          tabIndex={canSave ? 0 : undefined}
          className="colors__save-button"
          data-disabled={!canSave}
          title="Annuler les modifications"
          onClick={handleOnCancel}
        >
          <FontAwesomeIcon icon={faTimes as any} />
        </div>
      </div>
      <div
        className="colors__picker"
        data-open={selectedColorIndex !== undefined}
      >
        <ColorPicker
          color={currentColor}
          onChange={handleOnSelectColor}
          onChangeComplete={handleOnSelectColor}
        />
      </div>
    </div>
  );
};

export default Colors;
