import React, { useState, useEffect } from "react";

import {
  ICreateWizardStep05,
  ICreateWizardStep04
} from "core/interfaces/createWizard/createWizard.interface";
import { getUrlFromFile } from "utils/file/file.utils";
import ColorChooser from "./components/ColorChooser";

import "./index.scss";

interface IProps {
  defaultData: ICreateWizardStep05;
  onChange: (data: ICreateWizardStep05) => void;
  logoUrl?: string;
  step04data: ICreateWizardStep04;
}

const Step05: React.FC<IProps> = (props: IProps) => {
  const { logoUrl, step04data } = props;
  const [imageUrl, setImageUrl] = useState<string>();

  useEffect(() => {
    if (step04data.newFile) {
      const url = getUrlFromFile(step04data.newFile);
      setImageUrl(url);
    } else if (logoUrl) {
      setImageUrl(logoUrl);
    }
  }, [logoUrl, step04data]);

  return (
    <div className="step-container step-container-step05">
      <img src={imageUrl} alt="Votre logo" />

      <p>
        Utilisez les couleurs de votre logo pour créer une palette de couleurs
        qui sera utilisée pour générer le thème du site de votre club. Voici la
        palette que nous vous recommendons :
      </p>

      {step04data.colors && (
        <div className="colors-container">
          {step04data.colors.map(color => (
            <ColorChooser key={color} color={color} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Step05;
