import React, { useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from "quill-image-resize-module-react";

import { IFormInput } from "components/FormBuilder/interfaces";

import "react-quill/dist/quill.snow.css";
import "./index.scss";

interface IProps {
  field: IFormInput;
  value: string;
  onChange: (key: string, value: string | number) => void;
}

let Image = Quill.import("formats/image");
Image.className = "text-editor-image";
Quill.register(Image, true);
Quill.register("modules/ImageResize", ImageResize);

const TextEditor: React.FC<IProps> = (props: IProps) => {
  const { value, field, onChange } = props;
  const [quillRef, setQuillRef] = useState<any>();

  const handleOnChange = (content: any) => {
    onChange(field.id, content);
  };

  const imageHandler = () => {
    const url = prompt("Veuillez entrer le lien de votre image");
    const range = quillRef.getEditor().getSelection();
    if (url && url !== "") {
      quillRef.getEditor().insertEmbed(range.index, "image", url, "user");
      quillRef.getEditor().setSelection(range.index + 1);
    }
  };

  const modules = React.useMemo(
    () => ({
      ImageResize: {
        parchment: Quill.import("parchment"),
      },
      toolbar: {
        container: [
          ["bold", "italic", "underline", "strike"],
          [{ list: "ordered" }, { list: "bullet" }],
          ["link", "image"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [quillRef]
  );

  return (
    <div className="text-editor-container">
      <ReactQuill
        value={value}
        style={{ height: "300px" }}
        ref={(el) => {
          setQuillRef(el);
        }}
        onChange={handleOnChange}
        theme={"snow"}
        modules={modules}
      />
    </div>
  );
};

export default TextEditor;
