import { MasterService } from "services";
import { IUsefulLink } from "services/service.interfaces";

class UsefulLinkService {
  private master: MasterService;

  constructor() {
    this.master = new MasterService();
  }

  all = (
    websiteId: string
  ): { promise: Promise<IUsefulLink[]>; cancel: any } => {
    return this.master.get<IUsefulLink[]>(`websites/${websiteId}/useful-links`);
  };

  find = (
    websiteId: string,
    usefulLinkId: string
  ): { promise: Promise<IUsefulLink>; cancel: any } => {
    return this.master.get<IUsefulLink>(
      `websites/${websiteId}/useful-links/${usefulLinkId}`
    );
  };

  remove = (
    websiteId: string,
    usefulLinkId: string
  ): { promise: Promise<any>; cancel: any } => {
    return this.master.delete(
      `websites/${websiteId}/useful-links/${usefulLinkId}`
    );
  };

  save = (
    websiteId: string,
    data: any
  ): { promise: Promise<IUsefulLink>; cancel: any } => {
    const body = { websiteId, ...data };
    let request;

    if (!!data.id) {
      const route = `websites/${websiteId}/useful-links/${data.id}`;
      request = this.master.put<IUsefulLink>(route, body);
    } else {
      const route = `websites/${websiteId}/useful-links`;
      request = this.master.post<IUsefulLink>(route, body);
    }

    return request;
  };
}

export default UsefulLinkService;
