import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import "./index.scss";

interface IProps {
  icon: IconProp;
  positions?: IPosition;
  onClick: () => void;
  title?: string;
}

interface IPosition {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

const FloatingIcon: React.FC<IProps> = (props: IProps) => {
  const { icon, positions = {}, onClick, title = "" } = props;
  const style: React.CSSProperties = {
    top: positions.top,
    right: positions.right,
    bottom: positions.bottom,
    left: positions.left
  };

  return (
    <div
      className="floating-icon-container"
      role="button"
      tabIndex={0}
      onClick={onClick}
      style={style}
      title={title}
    >
      <FontAwesomeIcon icon={icon} />
    </div>
  );
};

export default FloatingIcon;
