import { LocalStorageService } from "services";
import { ROUTE_PATHS, ROUTE_KEYS } from "core/router/route.keys";

const localStorageService = new LocalStorageService();

const isPrivateRoute = (history: any): void => {
  const person = localStorageService.getPerson();
  const { pathname } = document.location;
  const shouldRedirect = !person || !person.player || !person.player.instance;

  if (shouldRedirect) {
    localStorageService.removePerson();
    const returnUrl = pathname !== "/" ? `?returnUrl=${pathname}` : "";
    history.push(ROUTE_PATHS[ROUTE_KEYS.LOGIN] + returnUrl);
  }
};

export default isPrivateRoute;
