import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  faEdit,
  faTrash,
  faSpinner,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { DISPLAY_NOTIFICATION } from "core/redux/reducer/main.reducer";
import { ROUTE_KEYS } from "core/router/route.keys";
import NewsService from "services/news/news.service";
import useMainState from "hooks/useMainState";
import { buildRoute } from "utils/route.utils";

interface IProps {
  aNewsId: string;
  isInCarrousel?: boolean;
  onRefresh: () => void;
}

const CONFIRM_MESSAGE =
  "Voulez-vous supprimer définitivement cette actualité ?";

const NewsOverlay: React.FC<IProps> = (props: IProps) => {
  const { websiteConfiguration } = useMainState();
  const newsService = useRef(new NewsService());
  const [loading, setLoading] = useState(false);
  const { aNewsId, isInCarrousel = false, onRefresh } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const handleOnEdit = () => {
    const route = buildRoute(ROUTE_KEYS.NEWS_EDIT, { id: aNewsId });
    history.push(route);
  };

  const handleOnRemove = () => {
    if (!websiteConfiguration || !window.confirm(CONFIRM_MESSAGE)) {
      setLoading(false);
      return;
    }

    setLoading(true);

    newsService.current
      .remove(websiteConfiguration.id, aNewsId)
      .promise.then(() => {
        onRefresh();
        dispatch({
          type: DISPLAY_NOTIFICATION,
          payload: { message: "L'actualité a bien été supprimée" },
        });
      })
      .catch(() => {
        dispatch({
          type: DISPLAY_NOTIFICATION,
          payload: {
            message: "L'actualité n'a pas pu être supprimée",
            type: "danger",
          },
        });
      })
      .finally(() => setLoading(false));
  };

  const actions = [
    { title: "Editer", icon: faEdit, onClick: handleOnEdit },
    { title: "Supprimer", icon: faTrash, onClick: handleOnRemove },
  ];

  return (
    <div className="news-overlay">
      {loading && (
        <FontAwesomeIcon
          className="news-overlay--loading"
          icon={faSpinner as any}
          spin
        />
      )}

      {isInCarrousel && (
        <div
          className="news-overlay--star"
          title="Cette actualité est mise en avant (carrousel)"
        >
          <FontAwesomeIcon icon={faStar as any} />
        </div>
      )}

      {!loading &&
        actions.map((action) => (
          <div
            key={action.title}
            className="news-overlay--action"
            title={action.title}
            onClick={action.onClick}
          >
            <FontAwesomeIcon icon={action.icon as any} />
          </div>
        ))}
    </div>
  );
};

export default NewsOverlay;
