import React, { useState, useRef, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";

import { ROUTE_PATHS, ROUTE_KEYS } from "core/router/route.keys";
import { DISPLAY_NOTIFICATION } from "core/redux/reducer/main.reducer";
import { UsefulDocumentService } from "services";
import { useMainState, usePerson } from "hooks";
import { FormBuilder, Loading } from "components";
import Label from "components/FormBuilder/components/Label";
import Actions from "./components/Actions";
import fields from "./fields.definition";

interface IProps { }
const types = ['pdf', 'png', 'jpeg', 'gif', 'jpg', 'svg',]

const UsefulLDocumentsForm: React.FC<IProps> = (props: IProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<{id?:any}>();
  const [data, setData] = useState({ title: '' });
  const [file, setFile] = useState<File>();
  const [loading, setLoading] = useState(false);
  const [fileURL, setFileURL] = useState('');
  const [displayIframe, setDisplayIframe] = useState(false);
  const { websiteConfiguration } = useMainState();
  const usefulDocumentService = useRef(new UsefulDocumentService());
  const isEditMode = !!id;
  const person = usePerson();
  const inputFileRef = useRef<any>();
  const { REACT_APP_CDN_URL } = process.env;

  const handleOnCancel = () => {
    history.push(ROUTE_PATHS[ROUTE_KEYS.USEFUL_DOCUMENTS]);
  };

  useEffect(() => {
    if (isEditMode && websiteConfiguration) {
      usefulDocumentService.current
        .find(websiteConfiguration.id, id)
        .promise.then((response) => {
          setFileURL(`${REACT_APP_CDN_URL}${response.url}`)
          setDisplayIframe(isFileTypeForIframe(response.url));
        })
        .catch(() => {
          setLoading(false);
          dispatch({
            type: DISPLAY_NOTIFICATION,
            payload: {
              message: "Le document n'a pas pu être chargé",
              type: "danger",
            },
          });
        });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, isEditMode, websiteConfiguration])

  const handleOnSave = () => {
    if (!websiteConfiguration) {
      return;
    }

    const formData = new FormData();
    formData.append("label", data.title);
    formData.append("ownerId", person.player.instance.instanceId);
    if (file) {
      formData.append("document", file);
    }

    const error = checkUsefulDocument(data, file);
    if (error) {
      dispatch({
        type: DISPLAY_NOTIFICATION,
        payload: {
          message: error,
          type: "danger",
        },
      });
      return;
    }

    setLoading(true);
    let promise = new Promise((resolve, reject) => { });
    if (isEditMode) {
      promise = usefulDocumentService.current.update(websiteConfiguration.id, id, data.title).promise;
    } else {
      promise = usefulDocumentService.current.upload(websiteConfiguration.id, formData).promise;
    }
    promise.then(() => {
      dispatch({
        type: DISPLAY_NOTIFICATION,
        payload: {
          message: `Le document utile a bien été ${isEditMode ? "mis à jour" : "créé"
            }`,
        },
      });
      handleOnCancel();
    })
      .catch(() => {
        setLoading(false);
        dispatch({
          type: DISPLAY_NOTIFICATION,
          payload: {
            message: `Le document utile n'a pas pu être ${isEditMode ? "mis à jour" : "créé"
              }`,
            type: "danger",
          },
        });
      });
  };

  const handleOnChangeTitle = (data: any) => {
    setData(data);
  };

  const handleOnChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.currentTarget.files) {
      return;
    }
    setFile(e.currentTarget.files[0])
  }

  useEffect(() => {
    if (!websiteConfiguration || !id) {
      return;
    }

    const { promise, cancel } = usefulDocumentService.current.find(
      websiteConfiguration.id,
      id
    );
    promise.then((response) => {
      setLoading(false);

      setData({
        title: response.label,
      });
    });

    return () => cancel();
  }, [websiteConfiguration, id]);

  return (
    <div className="useful-documents-form">
      <h1>{isEditMode ? "Edition" : "Création"} d'un document utile</h1>

      {loading && <Loading />}

      {!loading && (
        <>
          <FormBuilder
            items={fields}
            defaultData={data}
            onChange={handleOnChangeTitle}
          />
          {isEditMode ?
            <div className="form-element-container">
              <Label htmlFor="fileLabel" >
                Visualisation du document
              </Label>
              {
                // eslint-disable-next-line jsx-a11y/iframe-has-title
                fileURL !== '' && displayIframe ? <iframe className="iframe" src={fileURL}></iframe>
                  :
                  <>Le document ne peut pas etre visualisé</>
              }
            </div>
            :
            <div key="fileLabel" className="form-element-container">
              <Label htmlFor="fileLabel" >
                Choisir un document
              </Label>

              <div className="input-icon-container">
                <FontAwesomeIcon icon={faFile as any} />

                <input
                  ref={inputFileRef}
                  type="file"
                  onChange={handleOnChangeFile}
                  style={{ marginLeft: '20px' }}
                />
              </div>
            </div>
          }

          <Actions onCancel={handleOnCancel} onSave={handleOnSave} />
        </>
      )
      }
    </div >
  );
};

export default UsefulLDocumentsForm;

const checkUsefulDocument = (data: any, file: any): string | undefined => {
  if (!data.title) {
    return "Merci de saisir un nom";
  }

  if (!file) {
    return "Merci de choisir un fichier";
  }
};

const isFileTypeForIframe = (url: string): boolean => {
  const words = url.split('.');
  const type = words[words.length - 1];
  return types.includes(type);
}
