import { FileService } from "services";

const getColors = require("get-image-colors");
const fileService = new FileService();

export const getColorsFromFile = (file: File): Promise<string[]> => {
  const url = getUrlFromFile(file);

  return new Promise(resolve => {
    getColors(url).then((colors: any) => {
      const hexColors = colors.map((color: any) => color.hex());
      resolve(hexColors);
    });
  });
};

export const getColorsFromUrl = async (url: string): Promise<string[]> => {
  const buffer: any = await fileService
    .downloadFile(url)
    .then(response => response.blob())
    .then((blob: any) => URL.createObjectURL(blob));

  return new Promise(resolve => {
    getColors(buffer).then((colors: any) => {
      const hexColors = colors.map((color: any) => color.hex());
      resolve(hexColors);
    });
  });
};

export const getUrlFromFile = (file: File): string => URL.createObjectURL(file);
