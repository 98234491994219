import React, { useCallback, useEffect, useState } from "react";

import GalleryService from "services/gallery/gallery.service";
import { IAlbum } from "services/service.interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { DISPLAY_NOTIFICATION } from "core/redux/reducer/main.reducer";

interface IProps {
  setImagePath: (path: string) => void;
  imagePath: string;
}
const AlbumService = new GalleryService();
const { REACT_APP_CDN_URL } = process.env;

const SelectImage: React.FC<IProps> = (props: IProps) => {
  const { setImagePath, imagePath } = props;
  const dispatch = useDispatch();
  const [albums, setAlbums] = useState<IAlbum[]>();
  const [pictures, setPictures] = useState<any[]>([]);
  const [selectedAlbumId, setSelectedAlbumId] = useState<string>();

  useEffect(() => {
    const { promise } = AlbumService.all();
    promise.then((albums: IAlbum[]) => {
      setAlbums(albums);
      setSelectedAlbumId(albums[0].id);
      fetchPictures();
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPictures = useCallback(() => {
    if (!selectedAlbumId) {
      return;
    }
    const { promise, cancel } = AlbumService.findPictures(selectedAlbumId);
    promise
      .then((tempPictures) => {
        setPictures(tempPictures);
      })
      .catch(() => {
        dispatch({
          type: DISPLAY_NOTIFICATION,
          payload: { message: "Erreur de chargement", type: "danger" },
        });
      });
    return cancel;
  }, [selectedAlbumId, dispatch]);

  useEffect(() => {
    fetchPictures();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAlbumId]);

  const handleOnSelectImage = (picture: any) => {
    setImagePath(picture.path);
  };

  const handleOnAlbumChange = (event: any) => {
    setSelectedAlbumId(event.target.value);
  };

  return (
    <>
      <select onChange={handleOnAlbumChange}>
        {albums?.map((album) => {
          return (
            <option key={album.id} value={album.id}>
              {album.title}
            </option>
          );
        })}
      </select>

      <div className="photos-container">
        <div className="listing">
          {pictures && pictures.length === 0 && (
            <p>
              <FontAwesomeIcon icon={faExclamationTriangle as any} />
              &nbsp;Il n'y a aucune photo dans cette galerie
            </p>
          )}

          {pictures.map((picture) => (
            <div key={picture.id} onClick={() => handleOnSelectImage(picture)}>
              <div className="listing--item">
                <div
                  className="listing--background"
                  data-selected={imagePath === picture.path}
                  style={{
                    backgroundImage: `url("${REACT_APP_CDN_URL}${picture.path}")`,
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default SelectImage;
