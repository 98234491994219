import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner, faCheck } from "@fortawesome/free-solid-svg-icons";

import { ConfigService, InstanceService } from "services";
import { ROUTE_KEYS } from "core/router/routes.config";
import { ICreateWizard } from "core/interfaces/createWizard/createWizard.interface";
import { usePerson } from "hooks";
import { buildRoute } from "utils/route.utils";

import "./index.scss";

interface IProps {
  data: ICreateWizard;
}

// Used to let user see the confirmation message.
const DEFAULT_TIMING_BEFORE_REDIRECT = 500;
const configService = new ConfigService();
const instanceService = new InstanceService();

const Finalisation: React.FC<IProps> = (props: IProps) => {
  const { data } = props;
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const person = usePerson();

  const isDefaultUrl = (url?: string) => {
    if (url)
      return url.includes('.ffbad.club') || url.includes('ffbad.mobi');
  }

  const getDefaultUrl = (initials?: string) => {
    if (initials) {
      if (process.env.REACT_APP_ENV === "qual") {
        return initials + ".ffbad.mobi";
      } else if (process.env.REACT_APP_ENV === "prod") {
        return initials + ".ffbad.club";
      } else if (process.env.REACT_APP_ENV === "dev") {
        return "localhost";
      }
    }
  }

  useEffect(() => {
    const { instanceId } = person.player.instance;


    const { promise, cancel } = instanceService.light(Number(instanceId));
    promise.then((anInstance) => {
      if (!isDefaultUrl(data.step01.siteUrl))
        data.step01.defaultUrl = getDefaultUrl(anInstance.initials.toLowerCase());
      const { promise, cancel } = configService.setWebsiteConfiguration(
        data,
        instanceId
      );
      promise
        .then(() => {
          setIsLoading(false);
          setTimeout(() => {
            const redirectUrl = buildRoute(ROUTE_KEYS.HOME);
            history.push(redirectUrl);
          }, DEFAULT_TIMING_BEFORE_REDIRECT);
        })
        .catch((err) => console.error(err));

      return () => cancel();
    });
    return () => cancel();

    // eslint-disable-next-line
  }, []);

  return (
    <div className="step-finalisation">
      <div className="step-finalisation-title">
        {isLoading && <span>Préparation de la version de votre site...</span>}
        {!isLoading && (
          <span>
            Votre site à bien été configuré, une redirection est en cours...
          </span>
        )}
      </div>

      <FontAwesomeIcon
        icon={(isLoading ? faSpinner : faCheck) as any}
        pulse={isLoading}
        size="2x"
      />
    </div>
  );
};

export default Finalisation;
