import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

import { IPage } from "services/service.interfaces";

import "./styles.scss";

interface IProps {
  page: IPage;
  handleOnAdd: () => void;
}

const DisplayPageNotInMenu: React.FC<IProps> = (props: IProps) => {
  const { page, handleOnAdd } = props;

  return (
    <div
      className="page-not-in-menu"
      title="Ajouter la page au menu"
      onClick={handleOnAdd}
    >
      <span className="page-not-in-menu--icon">
        <FontAwesomeIcon icon={faPlusCircle as any} />
      </span>
      <span className="page-not-in-menu--label">{page.label}</span>
    </div>
  );
};

export default DisplayPageNotInMenu;
