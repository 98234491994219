import React, { useEffect, useState } from "react";

import { ICreateWizardStep02 } from "core/interfaces/createWizard/createWizard.interface";
import { IInstance } from "core/interfaces/instance.interface";
import { InstanceService } from "services";
import { IPersonLicence } from "services/localStorage/localStorage.service";
import { FormBuilder, Loading } from "components";
import fields, { KEYS } from "./fields.definition";

import "./index.scss";

interface IProps {
  defaultData: ICreateWizardStep02;
  onChange: (data: ICreateWizardStep02) => void;
  person: IPersonLicence;
}

const instanceService = new InstanceService();
const FORM_ID = "form-builder-step02";

const Step02: React.FC<IProps> = (props: IProps) => {
  const { defaultData, onChange, person } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [instance, setInstance] = useState<IInstance>();

  useEffect(() => {
    const {
      player: {
        instance: { instanceId },
      },
    } = person;

    const { promise, cancel } = instanceService.light(Number(instanceId));
    promise.then((anInstance) => {
      setInstance(anInstance);
      setIsLoading(false);
    });

    return () => cancel();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!instance) {
      return;
    }

    const newDefaultValue = buildDefaultValue(instance);
    onChange({ ...defaultData, ...newDefaultValue });
    // eslint-disable-next-line
  }, [instance]);

  return (
    <div className="step-container">
      {isLoading && <Loading />}

      {!isLoading && instance && (
        <div>
          <img
            className="step-02-logo"
            src={instance.logo}
            alt={`Logo de ${instance.name}`}
          />

          <FormBuilder
            id={FORM_ID}
            items={fields}
            onChange={onChange}
            defaultData={defaultData}
          />
          <div className="formDetails">
            *Ces informations proviennent de Poona. Pour effectuer un changement veuillez vous rendre sur le site https://poona.ffbad.org/.
          </div>
        </div>
      )}
    </div>
  );
};

const buildDefaultValue = (instance: IInstance) => {
  return {
    [KEYS.CLUB_NAME]: instance.name,
    [KEYS.CLUB_EMAIL]: instance.email,
    [KEYS.CLUB_FAX]: instance.fax,
    [KEYS.CLUB_MOBILE]: instance.mobile,
    [KEYS.CLUB_LOGO]: instance.logo,
  };
};

export default Step02;
