import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { faEdit, faTrash, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { DISPLAY_NOTIFICATION } from "core/redux/reducer/main.reducer";
import { ROUTE_KEYS } from "core/router/route.keys";
import { useMainState } from "hooks";
import { buildRoute } from "utils/route.utils";
import { UsefulDocumentService } from "services";

interface IProps {
  aDocumentId: string;
  onRefresh: () => void;
}

const CONFIRM_MESSAGE = "Voulez-vous supprimer définitivement ce document utile ?";

const UsefulDocumentOverlay: React.FC<IProps> = (props: IProps) => {
  const { websiteConfiguration } = useMainState();
  const usefulDocumentService = useRef(new UsefulDocumentService());
  const [loading, setLoading] = useState(false);
  const { aDocumentId, onRefresh } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const handleOnEdit = () => {
    const route = buildRoute(ROUTE_KEYS.USEFUL_DOCUMENTS_EDIT, { id: aDocumentId });
    history.push(route);
  };

  const handleOnRemove = () => {
    if (!websiteConfiguration || !window.confirm(CONFIRM_MESSAGE)) {
      setLoading(false);
      return;
    }

    setLoading(true);

    usefulDocumentService.current
      .remove(websiteConfiguration.id, aDocumentId)
      .promise.then(() => {
        onRefresh();
        dispatch({
          type: DISPLAY_NOTIFICATION,
          payload: { message: "Le lien utile a bien été supprimé" },
        });
      })
      .catch(() => {
        dispatch({
          type: DISPLAY_NOTIFICATION,
          payload: {
            message: "Le lien utile n'a pas pu être supprimé",
            type: "danger",
          },
        });
      })
      .finally(() => setLoading(false));
  };

  const actions = [
    { title: "Editer", icon: faEdit, onClick: handleOnEdit },
    { title: "Supprimer", icon: faTrash, onClick: handleOnRemove },
  ];

  return (
    <div className="useful-document-overlay">
      {loading && (
        <FontAwesomeIcon
          className="useful-document-overlay--loading"
          icon={faSpinner as any}
          spin
        />
      )}

      {!loading &&
        actions.map((action) => (
          <div
            key={action.title}
            className="useful-document-overlay--action"
            title={action.title}
            onClick={action.onClick}
          >
            <FontAwesomeIcon icon={action.icon as any} />
          </div>
        ))}
    </div>
  );
};

export default UsefulDocumentOverlay;
