import React from "react";
import { useDispatch } from "react-redux";
import { IPageComponentProperties, ComponentTypes } from "ffbad-components";

import { SET_LEFT_MENU_ACTION } from "core/redux/reducer/leftMenu.reducer";
import {
  REFRESH_EDITOR_STATE,
  SET_COMPONENT_TO_EDIT,
  SET_EXISTING_COMPONENT_ID,
  IEditorState,
} from "core/redux/reducer/editor.reducer";
import { essentialElements, advancedElements } from "./element.mappings";
import DisplayAvailableElements from "./components/DisplayAvailableElements";
import { MENU_ACTIONS } from "components/PageRenderer/menuActions.mapping";
import { usePageBuilder, useEditorState } from "hooks";
import { randomString } from "utils/string/string.utils";

const TITLE_ADD = "Ajout d'un élément";
const TITLE_EDIT = "Modification du type de l'élément";

interface IProps {
  canPublish?: boolean;
  canDelete?: boolean;

  // Handlers.
  onPublish: () => void;
  onDelete?: () => void;
}

const ElementChooser: React.FC<IProps> = (props: IProps) => {
  const { canPublish = false, onPublish, canDelete = false, onDelete } = props;
  const dispatch = useDispatch();
  const pageBuilder = usePageBuilder();
  const editorState = useEditorState();

  const handleOnSelectElement = (element: IPageComponentProperties) => {
    if (!editorState.existingComponentId) {
      return;
    }

    const elementToAdd: IPageComponentProperties = {
      ...element,
      props: pageBuilder.findDefaultPropsFor(element.type),
    };

    const { container, children } = wrapElement(
      elementToAdd,
      handleOnWantToAddAnotherElement
    );

    if (shouldReplaceComponent(editorState)) {
      const [firstChild] = children;
      const componentToReplace = editorState.componentToEdit as IPageComponentProperties;
      pageBuilder.replace(componentToReplace.id, firstChild);
    } else {
      pageBuilder.replace(editorState.existingComponentId, container);
      pageBuilder.appendAfter(container.id, children);
    }

    dispatch({ type: REFRESH_EDITOR_STATE });
    dispatch({ type: SET_COMPONENT_TO_EDIT, payload: elementToAdd });
    dispatch({
      type: SET_LEFT_MENU_ACTION,
      payload: {
        innerElementKey: MENU_ACTIONS.EDIT_COMPONENT,
        props: {
          canPublish: canPublish,
          canDelete: canDelete,
          onPublish: onPublish,
          onDelete: onDelete,
        },
      },
    });
  };

  const handleOnWantToAddAnotherElement = (newElementId: string) => {
    dispatch({ type: SET_EXISTING_COMPONENT_ID, payload: newElementId });
    dispatch({
      type: SET_LEFT_MENU_ACTION,
      payload: {
        innerElementKey: MENU_ACTIONS.ELEMENT_CHOOSER,
        props: {
          canPublish: canPublish,
          canDelete: canDelete,
          onPublish: onPublish,
          onDelete: onDelete,
        },
      },
    });
  };

  return (
    <>
      <h2>{editorState.isEditType ? TITLE_EDIT : TITLE_ADD}</h2>
      <p>Sélectionnez l'élément que vous souhaitez ajouter :</p>

      <DisplayAvailableElements
        title="Les essentiels"
        items={essentialElements}
        onSelectElement={handleOnSelectElement}
      />

      <DisplayAvailableElements
        title="Avancés"
        items={advancedElements}
        onSelectElement={handleOnSelectElement}
      />
    </>
  );
};

export default ElementChooser;

const wrapElement = (
  element: IPageComponentProperties,
  onAddElement: (elementId: string) => void
) => {
  element.id = randomString();

  const containerId = randomString();
  const addElementId = randomString();
  const container: IPageComponentProperties = {
    id: containerId,
    type: ComponentTypes.MultipleColumnsLayout,
    props: { flexDirection: "column" },
  };

  const children: IPageComponentProperties[] = [
    { ...element, parentId: containerId },
    {
      id: addElementId,
      type: ComponentTypes.AddElement,
      props: {
        light: true,
        onClick: () => onAddElement(addElementId),
      },
      parentId: containerId,
    },
  ];

  return {
    container,
    children,
  };
};

const shouldReplaceComponent = (editorState: IEditorState): boolean => {
  return editorState.isEditType && editorState.componentToEdit !== undefined;
};
