import React from "react";

import Button from "components/Button";

import "./index.scss";

interface IProps {
  canSkipStep?: boolean;
  canClickOnNextButton?: boolean;
  onNext: () => void;
  nextButtonLabel?: string;
}

const DEFAULT_NEXT_BUTTON_LABEL = "Suivant";

const BottomNavigation: React.FC<IProps> = (props: IProps) => {
  const {
    canSkipStep = false,
    canClickOnNextButton = true,
    onNext,
    nextButtonLabel = DEFAULT_NEXT_BUTTON_LABEL
  } = props;

  return (
    <div className="bottom-navigation-container">
      {canClickOnNextButton && canSkipStep && (
        <>
          <Button onClick={onNext} type="secondary">
            Passer
          </Button>
          &nbsp;
        </>
      )}

      {canClickOnNextButton && (
        <Button onClick={onNext} type="primary">
          {nextButtonLabel}
        </Button>
      )}
    </div>
  );
};

export default BottomNavigation;
