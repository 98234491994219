import {
  ComponentTypes,
  IPageComponentProperties,
  IPageProperties,
} from "ffbad-components";
import { PageService, ConfigService } from "services";
import { randomString } from "utils/string/string.utils";

export const pageService = new PageService();
export const DEFAULT_PAGE_LABEL = "Nouvelle page";
export const configService = new ConfigService();

const LAYOUT_COMPONENTS = [
  ComponentTypes.MultipleColumnsLayout,
  ComponentTypes.MultipleColumnsLayoutOneBig,
  ComponentTypes.FlexContainer,
  ComponentTypes.FlexContent,
];

export const newPageIsValid = (finalPage: IPageProperties): boolean => {
  return finalPage.label !== undefined && finalPage.description !== undefined;
};

export const newPageLabelIsValid = (finalPage: IPageProperties): boolean => {
  return finalPage.label?.toLowerCase() !== "inscription";
};

export const isNewPageFromPredefined = (): boolean => {
  return window.location.pathname.includes("predefined");
};

export const checkIfOneSectionLeft = (
  components?: IPageComponentProperties[]
) => {
  if (!components) return;
  let result = false;

  components.forEach((component) => {
    if (LAYOUT_COMPONENTS.includes(component.type)) {
      if (!result) {
        result = true;
      }
    }
  });
  return result;
};

const restoreFooter = (components?: IPageComponentProperties[]) => {
  if (!components) return;
  let footer;
  let result = components;
  for (let index = 0; index < components.length; index++) {
    if (components[index].type === ComponentTypes.Footer) {
      footer = components[index];
    }
  }
  if (footer) {
    result = result.filter(
      (component) => component.type !== ComponentTypes.Footer
    );
    result.push(footer);
  }
  return result;
};

export const removeSectionWithoutChildren = (
  components?: IPageComponentProperties[]
) => {
  if (!components) return;
  const sections: IPageComponentProperties[] = [];
  components.forEach((component) => {
    if (component.id.includes("section")) {
      sections.push(component);
    }
  });
  const sectionsWithoutChildren: IPageComponentProperties[] = [];
  sections.forEach((section) => {
    let hasChildren = false;
    components.forEach((component) => {
      if (component.parentId === section.id) {
        hasChildren = true;
      }
    });
    if (!hasChildren) {
      sectionsWithoutChildren.push(section);
    }
  });
  const result: IPageComponentProperties[] = [];

  components.forEach((component) => {
    let isSelect = true;
    sectionsWithoutChildren.forEach((section) => {
      if (component.id === section.id) {
        isSelect = false;
      }
    });
    if (isSelect) {
      result.push(component);
    }
  });

  if (!checkIfOneSectionLeft(result)) {
    result.push({
      id: "section01" + randomString(),
      type: 2002,
      props: {
        flexDirection: "column",
      },
      parentId: "top",
    });
  }
  return restoreFooter(result);
};

export const hasAlreadyMainTitle = (
  components?: IPageComponentProperties[]
) => {
  if (!components) return;
  let result = false;
  components.forEach((component) => {
    if (component.id.includes("pageMainTitle")) {
      if (!result) {
        result = true;
      }
    }
  });
  return result;
};
