import React from "react";
import DisplaySelection from "./components/DisplaySelection";

const NewPageChooser: React.FC = () => {
  return (
    <>
      <h2>Ajout d'une page</h2>
      <DisplaySelection />
    </>
  );
};

export default NewPageChooser;
