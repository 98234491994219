import { IPageComponentProperties, ComponentTypes } from "ffbad-components";
import {
  faMouse,
  faImage,
  faAlignLeft,
  faAt,
  faShare,
  faFolderOpen,
  faNewspaper,
  faPoll,
  faUsers,
  faUser,
  faEuroSign,
  faHandshake,
  faHeading,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faGoogleDrive, faYoutube } from "@fortawesome/free-brands-svg-icons";

import { randomString } from "utils/string/string.utils";

export interface IElementMapping {
  label: string;
  icon: IconProp | IconDefinition;
  json: IPageComponentProperties;
}

const essentialElements: IElementMapping[] = [
  {
    label: "Titre",
    icon: faHeading,
    json: {
      id: randomString(),
      type: ComponentTypes.Title,
      props: { content: "Votre texte ici" },
    },
  },
  {
    label: "Bouton",
    icon: faMouse,
    json: {
      id: randomString(),
      type: ComponentTypes.Button,
      props: { children: "Cliquez ici", style: { width: 150, height: 30 } },
    },
  },
  {
    label: "Image",
    icon: faImage,
    json: {
      id: randomString(),
      type: ComponentTypes.FlexImage,
      props: {
        style: { height: 100 },
      },
    },
  },
  {
    label: "Zone de texte",
    icon: faAlignLeft,
    json: {
      id: randomString(),
      type: ComponentTypes.TextEditor,
      props: {},
    },
  },
  {
    label: "Fichier Google Drive",
    icon: faGoogleDrive,
    json: {
      id: randomString(),
      type: ComponentTypes.GoogleDrive,
      props: {
        url:
          "https://docs.google.com/document/d/e/2PACX-1vR4lQa3g0EnJfRNN_uqcyFdhJhn3DtUb7JCcDSTrNZE8myjSyZjENBT1ZESrj6fb9nsBusXxeBUtJUf/pub?embedded=true",
      },
    },
  },
];

const advancedElements: IElementMapping[] = [
  {
    label: "Vidéo YouTube",
    icon: faYoutube,
    json: {
      id: randomString(),
      type: ComponentTypes.YouTubePlayer,
      props: {},
    },
  },
  {
    label: "Composition du bureau",
    icon: faUser,
    json: {
      id: randomString(),
      type: ComponentTypes.Bureau,
      props: { displayPicture: true },
    },
  },
  {
    label: "Contact",
    icon: faAt,
    json: {
      id: randomString(),
      type: ComponentTypes.Contact,
      props: {},
    },
  },
  {
    label: "Equipe(s) inter-club",
    icon: faUsers,
    json: {
      id: randomString(),
      type: ComponentTypes.InterclubTeams,
      props: {},
    },
  },
  {
    label: "Rencontres",
    icon: faPoll,
    json: {
      id: randomString(),
      type: ComponentTypes.Meetings,
      props: {},
    },
  },
  {
    label: "Actualités",
    icon: faNewspaper,
    json: {
      id: randomString(),
      type: ComponentTypes.News,
      props: {},
    },
  },
  {
    label: "Carrousel",
    icon: faNewspaper,
    json: {
      id: randomString(),
      type: ComponentTypes.Carousel,
      props: {},
    },
  },
  {
    label: "Résultats inter-club",
    icon: faPoll,
    json: {
      id: randomString(),
      type: ComponentTypes.InterclubResults,
      props: {},
    },
  },
  {
    label: "Accès rapides",
    icon: faShare,
    json: {
      id: randomString(),
      type: ComponentTypes.QuickAccess,
      props: {},
    },
  },
  {
    label: "Documents utiles",
    icon: faFolderOpen,
    json: {
      id: randomString(),
      type: ComponentTypes.QuickDocumentAccess,
      props: {},
    },
  },
  {
    label: "Album photos",
    icon: faImage,
    json: {
      id: randomString(),
      type: ComponentTypes.Albums,
      props: {},
    },
  },
  {
    label: "Tarifs",
    icon: faEuroSign,
    json: {
      id: randomString(),
      type: ComponentTypes.Prices,
      props: { title: "Nos tarifs" },
    },
  },
  {
    label: "Sponsors",
    icon: faHandshake,
    json: {
      id: randomString(),
      type: ComponentTypes.Sponsors,
      props: { title: "Nos sponsors" },
    },
  },
];

export { essentialElements, advancedElements };
