export const ROUTE_KEYS = {
  HOME: "home",
  LOGIN: "login",
  WIZARD: "wizard",
  EDIT_PAGE: "edit-page",
  CREATE_PAGE: "create-page",
  CREATE_NEW_PAGE: "create-new-page",
  ERROR: "error",
  SETTINGS: "settings",
  MENU: "menu",
  GALLERY: "gallery",
  // News.
  NEWS: "news",
  NEWS_CREATE: "news-create",
  NEWS_EDIT: "news-edit",
  // Sponsors.
  SPONSORS: "sponsors",
  SPONSORS_CREATE: "sponsors-create",
  SPONSORS_EDIT: "sponsors-edit",
  // Useful links.
  USEFUL_LINKS: "useful-links",
  USEFUL_LINKS_CREATE: "useful-links-create",
  USEFUL_LINKS_EDIT: "useful-links-edit",
  // Useful documents.
  USEFUL_DOCUMENTS: "useful-documents",
  USEFUL_DOCUMENTS_CREATE: "useful-documents-create",
  USEFUL_DOCUMENTS_EDIT: "useful-documents-edit",
};

export const ROUTE_PATHS = {
  [ROUTE_KEYS.HOME]: "/",
  [ROUTE_KEYS.LOGIN]: "/connexion",
  [ROUTE_KEYS.WIZARD]: "/wizard",
  [ROUTE_KEYS.EDIT_PAGE]: "/editer-page/:pageId",
  [ROUTE_KEYS.CREATE_PAGE]: "/creer-page/:type?/:pageId?",
  [ROUTE_KEYS.CREATE_NEW_PAGE]: "/creer-page",
  [ROUTE_KEYS.ERROR]: "/erreur",
  [ROUTE_KEYS.SETTINGS]: "/parametres",
  [ROUTE_KEYS.MENU]: "/menu",
  [ROUTE_KEYS.GALLERY]: "/galeries-photos",
  // News.
  [ROUTE_KEYS.NEWS]: "/actualites",
  [ROUTE_KEYS.NEWS_CREATE]: "/actualites/creer",
  [ROUTE_KEYS.NEWS_EDIT]: "/actualites/editer/:id",
  // Sponsors.
  [ROUTE_KEYS.SPONSORS]: "/sponsors",
  [ROUTE_KEYS.SPONSORS_CREATE]: "/sponsors/creer",
  [ROUTE_KEYS.SPONSORS_EDIT]: "/sponsors/editer/:id",
  // Useful links.
  [ROUTE_KEYS.USEFUL_LINKS]: "/liens-utiles",
  [ROUTE_KEYS.USEFUL_LINKS_CREATE]: "/liens-utiles/creer",
  [ROUTE_KEYS.USEFUL_LINKS_EDIT]: "/liens-utiles/editer/:id",
  // Useful links.
  [ROUTE_KEYS.USEFUL_DOCUMENTS]: "/documents",
  [ROUTE_KEYS.USEFUL_DOCUMENTS_CREATE]: "/documents/creer",
  [ROUTE_KEYS.USEFUL_DOCUMENTS_EDIT]: "/documents/editer/:id",
};
