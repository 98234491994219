import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";

import { DISPLAY_NOTIFICATION } from "core/redux/reducer/main.reducer";
import GalleryService from "services/gallery/gallery.service";
import Actions from "../Actions";

interface IProps {
  gallery: any;
}

const service = new GalleryService();
const { REACT_APP_CDN_URL } = process.env;

const DisplayPhotos: React.FC<IProps> = (props: IProps) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [pictures, setPictures] = useState<any[]>([]);
  const [selectPicture, setSelectPicture] = useState<any>('');
  const { gallery } = props;

  const fetchPictures = useCallback(() => {
    setError(false);
    const { promise, cancel } = service.findPictures(gallery.id);
    promise
      .then((tempPictures) => {
        setPictures(tempPictures);
      })
      .catch(() => {
        dispatch({
          type: DISPLAY_NOTIFICATION,
          payload: { message: "Erreur de chargement", type: "danger" },
        });
        setError(true);
      });
    return cancel;
  }, [gallery, dispatch]);

  useEffect(() => {
    const cancel = fetchPictures();
    return () => cancel();
  }, [fetchPictures]);

  const handleOnRemove = (pictureId: string) => {
    if (gallery.title === "Actualites") {
      if (
        !window.confirm(
          "Attention cette image est peut etre liée à une actualité. Voulez-vous continuer ?"
        )
      ) {
        return;
      }
    }
    const { promise } = service.removePicture(gallery.id, pictureId);
    promise.then(fetchPictures).catch(() => {
      dispatch({
        type: DISPLAY_NOTIFICATION,
        payload: { message: "Erreur de suppression", type: "danger" },
      });
    });
  };

  const copyToClipboard = (str: string) => {
    if (!str.includes('undefined')) {
      const el = document.createElement('textarea');
      el.value = str;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      dispatch({
        type: DISPLAY_NOTIFICATION,
        payload: { message: "URL copiée dans le presse-papier" },
      });
    }
  };

  return (
    <>
      <h2>Les photos de {gallery.title}</h2>
      <div className="subTitle">(cliquez sur une photo pour récupérer son url)</div>

      {error && <p>Erreur lors du chargement de vos photos</p>}

      {!error && (
        <div className="photos-container">
          <Actions gallery={gallery} onRefresh={fetchPictures} />
          <div className="url" onClick={() => copyToClipboard(`${REACT_APP_CDN_URL}${selectPicture.path}`)}>
            {selectPicture !== '' ? `${REACT_APP_CDN_URL}${selectPicture.path}` : 'URL d\'une image'}
          </div>
          <div className="listing">
            {pictures && pictures.length === 0 && (
              <p>
                <FontAwesomeIcon icon={faExclamationTriangle as any} />
                &nbsp;Il n'y a aucune photo dans cette galerie
              </p>
            )}

            {pictures.map((picture) => (
              <div key={picture.id}>
                <div className={selectPicture.id === picture.id ? "listing--itemSelected" : "listing--item"} data-clickable="true">
                  <div
                    className="listing--background"
                    style={{
                      backgroundImage: `url("${REACT_APP_CDN_URL}${picture.path}")`,
                    }}
                    onClick={() => {
                      if (selectPicture.id === picture.id) {
                        setSelectPicture('')
                      } else {
                        setSelectPicture(picture)
                      }
                    }}
                  />
                </div>
                <div className="picture-actions">
                  <div
                    className="action"
                    title="Supprimer la photo"
                    onClick={() => handleOnRemove(picture.id)}
                  >
                    <FontAwesomeIcon icon={faTrash as any} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default DisplayPhotos;
