import React from "react";
import { Switch, Route } from "react-router-dom";

import { EditorHeader, withLayout } from "components";
import LeftMenu from "components/PageRenderer/components/LeftMenu";
import { ROUTE_PATHS, ROUTE_KEYS } from "core/router/route.keys";

import YourSponsors from "./components/YourSponsors";
import SponsorForm from "./components/SponsorForm";

import "./styles.scss";

interface IProps { }

const Sponsors: React.FC<IProps> = (props: IProps) => {
  return (
    <>
      <EditorHeader
        currentPageLabel="Sponsors"
      />

      <div className="page-renderer">
        <LeftMenu />

        <section className="page-renderer-container">
          <div className="sponsors">
            <Switch>
              <Route
                path={ROUTE_PATHS[ROUTE_KEYS.SPONSORS]}
                component={YourSponsors}
                exact={true}
              />
              <Route
                path={ROUTE_PATHS[ROUTE_KEYS.SPONSORS_CREATE]}
                component={SponsorForm}
              />
              <Route
                path={ROUTE_PATHS[ROUTE_KEYS.SPONSORS_EDIT]}
                component={SponsorForm}
              />
            </Switch>
          </div>
        </section>
      </div>
    </>
  );
};

export default withLayout(Sponsors);
