import React, { useEffect, useState, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { DISPLAY_NOTIFICATION } from "core/redux/reducer/main.reducer";
import NewsService from "services/news/news.service";
import { INews } from "services/service.interfaces";
import useMainState from "hooks/useMainState";
import DisplayOneNews from "../DisplayOneNews";
import AddButton from "../AddButton";
import { Loading } from "components";

interface IProps {}

const YourNews: React.FC<IProps> = (props: IProps) => {
  const [loading, setLoading] = useState(true);
  const [news, setNews] = useState<INews[]>([]);
  const newsService = useRef(new NewsService());
  const { websiteConfiguration } = useMainState();
  const dispatch = useDispatch();
  const history = useHistory();

  const refresh = useCallback(() => {
    if (!websiteConfiguration) {
      return;
    }

    const { promise, cancel } = newsService.current.all(
      websiteConfiguration.id
    );
    promise
      .then((response) => {
        setNews(response);
        setLoading(false);
      })
      .catch(() => {
        dispatch({
          type: DISPLAY_NOTIFICATION,
          payload: {
            message: "Impossible de récupérer les actualités",
            type: "danger",
          },
        });
        history.push("/");
      });
    return () => cancel();
  }, [websiteConfiguration, dispatch, history]);

  useEffect(refresh, []);

  return (
    <div className="news__your-news">
      <h1>Actualités de votre club</h1>

      {loading && <Loading />}

      <AddButton />

      {!loading && (
        <div className="news__your-news--list">
          {news.map((aNews) => (
            <DisplayOneNews key={aNews.id} aNews={aNews} onRefresh={refresh} />
          ))}
        </div>
      )}
    </div>
  );
};

export default YourNews;
