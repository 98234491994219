import React, { useState } from "react";
import { Button } from "ffbad-components";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IProps {
  onAdd: (title: string) => void;
}

const AddElement: React.FC<IProps> = (props: IProps) => {
  const { onAdd } = props;
  const [title, setTitle] = useState("");

  const handleOnClick = () => {
    onAdd(title);
    setTitle("");
  };

  return (
    <div className="edit-menu__form">
      <h3>Ajouter un nouvel élément</h3>

      <div className="edit-menu__form--inputs">
        <input
          name="title"
          type="text"
          placeholder="Saisissez un titre"
          value={title}
          onChange={(e) => setTitle(e.currentTarget.value)}
        />
        <Button
          isEditor
          safetySpacing={false}
          onClick={handleOnClick}
          disabled={!title || !title.length}
        >
          <FontAwesomeIcon icon={faPlus as any} />
        </Button>
      </div>
    </div>
  );
};

export default AddElement;
