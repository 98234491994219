import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  onClick: () => void;
}

const RemoveElementButton: React.FC<IProps> = (props: IProps) => {
  const { onClick } = props;
  return (
    <div className="remove-item-button" onClick={onClick}>
      <FontAwesomeIcon icon={faTimes as any} />
    </div>
  );
};

export default RemoveElementButton;
