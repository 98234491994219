import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";

import "./index.scss";

interface IProps {
  step: IWizardStep;
}

interface IWizardStep {
  title: string;
  description?: string;
  info?: string;
}

const WizardTitle: React.FC<IProps> = (props: IProps) => {
  const { step } = props;

  return (
    <div className="fullscreen-wizard-title">
      <h1>{step.title}</h1>
      {step.description && <p>{step.description}</p>}

      {step.info && (
        <span className="fullscreen-wizard-info">
          <FontAwesomeIcon icon={faInfo as any} />
        </span>
      )}
    </div>
  );
};

export default WizardTitle;
