import { MasterService } from "services";
import { INews } from "services/service.interfaces";

class NewsService {
  private master: MasterService;

  constructor() {
    this.master = new MasterService();
  }

  all = (websiteId: string): { promise: Promise<INews[]>; cancel: any } => {
    return this.master.get<INews[]>(`websites/${websiteId}/news?maxItems=full`);
  };

  find = (
    websiteId: string,
    newsId: string
  ): { promise: Promise<INews>; cancel: any } => {
    return this.master.get<INews>(`websites/${websiteId}/news/${newsId}`);
  };

  save = (
    websiteId: string,
    data: any
  ): { promise: Promise<INews>; cancel: any } => {
    const body = { websiteId, ...data };
    let request;

    if (!!data.id) {
      const route = `websites/${websiteId}/news/${data.id}`;
      request = this.master.put<INews>(route, body);
    } else {
      const route = `websites/${websiteId}/news`;
      request = this.master.post<INews>(route, body);
    }

    return request;
  };

  remove = (
    websiteId: string,
    newsId: string
  ): { promise: Promise<any>; cancel: any } => {
    return this.master.delete<INews[]>(`websites/${websiteId}/news/${newsId}`);
  };
}

export default NewsService;
