import LeftMenuNoSelection from "components/LeftMenuNoSelection";
import ComponentFieldsEditor from "components/ComponentFieldsEditor";
import NewPageChooser from "components/NewPageChooser";
import SectionChooser from "components/SectionChooser";
import ElementChooser from "components/ElementChooser";
import Settings from "pages/Settings";
import PageSettings from "pages/PageSettings";
import Menu from "pages/Menu";

export const MENU_ACTIONS = {
  NO_SELECTION: "no_selection",
  EDIT_COMPONENT: "edit_component",
  PAGE_TYPE_CHOOSER: "page_type_chooser",
  SECTION_CHOOSER: "section_chooser",
  ELEMENT_CHOOSER: "element_chooser",
  SETTINGS: "settings",
  PAGE_SETTINGS: "page_settings",
  MENU: "menu",
};

export default {
  [MENU_ACTIONS.NO_SELECTION]: LeftMenuNoSelection,
  [MENU_ACTIONS.EDIT_COMPONENT]: ComponentFieldsEditor,
  [MENU_ACTIONS.PAGE_TYPE_CHOOSER]: NewPageChooser,
  [MENU_ACTIONS.SECTION_CHOOSER]: SectionChooser,
  [MENU_ACTIONS.ELEMENT_CHOOSER]: ElementChooser,
  [MENU_ACTIONS.SETTINGS]: Settings,
  [MENU_ACTIONS.PAGE_SETTINGS]: PageSettings,
  [MENU_ACTIONS.MENU]: Menu,
};
