import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IWebsiteConfig } from "core/interfaces.config";
import { DISPLAY_NOTIFICATION, SET_CONFIGURATION } from "core/redux/reducer/main.reducer";
import { useMainState } from "hooks";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ConfigService } from "services";
import { applyWebsiteConfiguration } from "utils/dom/dom.utils";

import "./index.scss";

const service = new ConfigService();


interface IProps { }

const WebsiteEnableSelection: React.FC<IProps> = (props: IProps) => {
    const { websiteConfiguration } = useMainState();
    const dispatch = useDispatch();
    const [data, setData] = useState<boolean>(false);
    const [canSave, setCanSave] = useState(false);

    useEffect(() => {
        if (!websiteConfiguration) {
            return;
        }
        const {
            enable
        } = websiteConfiguration;
        setData(enable);
        setCanSave(false);
    }, [websiteConfiguration]);

    const handleOnSubmit = () => {
        setCanSave(false);
        const newConfiguration: IWebsiteConfig = {
            ...(websiteConfiguration as IWebsiteConfig),
        };
        newConfiguration.enable = data;

        const { promise } = service.updateWebsiteConfiguration(newConfiguration);
        promise.then((updatedConfiguration) => {
            dispatch({
                type: DISPLAY_NOTIFICATION,
                payload: {
                    message: "La visibilité de votre site a bien été sauvegardée",
                },
            });

            applyWebsiteConfiguration(updatedConfiguration);
            dispatch({ type: SET_CONFIGURATION, payload: updatedConfiguration });
        });
    }

    const onChangeValue = (event: any) => {
        const oldValue = websiteConfiguration?.enable ? "visible" : "non-visible"

        if (event.target.value === oldValue) {
            setCanSave(false);
        } else {
            setCanSave(true);
        }
        setData(event.target.value === 'visible')
    }

    return (
        <div>
            <h2>La visibilité de votre site</h2>
            <p>Choisissez si oui ou non votre site doit etre visible</p>
            <div onChange={onChangeValue} className="websiteEnableConfiguration">
                <div className="form">
                    <div className="input"><input type="radio" value="visible" name="visible" checked={data === true} />Visible</div>
                    <div className="input"><input type="radio" value="non-visible" name="non-visible" checked={data === false} />Invisible</div>
                </div>
                <div
                    role="button"
                    tabIndex={canSave ? 0 : undefined}
                    className="save-button"
                    data-disabled={!canSave}
                    title="Sauvegarder les modifications"
                    onClick={handleOnSubmit}
                >
                    <FontAwesomeIcon icon={faSave as any} />
                </div>
            </div>
        </div>
    );
};

export default WebsiteEnableSelection;
