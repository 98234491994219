import { PageBuilder } from "ffbad-components";
import { useSelector } from "react-redux";
import { IReducers } from "core/redux";

const usePageBuilder = (): PageBuilder => {
  const pageBuilder = useSelector(
    (reducers: IReducers) => reducers.editorReducer.pageBuilder
  );

  return pageBuilder;
};

export default usePageBuilder;
