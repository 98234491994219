import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderOpen } from "@fortawesome/free-solid-svg-icons";

import "./styles.scss";

interface IProps {
  children?: any;
  hideContent?: boolean;
  icon?: any;
}

const Empty: React.FC<IProps> = (props: IProps) => {
  const {
    children = "Il n'y a aucun résultat",
    hideContent = false,
    icon = faFolderOpen
  } = props;

  return (
    <div className="empty-container">
      <FontAwesomeIcon icon={icon} />
      {!hideContent && children}
    </div>
  );
};

export default Empty;
