import { ROUTE_PATHS, ROUTE_KEYS } from "core/router/route.keys";
import stopPromise from "utils/stop-promise";
import LocalStorageService from "./localStorage/localStorage.service";

const DEFAULT_ERROR_MESSAGE = "Une erreur est survenue.";
const localStorageService = new LocalStorageService();

// eslint-disable import/prefer-default-export
// eslint-disable-next-line no-underscore-dangle
const { _env } = window as any;
const getEnv = (key: string) =>
  (_env && _env[`REACT_APP_${key}`]) || process.env[`REACT_APP_${key}`] || "";

class MasterService {
  private apiURL: string;

  constructor() {
    this.apiURL = getEnv("API_URL");
  }

  get headers(): HeadersInit {
    const token = localStorageService.getToken();
    return {
      Authorization: `Bearer ${token}`,
    };
  }

  get = <T>(
    url: string
  ): {
    promise: Promise<T>;
    cancel: () => void;
  } => {
    const finalUrl = `${this.apiURL}${url}`;

    return stopPromise(
      fetch(finalUrl, {
        headers: this.headers,
      }).then(async (response) => {
        await this.checkStatus(response);
        return await response.json();
      })
    );
  };

  post = <T>(
    url: string,
    body: any | FormData = {},
    baseRequestInit?: RequestInit,
    method = "POST",
    shouldStringify = true
  ): {
    promise: Promise<T>;
    cancel: () => void;
  } => {
    const finalUrl = `${this.apiURL}${url}`;
    const init: RequestInit = {
      method,
      headers: {
        ...this.headers,
        "Content-Type": "application/json",
      },
      body: shouldStringify ? JSON.stringify(body) : body,
      ...baseRequestInit,
    };

    return stopPromise(
      fetch(finalUrl, init).then(async (response) => {
        await this.checkStatus(response);
        try {
          return await response.json();
        } catch {
          return;
        }
      })
    );
  };

  postFormData = <T>(url: string, formData: FormData) => {
    return this.post(
      url,
      formData,
      {
        headers: {
          ...this.headers,
        },
      },
      "POST",
      false
    );
  };

  put = <T>(
    url: string,
    body: any = {},
    baseRequestInit?: RequestInit
  ): { promise: Promise<T>; cancel: () => void } => {
    return this.post<T>(url, body, baseRequestInit, "PUT");
  };

  putFormData = <T>(url: string, formData: FormData) => {
    return this.post(
      url,
      formData,
      {
        headers: {
          ...this.headers,
        },
      },
      "PUT",
      false
    );
  };

  delete = <T>(
    url: string,
    body: any = {},
    baseRequestInit?: RequestInit
  ): { promise: Promise<T>; cancel: () => void } => {
    return this.post<T>(url, body, baseRequestInit, "DELETE");
  };

  private checkStatus = async (response: Response) => {
    if (response.status >= 200 && response.status < 300) {
      return;
    }

    if ([401, 403].includes(response.status)) {
      localStorageService.signOut();
      window.location.href = ROUTE_PATHS[ROUTE_KEYS.LOGIN];
      return;
    }

    const { error = DEFAULT_ERROR_MESSAGE } = await response.json();
    throw new Error(error);
  };
}

export default MasterService;
