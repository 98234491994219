import React, { useState, useEffect } from "react";
import { Button } from "ffbad-components";

import GalleryService from "services/gallery/gallery.service";
import { Loading } from "components";
import { useDispatch } from "react-redux";
import { DISPLAY_NOTIFICATION } from "core/redux/reducer/main.reducer";
import AddAction from "../AddAction";

interface IProps {
  onSelect: (gallery: any) => void;
  onAdd: () => void;
  selectedGalleryId?: string;
}

const service = new GalleryService();

const DisplayGalleries: React.FC<IProps> = (props: IProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [galleries, setGalleries] = useState<any[]>([]);
  const { onSelect, selectedGalleryId, onAdd } = props;

  useEffect(() => {
    const { promise, cancel } = service.all();
    promise
      .then((tempGalleries) => {
        setGalleries(tempGalleries);
        setLoading(false);
      })
      .catch(() => {
        dispatch({
          type: DISPLAY_NOTIFICATION,
          payload: { message: "Erreur de chargement", type: "danger" },
        });
        setError(true);
      });

    return () => cancel();
  }, [dispatch]);

  return (
    <>
      <h2>Vos galeries photos</h2>

      {loading && !error && <Loading />}

      {error && <p>Erreur lors du chargement de vos galeries photos</p>}

      {!loading && !error && (
        <div className="listing-galleries">
          <AddAction onClick={onAdd}>Créer une galerie</AddAction>

          {galleries.map((gallery) => (
            <div key={gallery.id} className="item">
              <Button
                type={
                  gallery.id === selectedGalleryId ? "primary" : "secondary"
                }
                onClick={() => onSelect(gallery)}
                safetySpacing={false}
                isEditor
              >
                {gallery.title}
              </Button>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default DisplayGalleries;
