import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";

interface IProps {
    onClick: () => void;
    selected?: boolean;
}

const MakeHomePageButton: React.FC<IProps> = (props: IProps) => {
    const { onClick, selected = false } = props;

    return (
        <div className="make-root-page-button" onClick={onClick}>
            <FontAwesomeIcon icon={faHome as any} className={selected ? "selected" : "not-selected"} />
        </div>
    );
};

export default MakeHomePageButton;
