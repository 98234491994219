import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { CLEAR_LEFT_MENU_STATE } from "core/redux/reducer/leftMenu.reducer";
import LeftMenu from "components/PageRenderer/components/LeftMenu";
import { EditorHeader, withLayout } from "components";
import DisplayGalleries from "./components/DisplayGalleries";
import DisplayPhotos from "./components/DisplayPhotos";
import GalleryForm from "./components/GalleryForm";
import { randomString } from "utils/string/string.utils";

import "./index.scss";

enum Views {
  NONE,
  DISPLAY_PHOTOS,
  GALLERY_FORM,
}

const Gallery: React.FC = () => {
  const [view, setView] = useState(Views.NONE);
  const [selectedGallery, setSelectedGallery] = useState<any | undefined>();
  const [refreshGalleries, setRefreshGalleries] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: CLEAR_LEFT_MENU_STATE });
  }, [dispatch]);

  const handleOnSelectGallery = (gallery: any) => {
    setSelectedGallery(gallery);
    setView(Views.DISPLAY_PHOTOS);
  };

  const handleOnAdd = () => {
    setSelectedGallery(undefined);
    setView(Views.GALLERY_FORM);
  };

  const handleOnGalleryCreated = () => {
    setView(Views.NONE);
    setRefreshGalleries(randomString());
  };

  return (
    <>
      <EditorHeader
        currentPageLabel="Galerie"
      />

      <div className="page-renderer">
        <LeftMenu />

        <section className="page-renderer-container">
          <div className="gallery">
            <div className="gallery__flex-container">
              <div className="gallery__left">
                <DisplayGalleries
                  key={refreshGalleries}
                  onSelect={handleOnSelectGallery}
                  onAdd={handleOnAdd}
                  selectedGalleryId={selectedGallery && selectedGallery.id}
                />
              </div>
              <div className="gallery__right">
                {view === Views.DISPLAY_PHOTOS && (
                  <DisplayPhotos gallery={selectedGallery} />
                )}
                {view === Views.GALLERY_FORM && (
                  <GalleryForm onRefresh={handleOnGalleryCreated} />
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default withLayout(Gallery);
