import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Notification } from "ffbad-components";

import { ROUTE_PATHS, ROUTE_KEYS } from "core/router/route.keys";
import { DISPLAY_NOTIFICATION } from "core/redux/reducer/main.reducer";
import PersonService from "services/person/person.service";
import LocalStorageService from "services/localStorage/localStorage.service";
import { useNotification } from "hooks";
import { extractFormValues } from "utils/form/form.utils";
import Form from "./components/Form";

import "./index.scss";

const service = new PersonService();
const localStorageService = new LocalStorageService();

const Login: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const notification = useNotification();
  const [isLoading, setIsLoading] = useState(false);

  const successNotification = useCallback(() => {
    dispatch({
      type: DISPLAY_NOTIFICATION,
      payload: {
        message: "Vous êtes bien connecté !",
        type: "primary",
      },
    });
  }, [dispatch]);

  useEffect(() => {
    const person = localStorageService.getPerson();
    if (person && person.personId) {
      history.push("/");
      successNotification();
    }
  }, [history, successNotification]);

  const cleanNotificationFromStore = () => {
    dispatch({ type: DISPLAY_NOTIFICATION, payload: null });
  };

  const handleOnSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const values = extractFormValues(event);
    fetchPerson(values);
  };

  const isAuthorizedToEnter = () => {
    let result = false;
    localStorageService.getPermissions().map((perm) => {
      if (perm.permissionId === "40000000") {
        result = true;
      }

      return perm
    });
    return result;
  };

  const fetchPerson = (values: any) => {
    const { promise } = service.auth(values);
    setIsLoading(true);

    promise
      .then(({ accessToken }) => {
        localStorageService.setToken(accessToken);
        const tokenData = localStorageService.extractTokenData();

        if (!tokenData) {
          throw new Error("Connexion impossible");
        }

        if (
          !tokenData.poona ||
          !tokenData.poona.person ||
          !tokenData.poona.person.player ||
          !tokenData.poona.person.player.instance ||
          !tokenData.poona.person.player.instance.instanceId
        ) {
          throw new Error("Vous n'êtes pas licencié sur la saison actuelle.");
        }

        localStorageService.set(
          "iid",
          tokenData.poona.person.player.instance.instanceId
        );
        const { promise } = service.findPermissions(
          tokenData.poona.person.player.personId
        );
        promise.then((response) => {
          localStorageService.set("permissions", JSON.stringify(response));
          if (isAuthorizedToEnter()) {
            successNotification();
            const returnUrl = new URLSearchParams(location.search).get(
              "returnUrl"
            );
            history.push(returnUrl || ROUTE_PATHS[ROUTE_KEYS.HOME]);
          } else {
            setIsLoading(false);
            dispatch({
              type: DISPLAY_NOTIFICATION,
              payload: {
                message: "Vous n'êtes pas autorisé a accéder a l'éditeur !",
                type: "danger",
              },
            });
          }
        });
      })
      .catch((err) => {
        localStorageService.removePerson();
        localStorageService.remove("iid");
        localStorageService.remove("token");

        dispatch({
          type: DISPLAY_NOTIFICATION,
          // TODO: Remove .replace() when task'll be done (https://supralog.myjetbrains.com/youtrack/issue/FFBAD-594)
          payload: {
            message: err.message.replace("Error: ", ""),
            type: "danger",
          },
        });
        setIsLoading(false);
      });
  };

  return (
    <div className="login">
      <div className="login__left-part">
        <div className="login--main-logo" />
        <h1 className="login--heading">Espace Clubs</h1>
        <Form onSubmit={handleOnSubmit} isLoading={isLoading} />
        {/* TODO: Add it when customer'll ask for it. */}
        {/* <Link to={"#"} className="login--forgot-password-link">
          mot de passe oublié ?
        </Link> */}
      </div>

      <div className="login__right-part" />

      {notification && (
        <Notification
          type={notification.type}
          onHide={cleanNotificationFromStore}
          isEditor
        >
          {notification.message}
        </Notification>
      )}
    </div>
  );
};

export default Login;
