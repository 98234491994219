import React, { ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  children: ReactNode;
  onClick: () => void;
  icon?: IconDefinition;
  color?: "primary" | "danger";
}

const AddAction: React.FC<IProps> = (props: IProps) => {
  const { onClick, icon = faPlus, children, color = "primary" } = props;
  return (
    <div className="add-action" onClick={onClick} data-color={color}>
      <div className="add-action--icon">
        <FontAwesomeIcon icon={icon as any} />
      </div>
      <span className="add-action--text">{children}</span>
    </div>
  );
};

export default AddAction;
