import React, { useRef, useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { DISPLAY_NOTIFICATION } from "core/redux/reducer/main.reducer";
import { SponsorsService } from "services";
import { ISponsor } from "services/service.interfaces";
import useMainState from "hooks/useMainState";
import { Loading } from "components";
import AddButton from "../AddButton";
import DisplayOneSponsor from "../DisplayOneSponsor";

const YourSponsors: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [sponsors, setSponsors] = useState<ISponsor[]>([]);
  const service = useRef(new SponsorsService());
  const { websiteConfiguration } = useMainState();
  const dispatch = useDispatch();
  const history = useHistory();

  const refresh = useCallback(() => {
    if (!websiteConfiguration) {
      return;
    }

    setLoading(true);
    const { promise, cancel } = service.current.all(websiteConfiguration.id);

    promise
      .then((response) => {
        setSponsors(response);
        setLoading(false);
      })
      .catch(() => {
        dispatch({
          type: DISPLAY_NOTIFICATION,
          payload: {
            message: "Impossible de récupérer les sponsors",
            type: "danger",
          },
        });
        history.push("/");
      });

    return () => cancel();
  }, [websiteConfiguration, dispatch, history]);

  useEffect(refresh, []);

  return (
    <div className="sponsors__your-sponsors">
      <h1>Sponsors de votre club</h1>

      {loading && <Loading />}

      <AddButton />

      {!loading && (
        <div className="sponsors__your-sponsors--list">
          {sponsors.map((aSponsor) => (
            <DisplayOneSponsor
              key={aSponsor.id}
              aSponsor={aSponsor}
              onRefresh={refresh}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default YourSponsors;
