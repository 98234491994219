import { IPageProperties } from "ffbad-components";
import { MasterService } from "services";
import { IPage } from "services/service.interfaces";

class PageService {
  private master: MasterService;

  constructor() {
    this.master = new MasterService();
  }

  findById = (
    pageId: string
  ): { promise: Promise<IPageProperties>; cancel: () => void } => {
    return this.master.get<IPageProperties>(`pages/${pageId}`);
  };

  findPredefined = (): {
    promise: Promise<{ id: string; label: string; description: string }[]>;
    cancel: () => void;
  } => {
    return this.master.get<any[]>(`pages?predefined`);
  };

  findByOwnerId = (
    ownerId: string
  ): { promise: Promise<IPage[]>; cancel: () => void } => {
    return this.master.get<IPage[]>(`pages?ownerId=${ownerId}`);
  };

  update = (
    pageId: string,
    pageProperties: IPageProperties
  ): { promise: Promise<IPageProperties>; cancel: () => void } => {
    return this.master.put<IPageProperties>(`pages/${pageId}`, pageProperties);
  };

  create = (
    pageProperties: IPageProperties
  ): { promise: Promise<IPageProperties>; cancel: () => void } => {
    return this.master.post<IPageProperties>(`pages`, pageProperties);
  };

  delete = (
    pageId: string
  ): { promise: Promise<IPageProperties>; cancel: () => void } => {
    return this.master.delete<IPageProperties>(`pages/${pageId}`);
  };
}

export default PageService;
