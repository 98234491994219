import React, { useState, useEffect } from "react";

import { buildClassName } from "utils/dom/dom.utils";
import INPUT_TYPES_MAPPING from "./input.mapping";
import { IFormInput, IFormInputTypes } from "./interfaces";
import Label from "./components/Label";
import Icon from "./components/Icon";

import "./index.scss";

type Props = {
  id?: string;
  items: IFormInput[];
  defaultData: any;
  onChange: (data: any) => void;
};

const DEFAULT_INPUT_TYPES_MAPPING = INPUT_TYPES_MAPPING[0];

const FormBuilder: React.FC<Props> = (props) => {
  const { id, items = [], defaultData, onChange } = props;
  const [data, setData] = useState<any>({});
  const [count, setCount] = useState<any>(0);

  useEffect(() => {
    setData(defaultData);
  }, [defaultData]);

  const handleOnChange = (id: string, value: string) => {
    if (window.location.pathname.includes("/actualites/editer")) {
      if (count >= 1) {
        const newData = { ...data, [id]: value };
        setData(newData);
        onChange(newData);
      }
      setCount(count + 1);
    } else {
      const newData = { ...data, [id]: value };
      setData(newData);
      onChange(newData);
    }
  };

  return (
    <form id={id} className="form-builder" onSubmit={(e) => e.preventDefault()}>
      {items.map((item) => {
        const { Component } = getMappingByInputType(item.type);
        const defaultValue = (data && data[item.id]) || item.defaultValue || "";
        const className = buildClassName("form-element-container", [
          [item.className !== undefined, item.className],
        ]);

        return (
          <div key={item.id} className={className}>
            {item.label && (
              <Label htmlFor={item.id} disabled={item.readonly}>
                {item.label}
              </Label>
            )}

            <div className="input-icon-container">
              {item.icon && <Icon icon={item.icon} />}

              <Component
                field={item}
                value={defaultValue}
                onChange={handleOnChange}
              />
            </div>
          </div>
        );
      })}
    </form>
  );
};

export default FormBuilder;

const getMappingByInputType = (
  type: IFormInputTypes = "text"
): { Component: any } => {
  return (
    INPUT_TYPES_MAPPING.find((mapping) => mapping.type === type) ||
    DEFAULT_INPUT_TYPES_MAPPING
  );
};
