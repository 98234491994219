import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IWebsiteConfig } from "core/interfaces.config";
import { DISPLAY_NOTIFICATION, SET_CONFIGURATION } from "core/redux/reducer/main.reducer";
import { useMainState } from "hooks";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ConfigService } from "services";
import { applyWebsiteConfiguration } from "utils/dom/dom.utils";
import "./index.scss";

const service = new ConfigService();


interface IProps { }

const SocialMedia: React.FC<IProps> = (props: IProps) => {
    const { websiteConfiguration } = useMainState();
    const dispatch = useDispatch();
    const [facebook, setFacebook] = useState<string | undefined>();
    const [twitter, setTwitter] = useState<string | undefined>();
    const [instagram, setInstagram] = useState<string | undefined>();
    const [canSave, setCanSave] = useState(false);
    const [, setDataLoaded] = useState(false);
    const placeholderFacebook = "Exemple : https://www.facebook.com/FederationFrancaiseBadminton";
    const placeholderTwitter = "Exemple : https://twitter.com/FFBaD";
    const placeholderInstagram = "Exemple : https://www.instagram.com/ffbad";

    useEffect(() => {
        if (!websiteConfiguration) {
            return;
        }
        const {
            configuration: {
                social
            }
        } = websiteConfiguration;
        setFacebook(social?.facebook)
        setTwitter(social?.twitter)
        setInstagram(social?.instagram)
        setCanSave(false);
        setDataLoaded(true);
    }, [websiteConfiguration]);

    const handleOnSubmit = () => {
        setCanSave(false);
        const newConfiguration: IWebsiteConfig = {
            ...(websiteConfiguration as IWebsiteConfig),
        };
        newConfiguration.configuration.social = { facebook, twitter, instagram };

        const { promise } = service.updateWebsiteConfiguration(newConfiguration);
        promise.then((updatedConfiguration) => {
            dispatch({
                type: DISPLAY_NOTIFICATION,
                payload: {
                    message: "Les réseaux sociaux ont bien été sauvegardés",
                },
            });

            applyWebsiteConfiguration(updatedConfiguration);
            dispatch({ type: SET_CONFIGURATION, payload: updatedConfiguration });
        });
    }

    return (
        <div>
            <h2>Les réseaux sociaux de votre club</h2>
            <div className="socialMediaConfiguration">
                <div className="form">
                    <div className="input">Facebook :<input className="socialMediaInput" value={facebook} type="text" placeholder={placeholderFacebook} onChange={(event) => { setCanSave(true); setFacebook(event.target.value) }} /></div>
                    <div className="input">Twitter :<input className="socialMediaInput" value={twitter} type="text" placeholder={placeholderTwitter} onChange={(event) => { setCanSave(true); setTwitter(event.target.value) }} /></div>
                    <div className="input">Instagram :<input className="socialMediaInput" value={instagram} type="text" placeholder={placeholderInstagram} onChange={(event) => { setCanSave(true); setInstagram(event.target.value) }} /></div>
                </div>
                <div
                    role="button"
                    tabIndex={canSave ? 0 : undefined}
                    className="save-button"
                    data-disabled={!canSave}
                    title="Sauvegarder les modifications"
                    onClick={handleOnSubmit}
                >
                    <FontAwesomeIcon icon={faSave as any} />
                </div>
            </div>
        </div>
    );
};

export default SocialMedia;
