import React, { useEffect, useState } from "react";

import { useGalleryService } from "hooks";
import { IAlbum } from "services/service.interfaces";
import { IFormInput } from "components/FormBuilder/interfaces";
import Select from "../Select";
import { Loading } from "components";

interface IProps {
  field: IFormInput;
  value: string | number;
  onChange: (key: string, value: string | number) => void;
}

const SelectAlbums: React.FC<IProps> = (props: IProps) => {
  const { field, value, onChange } = props;
  const galleryService = useGalleryService();
  const [albums, setAlbums] = useState<IAlbum[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const { promise, cancel } = galleryService.all();

    promise.then((albums) => {
      setAlbums(albums);
      setLoading(false);
    });
    return () => cancel();
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <Select
      field={{ ...field, selectItems: getSelectItems(albums) }}
      value={value}
      onChange={onChange}
    />
  );
};

const getSelectItems = (
  albums: IAlbum[] = []
): {
  key: string;
  label: string;
}[] => {
  const output = albums.map((album) => ({
    key: album.id,
    label: album.title,
  }));

  return [{ key: "", label: "Aucun" }, ...output];
};

export default SelectAlbums;
