import { faGlobeEurope } from "@fortawesome/free-solid-svg-icons";

import { IFormInput } from "components/FormBuilder/interfaces";

const fields: IFormInput[] = [
  {
    id: "siteUrl",
    label: "URL du site*",
    placeholder: "Saisissez l'URL de votre site",
    icon: faGlobeEurope,
  },
];

export default fields;
