import { faSave, faUndoAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IWebsiteConfig } from "core/interfaces.config";
import { DISPLAY_NOTIFICATION, SET_CONFIGURATION } from "core/redux/reducer/main.reducer";
import { useMainState } from "hooks";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ConfigService } from "services";
import { applyWebsiteConfiguration } from "utils/dom/dom.utils";
import AddElement from "../AddElement";
import DisplayDomainName from "../DisplayDomainName";

import "./index.scss";

const service = new ConfigService();


interface IProps { }

const DomainSelection: React.FC<IProps> = (props: IProps) => {
  const { websiteConfiguration } = useMainState();
  const dispatch = useDispatch();
  const [data, setData] = useState<string[]>(websiteConfiguration?.domainNames || []);
  const [canSave, setCanSave] = useState(false);
  const [canReverse, setCanReverse] = useState(false);

  const handleOnSave = () => {
    setCanSave(false);
    const newConfiguration: IWebsiteConfig = {
      ...(websiteConfiguration as IWebsiteConfig),
    };
    newConfiguration.domainNames = data;

    const { promise } = service.updateWebsiteConfiguration(newConfiguration);
    promise.then((updatedConfiguration) => {
      dispatch({
        type: DISPLAY_NOTIFICATION,
        payload: {
          message: "Les noms de domaine ont bien été sauvegardés",
        },
      });

      applyWebsiteConfiguration(updatedConfiguration);
      dispatch({ type: SET_CONFIGURATION, payload: updatedConfiguration });
    });
  }

  const handleOnReverse = () => {
    setCanReverse(false)
    setCanSave(false);
    setData(websiteConfiguration?.domainNames || [])
  }

  const handleOnDelete = (domainName: string) => {
    setCanReverse(true);
    setCanSave(true);
    setData(data.filter(d => d !== domainName))
  }

  const handleOnAdd = (domainName: string) => {
    setCanReverse(true);
    setCanSave(true);
    setData([...data, domainName])
  }

  return (
    <div>
      <h2>Les différents noms de domaine de votre site</h2>
      <AddElement onAdd={handleOnAdd} />
      {data.length > 0 && <h3>Liste des noms de domaines :</h3>}
      {data.map((domainName) => (
        <DisplayDomainName
          key={domainName}
          domainName={domainName}
          handleOnDelete={() => handleOnDelete(domainName)}
        />
      ))}
      <div className="domainSelection">
        <div
          role="button"
          tabIndex={canReverse ? 0 : undefined}
          className="save-button"
          data-disabled={!canReverse}
          title="Revenir en arrière"
          onClick={handleOnReverse}
        >
          <FontAwesomeIcon icon={faUndoAlt as any} />
        </div>
        <div
          role="button"
          tabIndex={canSave ? 0 : undefined}
          className="save-button"
          data-disabled={!canSave}
          title="Sauvegarder les modifications"
          onClick={handleOnSave}
        >
          <FontAwesomeIcon icon={faSave as any} />
        </div>
      </div>
    </div>
  );
};

export default DomainSelection;
