import { PageBuilder, IPageComponentProperties } from "ffbad-components";
import { randomString } from "utils/string/string.utils";

export const SET_EXISTING_COMPONENT_ID: string = "setExistingComponentId";
export const SET_NEW_COMPONENT_JSON: string = "setSelectedComponentJson";
export const CLEAR_EDITOR_STATE: string = "clearEditorState";
export const SET_EDITOR_STATE: string = "setEditorState";
export const SET_COMPONENT_TO_EDIT: string = "setComponentToEdit";
export const REFRESH_EDITOR_STATE: string = "refreshEditorState";
export const EDIT_COMPONENT_TYPE: string = "editComponentType";

export interface IEditorState {
  existingComponentId?: string;
  componentToEdit?: IPageComponentProperties;
  isEditType: boolean;
  newComponentJSON?: IPageComponentProperties;
  keepLeftMenuOpened?: boolean;
  pageBuilder: PageBuilder;

  // force
  forceRefreshToken: string;
}

const initialState: IEditorState = {
  existingComponentId: undefined,
  componentToEdit: undefined,
  isEditType: false,
  newComponentJSON: undefined,
  keepLeftMenuOpened: false,
  pageBuilder: new PageBuilder(),
  forceRefreshToken: randomString(),
};

const editorReducer = (
  state: IEditorState = initialState,
  action: any
): IEditorState => {
  switch (action.type) {
    case SET_EXISTING_COMPONENT_ID:
      return { ...state, existingComponentId: action.payload };
    case SET_NEW_COMPONENT_JSON:
      return { ...state, newComponentJSON: action.payload };
    case CLEAR_EDITOR_STATE:
      return { ...initialState };
    case SET_EDITOR_STATE:
      return { ...state, ...action.payload };
    case SET_COMPONENT_TO_EDIT:
      return { ...state, componentToEdit: action.payload };
    case REFRESH_EDITOR_STATE:
      return { ...state, forceRefreshToken: randomString() };
    case EDIT_COMPONENT_TYPE:
      return {
        ...state,
        componentToEdit: action.payload,
        isEditType: true,
      };
    default:
      return state;
  }
};

export default editorReducer;
