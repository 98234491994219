import { IFormInputTypes } from "./interfaces";
import Input from "./components/Input";
import Checkbox from "./components/Checkbox";
import TextArea from "./components/TextArea";
import Select from "./components/Select";
import TextEditor from "./components/TextEditor";
import SelectAlbums from "./components/SelectAlbums";
import SelectImage from "./components/SelectImage";

interface IInputMapping {
  type: IFormInputTypes;
  Component: React.FC<any>;
}

const INPUT_TYPES_MAPPING: IInputMapping[] = [
  {
    type: "text",
    Component: Input,
  },
  {
    type: "number",
    Component: Input,
  },
  {
    type: "checkbox",
    Component: Checkbox,
  },
  {
    type: "textarea",
    Component: TextArea,
  },
  {
    type: "select",
    Component: Select,
  },
  {
    type: "select_albums",
    Component: SelectAlbums,
  },
  {
    type: "select_image",
    Component: SelectImage,
  },
  {
    type: "htmlContent",
    Component: TextEditor,
  },
];

export default INPUT_TYPES_MAPPING;
