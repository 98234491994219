import React, { useState, useEffect } from "react";

import { usePageBuilder } from "hooks";
import { FormBuilder } from "components";
import { IFormInput } from "components/FormBuilder/interfaces";

import "./index.scss";

const LABEL_KEY = "label";
const DESCRIPTION_KEY = "description";
const TITLE_KEY = "generateTitle";
const items: IFormInput[] = [
  {
    id: LABEL_KEY,
    label: "Nom de la page",
    placeholder: "Saisissez le nom de votre page",
  },
  {
    id: DESCRIPTION_KEY,
    label: "Description de la page",
    placeholder: "Saisissez la description de votre page",
    type: "textarea",
  },
  {
    id: TITLE_KEY,
    label: "Générer un titre",
    type: "checkbox",
  },
];

const PageDefinition: React.FC = () => {
  const [data, setData] = useState<any>({});
  const editor = usePageBuilder();

  useEffect(() => {
    const {
      properties: { label, description, generateTitle, components },
    } = editor;

    if (
      components?.filter((component) => component.id === "pageMainTitle")
        .length !== 0
    ) {
      setData({
        label,
        description,
        generateTitle: true,
      });
    } else {
      setData({
        label,
        description,
        generateTitle,
      });
    }
  }, [editor]);

  useEffect(() => {
    editor.properties.label = data[LABEL_KEY];
    editor.properties.description = data[DESCRIPTION_KEY];
    editor.properties.generateTitle = data[TITLE_KEY];
    // eslint-disable-next-line
  }, [data]);

  return (
    <div className="page-definition">
      <h2>Information sur votre page</h2>
      <p>Personnalisez le nom et la description de votre page</p>

      <FormBuilder items={items} defaultData={data} onChange={setData} />
    </div>
  );
};

export default PageDefinition;
