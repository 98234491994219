import React, { useState, useRef, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { ROUTE_PATHS, ROUTE_KEYS } from "core/router/route.keys";
import useMainState from "hooks/useMainState";
import NewsService from "services/news/news.service";
import Actions from "./components/Actions";
import { FormBuilder, Loading } from "components";
import { DISPLAY_NOTIFICATION } from "core/redux/reducer/main.reducer";
import { useFetchNews } from "hooks";
import fields from "./fields.definition";
import Checkbox from "components/FormBuilder/components/Checkbox";
import Label from "components/FormBuilder/components/Label";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { FacebookShareButton } from "react-share";
import UploadImage from "./components/UploadImage";
import { IAlbum } from "services/service.interfaces";
import GalleryService from "services/gallery/gallery.service";

interface IProps { }

const ALL_NEWS_PAGE = ROUTE_PATHS[ROUTE_KEYS.NEWS];
const albumService = new GalleryService();
const { REACT_APP_CDN_URL } = process.env;

const NewsForm: React.FC<IProps> = (props: IProps) => {
  const history = useHistory();
  const { websiteConfiguration } = useMainState();
  const [data, setData] = useState<any>();
  const [facebook, setFacebook] = useState(false);
  const [twitter] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [publicationRemaining, setPublicationRemaining] = useState(0);
  const [newsUrl, setNewsUrl] = useState('');
  const [image, setImage] = useState<FormData>(new FormData());
  const newsService = useRef(new NewsService());
  const dispatch = useDispatch();
  const { id } = useParams<{ id?: any }>();
  const isEditMode = !!id;
  const { loading, news } = useFetchNews(id, ALL_NEWS_PAGE);
  const facebookButtonRef = useRef<any>();
  const [albumId, setAlbumId] = useState('')
  const [imagePath, setImagePath] = useState('')

  const handleOnCancel = () => {
    history.push(ROUTE_PATHS[ROUTE_KEYS.NEWS]);
  };

  useEffect(() => {
    const { promise } = albumService.all();
    promise.then((albums: IAlbum[]) => {
      const albumNews = albums.find(album => album.title === 'Actualites');
      if (albumNews) {
        setAlbumId(albumNews.id);
      } else {
        const { promise } = albumService.createAlbum({ title: 'Actualites' })
        promise.then((response: any) => {
          setAlbumId(response.id);
        })
      }
    })
  }, []);

  const publishOnFacebook = () => {
    if (facebook) {
      facebookButtonRef.current.click();
      setFacebook(false);
    }
  }

  const publicationDone = () => {
    setPublicationRemaining(prevValue => prevValue - 1);
  }

  const publishOnSocialMedia = (id: string) => {
    if (data && websiteConfiguration?.domainNames) {
      setNewsUrl(`${websiteConfiguration?.domainNames[0]}/actualite/${id}`);
    }
    if (twitter && facebook) {
      setPublicationRemaining(2);
    } else if ((twitter && !facebook) || (facebook && !twitter)) {
      setPublicationRemaining(1);
    }
    publishOnFacebook();
    //publishOnTwitter();
    setIsPublishing(true)
  }

  const handleOnSave = () => {
    if (!websiteConfiguration) {
      return;
    }

    const error = checkNews(data);
    if (error) {
      dispatch({
        type: DISPLAY_NOTIFICATION,
        payload: {
          message: error,
          type: "danger",
        },
      });
      return;
    }

    if (imagePath !== '') {
      if (imagePath.includes('http')) {
        data.image = imagePath;
      } else {
        data.image = REACT_APP_CDN_URL + '' + imagePath;
      }
      saveNews();
      return;
    }


    const { promise } = albumService.upload(albumId, image);
    promise.then((response: any) => {
      data.image = REACT_APP_CDN_URL + '' + response.path;
      saveNews();
    })
      .catch(() => {
        dispatch({
          type: DISPLAY_NOTIFICATION,
          payload: {
            message: "L'image n'a pas pu être importée",
            type: "danger",
          },
        });
      })
  };

  const saveNews = () => {
    if (!websiteConfiguration) {
      return;
    }

    data.shortContent = data.shortContent.replace('style="cursor: nwse-resize;"', '');

    newsService.current
      .save(websiteConfiguration.id, { id, ...data })
      .promise.then((response) => {
        dispatch({
          type: DISPLAY_NOTIFICATION,
          payload: { message: `L'actualité a bien été ${isEditMode ? "éditée" : "créée"}` },
        });
        publishOnSocialMedia(response.id);
      })
      .catch(() => {
        dispatch({
          type: DISPLAY_NOTIFICATION,
          payload: {
            message: `L'actualité n'a pas pu être ${isEditMode ? "éditée" : "créée"}`,
            type: "danger",
          },
        });
      });
  }

  const handleOnChange = (data: any) => {
    setData(data);
  };

  const handleOnChangeImage = (image: FormData) => {
    setImage(image);
  };

  const handleOnFacebookChange = (id: string, data: any) => {
    setFacebook(data)
  }

  useEffect(() => {
    if (!news) {
      return;
    }

    setData({
      title: news.title,
      shortContent: news.shortContent,
      image: news.image,
      highlighted: news.highlighted,
    });
    setImagePath(news.image)

    // eslint-disable-next-line
  }, [news]);

  useEffect(() => {
    if (isPublishing && publicationRemaining === 0) {
      handleOnCancel();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPublishing, publicationRemaining]);

  const cleanDesciption = (desciption: string) => {
    return desciption.replace('<p>', '').replace('</p>', '');
  }

  return (
    <div className="news-form">
      <h1>{isEditMode ? "Edition" : "Création"} d'une actualité</h1>

      {loading && <Loading />}

      {!loading && (
        <>
          <FormBuilder
            items={fields}
            defaultData={data}
            onChange={handleOnChange}
          />
          <UploadImage handleOnChangeImage={handleOnChangeImage} imagePath={imagePath} setImagePath={setImagePath} />
          {websiteConfiguration?.configuration.social?.facebook &&
            <div key="facebookLabel" className="form-element-container">
              <Label htmlFor="facebookLabel" >
                Publier sur Facebook
            </Label>
              <div className="input-icon-container">
                <FontAwesomeIcon icon={faFacebook as any} className="social-icon" />
                <Checkbox field={{ id: 'facebook' }} value={facebook} onChange={handleOnFacebookChange} />
              </div>
            </div>}

          <Actions
            disable={isPublishing}
            saveLabel={
              isPublishing && publicationRemaining > 0 ?
                `Publication a traiter (${publicationRemaining})`
                :
                undefined
            }
            onCancel={handleOnCancel}
            onSave={handleOnSave}
          />

          {websiteConfiguration?.configuration.social?.facebook &&
            <FacebookShareButton
              ref={facebookButtonRef}
              children=""
              url={newsUrl}
              translate={null}
              quote={data?.shortContent && cleanDesciption(data.shortContent)}
              onShareWindowClose={publicationDone}
              windowHeight={1000}
              windowWidth={1000}
            />
          }
        </>
      )}
    </div>
  );
};

export default NewsForm;

const checkNews = (data: any): string | undefined => {
  if (!data.title) {
    return "Merci de saisir un titre";
  }

  if (data.title.length < 5) {
    return "Merci de saisir un titre avec 5 caractères minimum";
  }

  if (!data.shortContent) {
    return "Merci de saisir un contenu";
  }

  if (data.shortContent.length <= 50) {
    return "Merci de saisir un contenu plus long";
  }
};
