import {
  faEdit,
  faAt,
  faFax,
  faPhone
} from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faInstagram,
  faTwitter
} from "@fortawesome/free-brands-svg-icons";

import { IFormInput } from "components/FormBuilder/interfaces";
import {
  twitterUrlIsValid,
  facebookUrlIsValid,
  instagramUrlIsValid
} from "utils/form/form.resolver";

export const KEYS = {
  CLUB_NAME: "clubName",
  CLUB_EMAIL: "clubEmail",
  CLUB_FAX: "clubFax",
  CLUB_MOBILE: "clubMobile",
  CLUB_INSTAGRAM: "clubInstagram",
  CLUB_FACEBOOK: "clubFacebook",
  CLUB_TWITTER: "clubTwitter",

  // Used in other step, but needed.
  CLUB_LOGO: "clubLogo"
};

const fields: IFormInput[] = [
  {
    id: KEYS.CLUB_NAME,
    label: "Nom de votre club*",
    icon: faEdit,
    readonly: true
  },
  {
    id: KEYS.CLUB_EMAIL,
    label: "E-mail de votre club*",
    icon: faAt,
    readonly: true
  },
  {
    id: KEYS.CLUB_FAX,
    label: "Fax de votre club*",
    icon: faFax,
    readonly: true
  },
  {
    id: KEYS.CLUB_MOBILE,
    label: "Tél. de votre club*",
    icon: faPhone,
    readonly: true
  },
  {
    id: KEYS.CLUB_INSTAGRAM,
    label: "Instagram",
    icon: faInstagram,
    className: "social-input",
    placeholder: "Exemple : https://www.instagram.com/ffbad",
    resolvers: [instagramUrlIsValid]
  },
  {
    id: KEYS.CLUB_FACEBOOK,
    label: "Facebook",
    icon: faFacebookF,
    className: "social-input",
    placeholder:
      "Exemple : https://www.facebook.com/FederationFrancaiseBadminton",
    resolvers: [facebookUrlIsValid]
  },
  {
    id: KEYS.CLUB_TWITTER,
    label: "Twitter",
    icon: faTwitter,
    className: "social-input",
    placeholder: "Exemple : https://twitter.com/FFBaD",
    resolvers: [twitterUrlIsValid]
  }
];

export default fields;
