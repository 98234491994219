import React from "react";

import Footer from "../Footer";

import "./styles.scss";

interface IProps {
  children: any;
}

const Layout: React.FC<IProps> = (props: IProps) => {
  const { children } = props;

  return (
    <>
      <main className="main-container">{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
