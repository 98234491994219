import React from "react";

import { buildClassName } from "utils/dom/dom.utils";

import "./index.scss";

interface IProps {
  children: any;
  type?: "primary" | "secondary" | "danger";
  disabled?: boolean;
  onClick: () => void;
}

const Button: React.FC<IProps> = (props: IProps) => {
  const { children, type = "primary", disabled = false, onClick } = props;
  const className = buildClassName("custom-button-container", [
    [type !== undefined, `button-${type}`]
  ]);

  return (
    <button className={className} onClick={() => onClick()} disabled={disabled}>
      {children}
    </button>
  );
};

export default Button;
