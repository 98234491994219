export interface ICreateWizard {
  step01: ICreateWizardStep01;
  step02: ICreateWizardStep02;
  step03: ICreateWizardStep03;
  step04: ICreateWizardStep04;
  step05: ICreateWizardStep05;
  step06: ICreateWizardStep06;
  step07: ICreateWizardStep07;
}

export interface ICreateWizardStep01 {
  siteUrl?: string;
  defaultUrl?: string;
}

export interface ICreateWizardStep02 {
  clubName?: string;
  clubEmail?: string;
  clubFax?: string;
  clubMobile?: string;
  clubInstagram?: string;
  clubFacebook?: string;
  clubTwitter?: string;
  clubLogo?: string;
}

export interface ICreateWizardStep03 {
  predefinedPages: string[];
}

export interface ICreateWizardStep04 {
  newFile?: File;
  colors?: string[];
}

export interface ICreateWizardStep05 { }

export interface ICreateWizardStep06 {
  tonalite?: {
    key: string;
    title: string;
    color: string;
    backgroundColor: string;
  };
}

export interface ICreateWizardStep07 {
  disposition?: { key: "top" | "left"; title: string; image: string };
}

export const defaultCreateWizardData: ICreateWizard = {
  step01: {},
  step02: {},
  step03: { predefinedPages: [] },
  step04: {},
  step05: {},
  step06: {},
  step07: {},
};
