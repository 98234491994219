import React from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { ROUTE_PATHS, ROUTE_KEYS } from "core/router/route.keys";

const AddButton: React.FC = () => {
  const history = useHistory();

  const handleOnClick = () => {
    const route = ROUTE_PATHS[ROUTE_KEYS.USEFUL_DOCUMENTS_CREATE];
    history.push(route);
  };

  return (
    <div className="useful-documents--add-button" onClick={handleOnClick}>
      <span className="icon">
        <FontAwesomeIcon icon={faPlus as any} />
      </span>
      <span className="text">Ajouter un document utile</span>
    </div>
  );
};

export default AddButton;
