import { useHistory } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { DISPLAY_NOTIFICATION } from "core/redux/reducer/main.reducer";
import { INews } from "services/service.interfaces";
import NewsService from "services/news/news.service";
import useMainState from "hooks/useMainState";

const useFetchNews = (
  id?: string,
  redirectUrlOnError?: string
): { loading: boolean; news: INews | undefined } => {
  const [loading, setLoading] = useState(true);
  const [news, setNews] = useState<INews>();
  const newsService = useRef(new NewsService());
  const { websiteConfiguration } = useMainState();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!id || !websiteConfiguration || !websiteConfiguration.id) {
      setLoading(false);
      return;
    }

    const { promise, cancel } = newsService.current.find(
      websiteConfiguration.id,
      id
    );
    promise
      .then((response) => {
        setNews(response);
        setLoading(false);
      })
      .catch(() => {
        dispatch({
          type: DISPLAY_NOTIFICATION,
          payload: {
            message: "Impossible de retrouver l'actualité",
            type: "danger",
          },
        });

        if (redirectUrlOnError) {
          history.push(redirectUrlOnError);
        }
      });
    return () => cancel();
  }, [id, websiteConfiguration, dispatch, history, redirectUrlOnError]);

  return { loading, news };
};

export default useFetchNews;
