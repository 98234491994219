import { MasterService } from "services";
import { IUsefulDocument } from "services/service.interfaces";

class UsefulDocumentService {
    private master: MasterService;

    constructor() {
        this.master = new MasterService();
    }

    all = (
        websiteId: string
    ): { promise: Promise<IUsefulDocument[]>; cancel: any } => {
        return this.master.get<IUsefulDocument[]>(`websites/${websiteId}/documents`);
    };

    allByOwnerId = (
        ownerId: string
    ): { promise: Promise<IUsefulDocument[]>; cancel: any } => {
        return this.master.get<IUsefulDocument[]>(`websites/${ownerId}/documents/ownerId`);
    };

    find = (
        websiteId: string,
        usefulDocumentId: string
    ): { promise: Promise<IUsefulDocument>; cancel: any } => {
        return this.master.get<IUsefulDocument>(
            `websites/${websiteId}/documents/${usefulDocumentId}`
        );
    };

    remove = (
        websiteId: string,
        usefulDocumentId: string
    ): { promise: Promise<any>; cancel: any } => {
        return this.master.delete(
            `websites/${websiteId}/documents/${usefulDocumentId}`
        );
    };

    upload = (websiteId: string, formData: FormData) => {
        return this.master.postFormData(`websites/${websiteId}/documents`, formData);
    };

    update = (websiteId: string, documentId: string, label: string) => {
        return this.master.put(`websites/${websiteId}/documents/${documentId}`, { label });
    };
}

export default UsefulDocumentService;
