import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { useDispatch } from "react-redux";

import { CLEAR_LEFT_MENU_STATE } from "core/redux/reducer/leftMenu.reducer";
import { ROUTE_PATHS, ROUTE_KEYS } from "core/router/route.keys";
import { withLayout, EditorHeader } from "components";
import LeftMenu from "components/PageRenderer/components/LeftMenu";
import YourUsefulLinks from "./components/YourUsefulLinks";
import UsefulLinksForm from "./components/UsefulLinksForm";

import "./index.scss";

const UsefulLinks: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: CLEAR_LEFT_MENU_STATE });
  }, [dispatch]);

  return (
    <>
      <EditorHeader
        currentPageLabel="Liens utiles"
      />

      <div className="page-renderer">
        <LeftMenu />

        <section className="page-renderer-container">
          <div className="useful-links">
            <Switch>
              <Route
                path={ROUTE_PATHS[ROUTE_KEYS.USEFUL_LINKS]}
                component={YourUsefulLinks}
                exact={true}
              />
              <Route
                path={ROUTE_PATHS[ROUTE_KEYS.USEFUL_LINKS_CREATE]}
                component={UsefulLinksForm}
              />
              <Route
                path={ROUTE_PATHS[ROUTE_KEYS.USEFUL_LINKS_EDIT]}
                component={UsefulLinksForm}
              />
            </Switch>
          </div>
        </section>
      </div>
    </>
  );
};

export default withLayout(UsefulLinks);
