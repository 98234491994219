import { faEdit, faLink } from "@fortawesome/free-solid-svg-icons";

import { IFormInput } from "components/FormBuilder/interfaces";

const fields: IFormInput[] = [
  {
    id: "title",
    label: "Titre",
    placeholder: "Saisissez le titre",
    icon: faEdit,
  },
  {
    id: "url",
    label: "Site internet",
    placeholder: "http://...",
    icon: faLink,
  },
];

export default fields;
