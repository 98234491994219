import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
  ICreateWizard,
  defaultCreateWizardData,
} from "core/interfaces/createWizard/createWizard.interface";
import { ROUTE_PATHS, ROUTE_KEYS } from "core/router/route.keys";
import { isPrivateRoute, usePerson } from "hooks";
import { ConfigService } from "services";
import { FullscreenWizard } from "components";
import Step01 from "./components/Step01";
import Step02 from "./components/Step02";
import Step03 from "./components/Step03";
import Step04 from "./components/Step04";
import Step05 from "./components/Step05";
import Step07 from "./components/Step07";
import Finalisation from "./components/Finalisation";

import "./index.scss";

interface IProps { }

const DEFAULT_STEP_INDEX = 0;
const STEPS = {
  STEP01: "step01",
  STEP02: "step02",
  STEP03: "step03",
  STEP04: "step04",
  STEP05: "step05",
  STEP06: "step06",
  STEP07: "step07",
};
const configService = new ConfigService();

const Wizard: React.FC<IProps> = (props: IProps) => {
  const history = useHistory();
  const person = usePerson();
  const [globalData, setGlobalData] = useState<ICreateWizard>(
    defaultCreateWizardData
  );

  isPrivateRoute(history);

  useEffect(() => {
    if (!person || !Object.keys(person).length) {
      return;
    }

    const { promise, cancel } = configService.getByOwnerId(
      person.player.instance.instanceId
    );
    promise
      .then((config) => {
        // If config was found, disable the wizard process & go to home page.
        if (config && config.id) {
          history.push(ROUTE_PATHS[ROUTE_KEYS.HOME]);
        }
      })
      .catch(() => {
        // Nothing to do.
      });

    return () => cancel();
    // eslint-disable-next-line
  }, []);

  const handleOnChange = (step: string, data: any) => {
    const newData = { ...globalData, [step]: data };
    setGlobalData(newData);
  };

  return (
    <>
      <FullscreenWizard
        index={DEFAULT_STEP_INDEX}
        steps={[
          {
            title: "Quel est le nom de votre site ou de votre club ?",
            description:
              "Le nom et l’url de votre site pourront être modifiées par la suite depuis les paramètres du site.",
            content: (
              <Step01
                onChange={(data) => handleOnChange(STEPS.STEP01, data)}
                defaultData={globalData.step01}
                person={person}
              />
            ),
            canSkipStep: true,
          },
          {
            title: "Vérifiez et modifiez vos coordonnées",
            content: (
              <Step02
                onChange={(data) => handleOnChange(STEPS.STEP02, data)}
                defaultData={globalData.step02}
                person={person}
              />
            ),
          },
          {
            title: "Quelles pages pré-définies voulez-vous sur votre site ?",
            content: (
              <Step03
                onChange={(data) => handleOnChange(STEPS.STEP03, data)}
                defaultData={globalData.step03}
              />
            ),
          },
          {
            title: "Créez votre thème à partir de votre logo",
            content: (
              <Step04
                onChange={(data) => handleOnChange(STEPS.STEP04, data)}
                defaultData={globalData.step04}
                logoUrl={globalData.step02.clubLogo}
              />
            ),
            nextButtonLabel: "Générer la palette",
            canClickOnNextButton: () => {
              const hasClubLogo =
                globalData.step02 && globalData.step02.clubLogo !== undefined;
              const hasNewLogo =
                globalData.step04 && globalData.step04.newFile !== undefined;

              return hasClubLogo || hasNewLogo;
            },
          },
          {
            title:
              "Voici les couleurs qui seront utilisées pour le thème de votre site club",
            description:
              "Vous pourrez changer les couleurs proposées dans les paramètres de votre site",
            content: (
              <Step05
                onChange={(data) => handleOnChange(STEPS.STEP05, data)}
                defaultData={globalData.step05}
                logoUrl={globalData.step02.clubLogo}
                step04data={globalData.step04}
              />
            ),
            nextButtonLabel: "Utiliser ces couleurs",
          },
          /*{
            title: "Définissez la tonalité dominante de votre site",
            content: (
              <Step06
                onChange={(data) => handleOnChange(STEPS.STEP06, data)}
                defaultData={globalData.step06}
              />
            ),
          },*/
          {
            title: "Choisissez comment disposer le menu du site",
            content: (
              <Step07
                onChange={(data) => handleOnChange(STEPS.STEP07, data)}
                defaultData={globalData.step07}
              />
            ),
            nextButtonLabel: "Générer mon site",
          },
          {
            title: "",
            content: <Finalisation data={globalData} />,
            hideLayout: true,
          },
        ]}
      />
    </>
  );
};

export default Wizard;
