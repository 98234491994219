import { IInstance } from "core/interfaces/instance.interface";
import { MasterService } from "services";
import { IUsefulLink } from "services/service.interfaces";

class InstanceService {
  private master: MasterService;

  constructor() {
    this.master = new MasterService();
  }

  light = (
    instanceId: number
  ): { promise: Promise<IInstance>; cancel: any } => {
    return this.master.get<IInstance>(`poona/instance/${instanceId}/full`);
  };

  usefulLinks = (
    instanceId: string
  ): { promise: Promise<IUsefulLink[]>; cancel: any } => {
    return this.master.get<IUsefulLink[]>(
      `poona/instance/${instanceId}/quickAccess`
    );
  };
}

export default InstanceService;
