import React, { useEffect, useState } from "react";

import menuActionsMapping, { MENU_ACTIONS } from "components/PageRenderer/menuActions.mapping";
import useLeftMenuState from "hooks/useLeftMenuState";
import { faChevronCircleRight, faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons";

import "./index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { CLEAR_LEFT_MENU_STATE, SET_LEFT_MENU_ACTION } from "core/redux/reducer/leftMenu.reducer";
import { useMainState } from "hooks";

interface IProps {
  canPublish?: boolean;
  canDelete?: boolean;

  // Handlers.
  onPublish?: () => void;
  onDelete?: () => void;
}

const LeftMenu: React.FC<IProps> = (props: IProps) => {
  const { canPublish = false, onPublish, onDelete } = props;
  const dispatch = useDispatch();
  const [children, setChildren] = useState<any>();
  const leftMenuState = useLeftMenuState();
  const { websiteConfiguration } = useMainState();

  useEffect(() => {
    if (!leftMenuState.open || !leftMenuState.innerElementKey) {
      return;
    }

    const Component: any = menuActionsMapping[leftMenuState.innerElementKey];
    const tempChildren = <Component {...leftMenuState.props} />;
    setChildren(tempChildren);
  }, [leftMenuState]);

  const handleOnClick = () => {
    if (leftMenuState.open) {
      dispatch({ type: CLEAR_LEFT_MENU_STATE });
    } else {
      dispatch({
        type: SET_LEFT_MENU_ACTION,
        payload: {
          innerElementKey: MENU_ACTIONS.PAGE_SETTINGS,
          props: {
            canPublish: canPublish,
            canDelete: true,
            onPublish: onPublish,
            onDelete: onDelete
          }
        },
      });
    }
  }

  return (
    <div className="left-menu-container">
      <menu className={leftMenuState.open ? "visible" : ""}>

        <FontAwesomeIcon
          className={leftMenuState.open ? "icon-open" : "icon-close"}
          icon={(leftMenuState.open ? faChevronCircleLeft : faChevronCircleRight) as any}
          onClick={handleOnClick}
          color={websiteConfiguration?.configuration.colors.primary}
          size={'2x'}
        />

        {leftMenuState.open && children}
      </menu>
      <div
        className={`left-menu-block ${leftMenuState.open ? "visible" : ""}`}
      />
    </div>
  );
};

export default LeftMenu;
