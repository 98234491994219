import { IRouteArgs } from "core/interfaces.config";
import { ROUTE_KEYS, ROUTE_PATHS } from "./route.keys";

import Home from "pages/Home";
import Wizard from "pages/Wizard";
import CreatePage from "pages/CreatePage";
import Login from "pages/Login";
import Settings from "pages/Settings";
import Menu from "pages/Menu";
import Gallery from "pages/Gallery";
import UsefulLinks from "pages/UsefulLinks";
import News from "pages/News";
import Sponsors from "pages/Sponsors";
import UsefulDocuments from "pages/UsefulDocuments";

const routes: IRouteArgs[] = [
  {
    key: ROUTE_KEYS.HOME,
    path: ROUTE_PATHS[ROUTE_KEYS.HOME],
    component: Home,
    exact: true,
  },
  {
    key: ROUTE_KEYS.LOGIN,
    path: ROUTE_PATHS[ROUTE_KEYS.LOGIN],
    component: Login,
    exact: true,
  },
  {
    key: ROUTE_KEYS.WIZARD,
    path: ROUTE_PATHS[ROUTE_KEYS.WIZARD],
    component: Wizard,
    exact: true,
  },
  {
    key: ROUTE_KEYS.EDIT_PAGE,
    path: ROUTE_PATHS[ROUTE_KEYS.EDIT_PAGE],
    component: CreatePage,
    exact: true,
  },
  {
    key: ROUTE_KEYS.CREATE_PAGE,
    path: ROUTE_PATHS[ROUTE_KEYS.CREATE_PAGE],
    component: CreatePage,
    exact: true,
  },
  {
    key: ROUTE_KEYS.SETTINGS,
    path: ROUTE_PATHS[ROUTE_KEYS.SETTINGS],
    component: Settings,
    exact: true,
  },
  {
    key: ROUTE_KEYS.MENU,
    path: ROUTE_PATHS[ROUTE_KEYS.MENU],
    component: Menu,
    exact: true,
  },
  {
    key: ROUTE_KEYS.GALLERY,
    path: ROUTE_PATHS[ROUTE_KEYS.GALLERY],
    component: Gallery,
    exact: true,
  },
  {
    key: ROUTE_KEYS.NEWS,
    path: ROUTE_PATHS[ROUTE_KEYS.NEWS],
    component: News,
    exact: false,
  },
  {
    key: ROUTE_KEYS.SPONSORS,
    path: ROUTE_PATHS[ROUTE_KEYS.SPONSORS],
    component: Sponsors,
    exact: false,
  },
  {
    key: ROUTE_KEYS.USEFUL_LINKS,
    path: ROUTE_PATHS[ROUTE_KEYS.USEFUL_LINKS],
    component: UsefulLinks,
    exact: false,
  },
  {
    key: ROUTE_KEYS.USEFUL_DOCUMENTS,
    path: ROUTE_PATHS[ROUTE_KEYS.USEFUL_DOCUMENTS],
    component: UsefulDocuments,
    exact: false,
  },
];

export default routes;
export { ROUTE_PATHS, ROUTE_KEYS };
