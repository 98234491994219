import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import { FormBuilder, Empty } from "components";
import { IFormInput } from "components/FormBuilder/interfaces";
import { useEditorState, usePageBuilder } from "hooks";
import { REFRESH_EDITOR_STATE } from "core/redux/reducer/editor.reducer";
import { nestedPropsToFlat } from "utils/string/string.utils";
import { SET_LEFT_MENU_ACTION } from "core/redux/reducer/leftMenu.reducer";
import { Button } from "ffbad-components";
import { MENU_ACTIONS } from "components/PageRenderer/menuActions.mapping";

const TIMEOUT_BEFORE_UPDATING = 500;

let timeout: NodeJS.Timeout;

interface IProps {
  canPublish?: boolean;
  canDelete?: boolean;

  // Handlers.
  onPublish: () => void;
  onDelete?: () => void;
}

const ComponentFieldsEditor: React.FC<IProps> = (props: IProps) => {
  const { canPublish = false, onPublish, canDelete = false, onDelete } = props;
  const pageBuilder = usePageBuilder();
  const dispatch = useDispatch();
  const { componentToEdit } = useEditorState();
  const [fieldsToEdit, setFieldsToEdit] = useState<IFormInput[]>([]);
  const [data, setData] = useState<any>({});
  const canAutoSave = useRef(false);

  useEffect(() => {
    if (!componentToEdit) {
      return;
    }

    canAutoSave.current = false;

    const fields = pageBuilder.getEditableProps(componentToEdit.type);
    const formInputFields = convertFields(fields);

    setFieldsToEdit(formInputFields);

    const defaultData = pageBuilder.findPropsOf(componentToEdit.id);
    const defaultNestedProps = nestedPropsToFlat(defaultData);
    setData(defaultNestedProps);

    // Prevent submit on first init...
    setTimeout(() => {
      canAutoSave.current = true;
    }, 200);
  }, [componentToEdit, pageBuilder]);

  const menuDone = () => {
    dispatch({
      type: SET_LEFT_MENU_ACTION,
      payload: {
        innerElementKey: MENU_ACTIONS.PAGE_SETTINGS,
        props: {
          canPublish: canPublish,
          canDelete: canDelete,
          onPublish: onPublish,
          onDelete: onDelete,
        },
      },
    });
  };

  useEffect(() => {
    if (!canAutoSave.current || !componentToEdit) {
      return;
    }

    clearTimeout(timeout);
    timeout = setTimeout(() => {
      pageBuilder.update(componentToEdit.id, data);
      dispatch({ type: REFRESH_EDITOR_STATE });
    }, TIMEOUT_BEFORE_UPDATING);
  }, [data, componentToEdit, dispatch, pageBuilder]);

  return (
    <div>
      <h2>Modification d'un élément</h2>

      {fieldsToEdit.length === 0 && (
        <Empty>Aucune modification possible pour cet élément</Empty>
      )}

      {fieldsToEdit.length !== 0 && (
        <>
          <FormBuilder
            items={fieldsToEdit}
            defaultData={data}
            onChange={setData}
          />
        </>
      )}

      <Button onClick={menuDone}>Terminer</Button>
    </div>
  );
};

const convertFields = (fields: any[]): IFormInput[] => {
  return fields.map((f) => ({
    id: f.id,
    label: f.label,
    type: f.type,
    min: f.min,
    max: f.max,
    step: f.step,
    placeholder: f.placeholder,
    selectItems: f.selectItems,
  }));
};

export default ComponentFieldsEditor;
