import React, { useEffect, useState } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { useDispatch } from "react-redux";

import { SET_CONFIGURATION } from "core/redux/reducer/main.reducer";
import { IRouteArgs } from "core/interfaces.config";
import { ConfigService, LocalStorageService } from "services";
import useMainState from "hooks/useMainState";
import { applyWebsiteConfiguration } from "utils/dom/dom.utils";
import Error from "pages/Error";

import routes, { ROUTE_PATHS, ROUTE_KEYS } from "./routes.config";

const service = new ConfigService();
const localStorageService = new LocalStorageService();

const Router: React.FC = () => {
  const dispatch = useDispatch();
  const { websiteConfiguration } = useMainState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const person = localStorageService.getPerson();

    if (isSignInPage() || isWizardPage()) {
      setIsLoading(false);
      return;
    }

    if (!person || !person.player || !person.player.instance) {
      setIsLoading(false);
      return;
    }

    const { instanceId } = person.player.instance;
    const { promise, cancel } = service.getByOwnerId(instanceId);
    promise
      .then((payload) => {
        dispatch({ type: SET_CONFIGURATION, payload });
        localStorageService.set("iid", payload.ownerId);
        localStorageService.set("wid", payload.id);
        setIsLoading(false);
      })
      .catch(() => {
        document.location.href = ROUTE_PATHS[ROUTE_KEYS.WIZARD];
      });
    return () => cancel();
  }, [dispatch]);

  useEffect(() => {
    if (!websiteConfiguration) {
      return;
    }

    applyWebsiteConfiguration(websiteConfiguration);
  }, [websiteConfiguration]);

  if (isLoading) {
    return <></>;
  }

  return (
    <BrowserRouter>
      <Switch>
        {routes.map((route: IRouteArgs) => (
          <Route {...route} />
        ))}

        <Route component={Error} path="/erreur" />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;

const isSignInPage = () => {
  return document.location.pathname.includes("connexion");
};

const isWizardPage = () => {
  return document.location.pathname.includes("wizard");
};
