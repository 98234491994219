import { createStore, combineReducers } from "redux";

import mainReducer, { IMainState } from "./reducer/main.reducer";
import editorReducer, { IEditorState } from "./reducer/editor.reducer";
import leftMenuReducer, { ILeftMenuState } from "./reducer/leftMenu.reducer";

export interface IReducers {
  mainReducer: IMainState;
  editorReducer: IEditorState;
  leftMenuReducer: ILeftMenuState;
}

const store = createStore(
  combineReducers({ mainReducer, editorReducer, leftMenuReducer }),
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
