import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import "./index.scss";

interface IProps {
  title: string;
  onClick: () => void;
}

const MainItem: React.FC<IProps> = (props: IProps) => {
  const { title, onClick } = props;

  return (
    <div
      className="main-header-main-item"
      onMouseEnter={onClick}
      onMouseLeave={onClick}
    >
      <span>
        {title}&nbsp;
        <FontAwesomeIcon icon={faChevronDown as any} />
      </span>
    </div>
  );
};

export default MainItem;
