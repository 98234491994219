import jwt from "jwt-decode";
const CryptoJS = require("crypto-js");
const SECRET = "M'E=x#`Vfv+Z3Mv<";

class LocalStorageService {
  public set = (key: string, value: any) => {
    const valueToSave =
      typeof value === "string" ? value : JSON.stringify(value);
    const encryptedValue = this.encrypt(valueToSave);
    localStorage.setItem(key, encryptedValue);
  };

  public get = (key: any): string | undefined => {
    const item = localStorage.getItem(key);
    return item ? this.decrypt(item) : undefined;
  };

  public remove = (key: string) => {
    localStorage.removeItem(key);
  };

  public getPerson = (): IPersonLicence => {
    const tokenData = this.extractTokenData();
    if (!tokenData) {
      return {} as IPersonLicence;
    }

    return tokenData && tokenData.poona.person;
  };

  public removePerson = () => this.remove("person");

  setToken = (token: string) => this.set("token", token);

  getToken = (): string => this.get("token") || "";

  getPermissions = (): any[] => JSON.parse(this.get("permissions") || "");

  removeToken = () => this.remove("token");

  extractTokenData = (): ITokenData | undefined => {
    const token = this.getToken();
    if (!token) {
      return;
    }

    const decoded = jwt(this.getToken());
    return decoded as ITokenData;
  };

  signOut = () => {
    this.removePerson();
    this.removeToken();
  };

  private encrypt = (content: string): string => {
    return CryptoJS.AES.encrypt(content, SECRET)
      .toString()
      .replace(/=/gi, "___");
  };

  private decrypt = (crypted: string): string => {
    return CryptoJS.AES.decrypt(crypted.replace(/___/gi, "="), SECRET).toString(
      CryptoJS.enc.Utf8
    );
  };
}

export default LocalStorageService;

export interface IPersonLicence {
  firstName: string;
  gameplayDouble: any;
  gameplayMixed: any;
  gameplaySingle: any;
  isDataPublic: string;
  isProspectingFFBaD: string;
  isProspectingThirdPart: string;
  isResultPublic: number;
  lastName: string;
  laterality: string;
  licence: string;
  nativeCountryIso3: string;
  personId: string;
  photo: string | null;
  sex: string;
  player: {
    category: string;
    categoryId: string;
    instance: { instanceId: string; name: string };
    isAdult: 1;
    licenceType: string;
    personId: string;
    playerId: string;
  };
}

export interface ITokenData {
  exp: number;
  iat: number;
  id: string;
  name: string;
  poona: {
    person: IPersonLicence;
  };
}
