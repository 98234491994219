import React, { useState } from "react";

import { IFormInput } from "components/FormBuilder/interfaces";
import { buildClassName } from "utils/dom/dom.utils";
import { inputIsValid } from "utils/form/form.resolver";

interface IProps {
  field: IFormInput;
  value: string | number;
  onChange: (key: string, value: string | number) => void;
  style?: any;
}

const Select: React.FC<IProps> = (props: IProps) => {
  const { field, value, onChange, style } = props;
  const [isValid, setIsValid] = useState(true);
  const className = buildClassName("flex-1", [[!isValid, "input-not-valid"]]);

  const handleOnChange = (e: any) => {
    const tempValue = e.currentTarget.value;
    onChange(field.id, tempValue);

    const isValid = inputIsValid(tempValue, field.resolvers);
    setIsValid(isValid);
  };

  return (
    <select
      id={field.id}
      placeholder={field.placeholder}
      onChange={handleOnChange}
      required={field.required}
      value={value}
      className={className}
      style={style}
    >
      {field.selectItems &&
        field.selectItems.map(item => (
          <option key={item.key} value={item.key}>
            {item.label}
          </option>
        ))}
    </select>
  );
};

export default Select;
