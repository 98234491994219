import React, { useEffect, useState } from "react";

import { ICreateWizardStep01 } from "core/interfaces/createWizard/createWizard.interface";
import { FormBuilder } from "components";
import fields from "./fields.definition";
import { IPersonLicence } from "services/localStorage/localStorage.service";
import { InstanceService } from "services";
import { IInstance } from "core/interfaces/instance.interface";

interface IProps {
  defaultData: ICreateWizardStep01;
  onChange: (data: ICreateWizardStep01) => void;
  person: IPersonLicence;
}

const instanceService = new InstanceService();

const Step01: React.FC<IProps> = (props: IProps) => {
  const { defaultData, onChange, person } = props;
  const [instance, setInstance] = useState<IInstance>();

  const handleOnChange = (data: { siteUrl: string }) => {
    data.siteUrl = replaceUnwantedCharacters(data.siteUrl);
    onChange(data);
  };

  useEffect(() => {
    if (!person || !person.player || !person.player.instance) {
      return;
    }

    const {
      player: {
        instance: { instanceId },
      },
    } = person;

    const { promise, cancel } = instanceService.light(Number(instanceId));
    promise.then((anInstance) => {
      setInstance(anInstance);
    });

    return () => cancel();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="step-container step-container-step01">
      <FormBuilder
        items={fields}
        onChange={handleOnChange}
        defaultData={defaultData}
      />
      <div className="urlDetails">
        *Vous n’avez pas de nom de domaine ? Pas de panique, votre site Internet
        répondra aussi à « {instance?.initials.toLocaleLowerCase()}.ffbad.club
        ». Pensez à faire pointer votre nom de domaine sur notre machine si vous
        voulez utiliser votre nom de domaine
      </div>
    </div>
  );
};

export default Step01;

const replaceUnwantedCharacters = (url: string = ""): string => {
  return url.replace(/http?(s):\/\//, "").split("/")[0];
};
