import { IPageProperties, ComponentTypes } from "ffbad-components";
import { randomString } from "utils/string/string.utils";

const DEFAULT_ADD_SECTION_LABEL = "Ajouter une section";
const DEFAULT_TOP_LEVEL_COMPONENT_ID = "top";
export const DEFAULT_ADD_SECTION_ID = "first-section";

const buildDefaultPageProperties = (
  onClick: (componentId: string) => void
): IPageProperties => {
  return {
    components: [
      {
        id: DEFAULT_TOP_LEVEL_COMPONENT_ID,
        type: ComponentTypes.TopLevel,
      },
      {
        id: randomString(),
        type: ComponentTypes.Header,
        props: {},
        parentId: DEFAULT_TOP_LEVEL_COMPONENT_ID,
      },
      {
        id: DEFAULT_ADD_SECTION_ID,
        type: ComponentTypes.AddElement,
        props: {
          label: DEFAULT_ADD_SECTION_LABEL,
          onClick: () => onClick(DEFAULT_ADD_SECTION_ID),
        },
        parentId: DEFAULT_TOP_LEVEL_COMPONENT_ID,
      },
      {
        id: randomString(),
        type: ComponentTypes.Footer,
        props: {
          displayLogo: true,
          displayAgenda: true,
          displayContact: true,
          displayNews: true,
        },
        parentId: DEFAULT_TOP_LEVEL_COMPONENT_ID,
      },
    ],
  };
};

export default buildDefaultPageProperties;
