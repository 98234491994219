import React, { useRef, useState } from "react";
import { Modal } from "ffbad-components";

import usePerson from "hooks/usePerson";
import AddAction from "../AddAction";
import SelectImage from "../SelectImage";

interface IProps {
  handleOnChangeImage: (image: FormData) => void;
  setImagePath: (path: string) => void;
  imagePath: string;
}
const { REACT_APP_CDN_URL } = process.env;

const UploadImage: React.FC<IProps> = (props: IProps) => {
  const { handleOnChangeImage, imagePath, setImagePath } = props;
  const person = usePerson();
  const inputFileRef = useRef<any>();
  const [imagePreview, setImagePreview] = useState<File>();
  const [modalVisible, setModalVisible] = useState(false);
  const [tmpImagePath, setTmpImagePath] = useState("");

  const handleOnUploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.currentTarget.files) {
      return;
    }

    const formData = new FormData();
    formData.append("title", "Sans titre");
    formData.append("description", "");
    formData.append(
      "filename",
      e.currentTarget.files[0].name
        .replace(" ", "")
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
    );
    formData.append("ownerId", person.player.instance.instanceId);
    formData.append("image", e.currentTarget.files[0]);

    setImagePreview(e.currentTarget.files[0]);
    handleOnChangeImage(formData);
    setImagePath("");
  };

  const handleOnModalSuccess = () => {
    setModalVisible(false);
    setImagePreview(undefined);
    setImagePath(tmpImagePath);
  };

  return (
    <>
      <div className="label">Image</div>
      <div className="uploadImageContainer">
        <div className="uploadImage">
          {imagePreview ? (
            <img className="image" alt="preview" src={URL.createObjectURL(imagePreview)} />
          ) : imagePath !== "" ? (
            <div
              className="image"
              style={
                !imagePath.includes("http")
                  ? {
                      backgroundImage: `url("${REACT_APP_CDN_URL}${imagePath}")`,
                    }
                  : {
                      backgroundImage: `url("${imagePath}")`,
                    }
              }
            />
          ) : (
            <div
              className="image-empty"
              style={{
                backgroundImage: `url("https://via.placeholder.com/200x150")`,
              }}
            />
          )}
        </div>
        <div>
          <input
            ref={inputFileRef}
            type="file"
            accept="image/png, image/jpeg"
            style={{ display: "none" }}
            onChange={handleOnUploadImage}
          />
          <AddAction
            onClick={() => {
              inputFileRef.current.click();
            }}
          >
            Ajouter une image depuis votre appareil
          </AddAction>
        </div>
        <div className="separator">OU</div>
        <div className="addFromGallery">
          <AddAction
            onClick={() => {
              setModalVisible(true);
              const modalback = document.getElementsByClassName(
                "modal__backdrop"
              ) as HTMLCollectionOf<HTMLElement>;
              if (modalback) {
                modalback[0].style.height = "110%";
              }
            }}
          >
            Ajouter une image depuis la gallerie
          </AddAction>
          <Modal
            visible={modalVisible}
            title="Choisissez une image"
            onSuccess={handleOnModalSuccess}
            onClose={() => setModalVisible(false)}
          >
            <SelectImage
              setImagePath={setTmpImagePath}
              imagePath={tmpImagePath}
            />
          </Modal>
        </div>
      </div>
    </>
  );
};

export default UploadImage;
