import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import { buildClassName } from "utils/dom/dom.utils";

import "./index.scss";

interface IProps {
  title: string;
  description?: string;
  onSelectionChange: (selected: boolean) => void;
}

const ClickableCard: React.FC<IProps> = (props: IProps) => {
  const { title, description, onSelectionChange } = props;
  const [isSelected, setIsSelected] = useState(false);
  const iconClassName = buildClassName("clickable-card--icon", [
    [isSelected, "clickable-card--icon--selected"],
  ]);

  const handleOnClick = () => {
    setIsSelected(!isSelected);
    onSelectionChange(!isSelected);
  };

  return (
    <div className="clickable-card" onClick={handleOnClick}>
      <span className={iconClassName}>
        <FontAwesomeIcon icon={faCheck as any} />
      </span>

      <h1 className="clickable-card--title">{title}</h1>
      <span className="clickable-card--description">{description}</span>
    </div>
  );
};

export default ClickableCard;
