import React from "react";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { IFormInput } from "components/FormBuilder/interfaces";
import { buildClassName, isAccessibleCheckboxKeys } from "utils/dom/dom.utils";

import "./index.scss";

interface IProps {
  field: IFormInput;
  value: boolean;
  onChange: (key: string, value: boolean) => void;
}

const Checkbox: React.FC<IProps> = (props: IProps) => {
  const { field, value, onChange } = props;
  const className = buildClassName("checkbox-container", [[value, "checked"]]);

  const handleOnChange = () => {
    const tempValue = !value;
    onChange(field.id, tempValue);
  };

  return (
    <div
      id={field.id}
      className={className}
      role="checkbox"
      tabIndex={0}
      onKeyDown={(e) => {
        if (isAccessibleCheckboxKeys(e)) {
          handleOnChange();
        }
      }}
      aria-checked={value}
      onClick={handleOnChange}
    >
      <div className="content">
        {value && <FontAwesomeIcon icon={faCheck as any} />}
      </div>
    </div>
  );
};

export default Checkbox;
