import React, { useState, useCallback, useEffect } from "react";

import { useUsefulDocumentService, useMainState, usePerson } from "hooks";
import { IUsefulDocument } from "services/service.interfaces";
import { Loading } from "components";
import DisplayOneUsefulDocument from "../DisplayOneUsefulDocument";
import AddButton from "../AddButton";

const YourUsefulDocuments: React.FC = () => {
  const service = useUsefulDocumentService();
  const { websiteConfiguration } = useMainState();
  const [documents, setDocuments] = useState<IUsefulDocument[]>([]);
  const [loading, setLoading] = useState(true);
  const person = usePerson();

  const refresh = useCallback(() => {
    if (!websiteConfiguration) {
      return;
    }

    setLoading(true);

    const { promise } = service.allByOwnerId(person.player.instance.instanceId);

    promise.then((response) => {
      setDocuments(response);
      setLoading(false);
    });
    // eslint-disable-next-line
  }, [websiteConfiguration]);

  useEffect(refresh, []);

  return (
    <div className="useful-documents__your-useful-documents">
      <h1>Vos documents utiles</h1>

      {loading && <Loading />}

      <AddButton />

      {!loading && (
        <div className="useful-documents__your-useful-documents--list">
          {documents.map((aDocument) => (
            <DisplayOneUsefulDocument
              key={aDocument.id}
              aDocument={aDocument}
              onRefresh={refresh}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default YourUsefulDocuments;
