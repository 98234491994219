import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";

import "./styles.scss";

interface IProps {
  domainName: String;
  handleOnDelete: () => void;
}

const DisplayDomainName: React.FC<IProps> = (props: IProps) => {
  const { domainName, handleOnDelete } = props;

  return (
    <div
      className="domainName"
      title="Supprimer ce nom de domaine"
      onClick={handleOnDelete}
    >
      <span className="domainName--icon">
        <FontAwesomeIcon icon={faMinusCircle as any} />
      </span>
      <span className="domainName--label">{domainName}</span>
    </div>
  );
};

export default DisplayDomainName;
