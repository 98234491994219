import React from "react";

import "./index.scss";

interface IProps { }

const Footer: React.FC<IProps> = (props: IProps) => {
  return (
    <footer style={{ paddingLeft: '10px' }}>
      Une plateforme numérique élaborée par ©Supralog - Tous droits réservés.
    </footer>
  );
};

export default Footer;
