import { TreeItem } from "react-sortable-tree";

import { IWebsiteConfig } from "core/interfaces.config";
import { PageService, ConfigService } from "services";
import { IPage } from "services/service.interfaces";
import { randomString } from "utils/string/string.utils";

class MenuService {
  private configService: ConfigService;
  private pageService: PageService;
  private websiteConfiguration: IWebsiteConfig | null = null;
  private instanceId: string = "";
  public pages: IPage[] = [];

  constructor() {
    this.pageService = new PageService();
    this.configService = new ConfigService();
  }

  public init = (websiteConfiguration: IWebsiteConfig, instanceId: string) => {
    this.websiteConfiguration = websiteConfiguration;
    this.instanceId = instanceId;

    return this.findMyPages();
  };

  public getDnDItems = (): TreeItem[] => {
    if (!this.websiteConfiguration) {
      return [];
    }

    const { items } = this.websiteConfiguration.configuration.menu;
    return this.convertToDnD(items);
  };

  public persist = (items: TreeItem[]): Promise<IWebsiteConfig> => {

    const newItems = this.convertFromDnD(items);
    const newConfiguration: IWebsiteConfig = Object.assign(
      {},
      this.websiteConfiguration
    );
    newConfiguration.configuration.menu.items = newItems;

    const { promise } = this.configService.updateWebsiteConfiguration(
      newConfiguration
    );

    return promise;
  };

  public pagesNotInMenu = (tempMenuItems: TreeItem[]): IPage[] => {
    const flattenTreeItems = this.flatten(tempMenuItems);
    const treeItemsId = flattenTreeItems
      .filter((item) => item.internalArgs && item.internalArgs.pageId)
      .map((item) => item.internalArgs.pageId);

    const output = this.pages.filter((page) => !treeItemsId.includes(page.id));
    return output;
  };

  public generateUrlFromLabel = (page: IPage): string =>
    "/" +
    page.label
      .replace(" ", "-")
      .replace(/[^a-zA-Z-]/g, "")
      .toLowerCase();

  /*
   * Utils.
   */
  private findMyPages = () => {
    const { promise } = this.pageService.findByOwnerId(this.instanceId);
    return promise.then((pages) => (this.pages = pages));
  };

  private convertToDnD = (items: any[]): TreeItem[] => {
    const filter = this.filterItemsWithMyPages;

    const output: TreeItem[] = items.filter(filter).map((item) => ({
      title: item.label,
      subtitle: item.url,
      expanded: true,
      internalArgs: { key: randomString(), ...item },
      children: item.menu
        ? item.menu.filter(filter).map((subItem: any) => ({
          title: subItem.label,
          subtitle: subItem.url,
          internalArgs: { key: randomString(), ...subItem },
        }))
        : [],
    }));

    return output;
  };

  private convertFromDnD = (items: any[]) => {
    return items.map((item) => ({
      pageId: item.internalArgs.pageId,
      url: item.subtitle,
      label: item.internalArgs.label,
      menu: item.children
        ? item.children.map((subItem: any) => ({
          pageId: subItem.internalArgs.pageId,
          url: subItem.internalArgs.url,
          label: subItem.internalArgs.label,
        }))
        : [],
    }));
  };

  private filterItemsWithMyPages = (item: any) => {
    return (
      !item.pageId ||
      (item.pageId &&
        item.pageId.length !== 0 &&
        this.pages.map(({ id }) => id).includes(item.pageId))
    );
  };

  private flatten = (items: TreeItem[]): any[] => {
    const output = items.reduce((acc, current, index) => {
      acc.push(current);

      if (current.children && current.children.length) {
        (current.children as TreeItem[]).forEach((child) => acc.push(child));
      }

      return acc;
    }, [] as any[]);

    return output;
  };
}

export default MenuService;
