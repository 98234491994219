import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ISide, IWebsiteConfig } from "core/interfaces.config";
import { DISPLAY_NOTIFICATION, SET_CONFIGURATION } from "core/redux/reducer/main.reducer";
import { useMainState } from "hooks";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { ConfigService } from "services";
import { applyWebsiteConfiguration } from "utils/dom/dom.utils";

import "./index.scss";

const service = new ConfigService();


interface IProps { }

const LayoutConfiguration: React.FC<IProps> = (props: IProps) => {
  const { websiteConfiguration } = useMainState();
  const dispatch = useDispatch();
  const [data, setData] = useState<ISide>(websiteConfiguration?.configuration.menu.side || 'top');
  const [canSave, setCanSave] = useState(false);

  const handleOnLayoutChange = () => {
    setCanSave(false);
    const newConfiguration: IWebsiteConfig = {
      ...(websiteConfiguration as IWebsiteConfig),
    };
    newConfiguration.configuration.menu.side = data;

    const { promise } = service.updateWebsiteConfiguration(newConfiguration);
    promise.then((updatedConfiguration) => {
      dispatch({
        type: DISPLAY_NOTIFICATION,
        payload: {
          message: "L'emplacement du menu a bien été sauvegardé",
        },
      });

      applyWebsiteConfiguration(updatedConfiguration);
      dispatch({ type: SET_CONFIGURATION, payload: updatedConfiguration });
      window.location.reload();
    });
  }

  const onChangeValue = (event: any) => {
    if (event.target.value === websiteConfiguration?.configuration.menu.side) {
      setCanSave(false);
    } else {
      setCanSave(true);
    }
    setData(event.target.value)
  }

  return (
    <div>
      <h2>L'emplacement du menu sur votre site</h2>
      <p>Choisissez l'emplacement de votre menu</p>
      <div onChange={onChangeValue} className="layoutConfiguration">
        <div className="form">
          <div className="input"><input type="radio" value="top" name="top" checked={data === 'top'} />En haut</div>
          <div className="input"><input type="radio" value="left" name="left" checked={data === 'left'} />A gauche</div>
        </div>
        <div
          role="button"
          tabIndex={canSave ? 0 : undefined}
          className="save-button"
          data-disabled={!canSave}
          title="Sauvegarder les modifications"
          onClick={handleOnLayoutChange}
        >
          <FontAwesomeIcon icon={faSave as any} />
        </div>
      </div>
    </div>
  );
};

export default LayoutConfiguration;
