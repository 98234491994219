import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { useDispatch } from "react-redux";

import { CLEAR_LEFT_MENU_STATE } from "core/redux/reducer/leftMenu.reducer";
import { ROUTE_PATHS, ROUTE_KEYS } from "core/router/route.keys";
import { withLayout, EditorHeader } from "components";
import LeftMenu from "components/PageRenderer/components/LeftMenu";
import YourUsefulDocuments from "./components/YourUsefulDocuments";
import UsefulDocumentsForm from "./components/UsefulDocumentsForm";

import "./index.scss";

const UsefulDocuments: React.FC = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: CLEAR_LEFT_MENU_STATE });
    }, [dispatch]);

    return (
        <>
            <EditorHeader
                currentPageLabel="Documents utiles"
            />

            <div className="page-renderer">
                <LeftMenu />

                <section className="page-renderer-container">
                    <div className="useful-documents">
                        <Switch>
                            <Route
                                path={ROUTE_PATHS[ROUTE_KEYS.USEFUL_DOCUMENTS]}
                                component={YourUsefulDocuments}
                                exact={true}
                            />
                            <Route
                                path={ROUTE_PATHS[ROUTE_KEYS.USEFUL_DOCUMENTS_CREATE]}
                                component={UsefulDocumentsForm}
                            />
                            <Route
                                path={ROUTE_PATHS[ROUTE_KEYS.USEFUL_DOCUMENTS_EDIT]}
                                component={UsefulDocumentsForm}
                            />
                        </Switch>
                    </div>
                </section>
            </div>
        </>
    );
};

export default withLayout(UsefulDocuments);
