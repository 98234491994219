import React from "react";
import { Button } from "ffbad-components";

interface IProps {
  onCancel: () => void;
  onSave: () => void;
  saveLabel?: string;
  cancelLabel?: string;
  disable?: boolean;
}

const Actions: React.FC<IProps> = (props: IProps) => {
  const {
    onCancel,
    onSave,
    saveLabel = "Sauvegarder",
    cancelLabel = "Annuler",
    disable = false,
  } = props;

  const buttons = [
    {
      label: cancelLabel,
      onClick: onCancel,
    },
    {
      label: saveLabel,
      onClick: onSave,
    },
  ];

  return (
    <div className="news-form__actions">
      {buttons.map((button) => (
        <Button
          key={button.label}
          onClick={button.onClick}
          safetySpacing={false}
          isEditor
          disabled={disable}
        >
          {button.label}
        </Button>
      ))}
    </div>
  );
};

export default Actions;
