import React from "react";

import Colors from "./components/Colors";
import DomainSelection from "./components/DomainSelection";
import LayoutConfiguration from "./components/LayoutConfiguration";
import SocialMedia from "./components/SocialMedia";
import WebsiteEnableSelection from "./components/WebsiteEnableSelection";
import Permissions from "./components/Permissions";

import "./styles.scss";

const Settings: React.FC = () => {
  return (
    <>
      <section className="settings">
        <Colors />
        <LayoutConfiguration />
        <WebsiteEnableSelection />
        <SocialMedia />
        <DomainSelection />
        <Permissions />
      </section>
    </>
  );
};

export default Settings;
