import { MasterService } from "services";

class PersonService {
  private master: MasterService;

  constructor() {
    this.master = new MasterService();
  }

  auth = (
    body: any = {}
  ): {
    promise: Promise<{
      accessToken: string;
    }>;
    cancel: () => void;
  } => {
    return this.master.post("auth/poona", body);
  };

  findPermissions = (
    personId: string,
  ): { promise: Promise<any>; cancel: any } => {
    return this.master.get<any>(`poona/person/${personId}/permissions`);
  };
}

export default PersonService;
