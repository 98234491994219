import React, { useState, useRef, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { FormBuilder, Loading } from "components";
import fields from "./fields.definition";
import { ROUTE_PATHS, ROUTE_KEYS } from "core/router/route.keys";
import Actions from "./components/Actions";
import useMainState from "hooks/useMainState";
import { DISPLAY_NOTIFICATION } from "core/redux/reducer/main.reducer";
import { UsefulLinkService } from "services";
import { IUsefulLink } from "services/service.interfaces";

interface IProps {}

const UsefulLinksForm: React.FC<IProps> = (props: IProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const { websiteConfiguration } = useMainState();
  const usefulLinkService = useRef(new UsefulLinkService());
  const isEditMode = !!id;

  const handleOnCancel = () => {
    history.push(ROUTE_PATHS[ROUTE_KEYS.USEFUL_LINKS]);
  };

  const handleOnSave = () => {
    if (!websiteConfiguration) {
      return;
    }

    const error = checkUsefulLink(data);
    if (error) {
      dispatch({
        type: DISPLAY_NOTIFICATION,
        payload: {
          message: error,
          type: "danger",
        },
      });
      return;
    }

    setLoading(true);
    usefulLinkService.current
      .save(websiteConfiguration.id, { id, ...data } as IUsefulLink)
      .promise.then(() => {
        dispatch({
          type: DISPLAY_NOTIFICATION,
          payload: {
            message: `Le lien utile a bien été ${
              isEditMode ? "mis à jour" : "créé"
            }`,
          },
        });
        handleOnCancel();
      })
      .catch(() => {
        setLoading(false);
        dispatch({
          type: DISPLAY_NOTIFICATION,
          payload: {
            message: `Le lien utile n'a pas pu être ${
              isEditMode ? "mis à jour" : "créé"
            }`,
            type: "danger",
          },
        });
      });
  };

  const handleOnChange = (data: any) => {
    setData(data);
  };

  useEffect(() => {
    if (!websiteConfiguration || !id) {
      return;
    }

    const { promise, cancel } = usefulLinkService.current.find(
      websiteConfiguration.id,
      id
    );
    promise.then((response) => {
      setLoading(false);

      setData({
        title: response.title,
        url: response.url,
      });
    });

    return () => cancel();
  }, [websiteConfiguration, id]);

  return (
    <div className="useful-links-form">
      <h1>{isEditMode ? "Edition" : "Création"} d'un lien utile</h1>

      {loading && <Loading />}

      {!loading && (
        <>
          <FormBuilder
            items={fields}
            defaultData={data}
            onChange={handleOnChange}
          />

          <Actions onCancel={handleOnCancel} onSave={handleOnSave} />
        </>
      )}
    </div>
  );
};

export default UsefulLinksForm;

const checkUsefulLink = (data: any): string | undefined => {
  if (!data.title) {
    return "Merci de saisir un nom";
  }

  if (!data.url) {
    return "Merci de saisir une URL";
  }
};
