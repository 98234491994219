import React, { useState } from "react";

import MainItem from "./components/MainItem";
import DisplayActions from "./components/DisplayActions";
import PageChooser from "./components/PageChooser";
import menuItems from "./menuItems";
import menuItemsTablet from "./menuItemsTablet";
import menuItemsMobile from "./menuItemsMobile";
import { useMediaQuery } from 'react-responsive'

import "./index.scss";
import Logout from "./components/Logout";

interface IProps {
  currentPageLabel: string;
  items?: any;
}

const EditorHeader: React.FC<IProps> = (props: IProps) => {
  const [pageChooserIsVisible, setPageChooserIsVisible] = useState(false);
  const { currentPageLabel, items } = props;

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1481px)'
  });
  const isTablet = useMediaQuery({ query: '(max-width: 1480px)' });
  const isTabletDevice = useMediaQuery({
    query: '(max-device-width: 1480px)'
  });
  const isMobile = useMediaQuery({ query: '(max-width: 990px)' });
  const isMobileDevice = useMediaQuery({
    query: '(max-device-width: 990px)'
  });

  return (
    <header className="main-header-container">
      <section className="main-header-menu">
        {(isMobile || isMobileDevice) ?
          <>

            <DisplayActions links={items ? items.right : menuItemsMobile.right} />
            <MainItem
              title={currentPageLabel}
              onClick={() => {
                setPageChooserIsVisible(!pageChooserIsVisible);
              }}
            />
            <PageChooser visible={pageChooserIsVisible} />
            <Logout />
          </>
          :
          <>
            <MainItem
              title={currentPageLabel}
              onClick={() => {
                setPageChooserIsVisible(!pageChooserIsVisible);
              }}
            />
            <PageChooser visible={pageChooserIsVisible} />

            {isDesktopOrLaptop &&
              (isTabletDevice || isTablet) ?
              <DisplayActions links={items ? items.left : menuItemsTablet.left} flex />
              :
              <DisplayActions links={items ? items.left : menuItems.left} flex />
            }
            <DisplayActions links={items ? items.right : menuItems.right} />
            <Logout />
          </>}
      </section>
    </header>
  );
};

export default EditorHeader;
