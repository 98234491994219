import React, { useState, useRef, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { FormBuilder, Loading } from "components";
import fields from "./fields.definition";
import { ROUTE_PATHS, ROUTE_KEYS } from "core/router/route.keys";
import Actions from "./components/Actions";
import useMainState from "hooks/useMainState";
import { DISPLAY_NOTIFICATION } from "core/redux/reducer/main.reducer";
import { SponsorsService } from "services";
import UploadImage from "./components/UploadImage";
import GalleryService from "services/gallery/gallery.service";
import { IAlbum } from "services/service.interfaces";

const albumService = new GalleryService();
const { REACT_APP_CDN_URL } = process.env;

const SponsorForm: React.FC= () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<{ id:string }>();
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const { websiteConfiguration } = useMainState();
  const sponsorsService = useRef(new SponsorsService());
  const isEditMode = !!id;
  const [imagePath, setImagePath] = useState('');
  const [image, setImage] = useState<FormData>(new FormData());
  const [albumId, setAlbumId] = useState('');

  const handleOnCancel = () => {
    history.push(ROUTE_PATHS[ROUTE_KEYS.SPONSORS]);
  };

  useEffect(() => {
    const { promise } = albumService.all();
    promise.then((albums: IAlbum[]) => {
      const albumNews = albums.find(album => album.title === 'Sponsors');
      if (albumNews) {
        setAlbumId(albumNews.id);
      } else {
        const { promise } = albumService.createAlbum({ title: 'Sponsors' })
        promise.then((response: any) => {
          setAlbumId(response.id);
        })
      }
    })
  }, []);

  useEffect(() => {
    if (isEditMode && websiteConfiguration) {
      sponsorsService.current
        .find(websiteConfiguration.id, id)
        .promise.then((response) => {
          setData(response);
          setImagePath(response.logo)
        })
        .catch(() => {
          setLoading(false);
          dispatch({
            type: DISPLAY_NOTIFICATION,
            payload: {
              message: "Le sponsor n'a pas pu être chargé",
              type: "danger",
            },
          });
        });
    }
  }, [dispatch, id, isEditMode, websiteConfiguration]);

  const handleOnSave = () => {
    if (!websiteConfiguration) {
      return;
    }

    const error = checkSponsor(data);
    if (error) {
      dispatch({
        type: DISPLAY_NOTIFICATION,
        payload: {
          message: error,
          type: "danger",
        },
      });
      return;
    }

    if (imagePath !== '') {
      if (imagePath.includes('http')) {
        data.logo = imagePath;
      } else {
        data.logo = REACT_APP_CDN_URL + '' + imagePath;
      }
      saveSponsors();
      return;
    }

    const { promise } = albumService.upload(albumId, image);
    promise.then((response: any) => {
      data.logo = REACT_APP_CDN_URL + '' + response.path;
      saveSponsors();
    })
      .catch(() => {
        dispatch({
          type: DISPLAY_NOTIFICATION,
          payload: {
            message: "L'image n'a pas pu être importée",
            type: "danger",
          },
        });
      })
  };

  const saveSponsors = () => {
    if (!websiteConfiguration) {
      return;
    }
    setLoading(true);
    sponsorsService.current
      .save(websiteConfiguration.id, { id, ...data } as any)
      .promise.then(() => {
        dispatch({
          type: DISPLAY_NOTIFICATION,
          payload: {
            message: `Le sponsor a bien été ${isEditMode ? "mis à jour" : "créé"
              }`,
          },
        });
        handleOnCancel();
      })
      .catch(() => {
        setLoading(false);
        dispatch({
          type: DISPLAY_NOTIFICATION,
          payload: {
            message: `Le sponsor n'a pas pu être ${isEditMode ? "mis à jour" : "créé"
              }`,
            type: "danger",
          },
        });
      });
  }

  const handleOnChange = (data: any) => {
    setData(data);
  };

  const handleOnChangeImage = (image: FormData) => {
    setImage(image);
  };

  return (
    <div className="sponsor-form">
      <h1>{isEditMode ? "Edition" : "Création"} d'un sponsor</h1>

      {loading && <Loading />}

      {!loading && (
        <>
          <FormBuilder
            items={fields}
            defaultData={data}
            onChange={handleOnChange}
          />
          <UploadImage handleOnChangeImage={handleOnChangeImage} imagePath={imagePath} setImagePath={setImagePath} />

          <Actions onCancel={handleOnCancel} onSave={handleOnSave} />
        </>
      )}
    </div>
  );
};

export default SponsorForm;

const checkSponsor = (data: any): string | undefined => {
  if (!data.label) {
    return "Merci de saisir un nom";
  }

  if (!data.url) {
    return "Merci de saisir une URL";
  }
};
