import React from "react";

import "./index.scss";

interface IProps {
  color: string;
}

const ColorChooser: React.FC<IProps> = (props: IProps) => {
  const { color } = props;

  return <div className="color-item" style={{ backgroundColor: color }} />;
};

export default ColorChooser;
