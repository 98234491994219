import { MasterService } from "services";
import { ISponsor } from "services/service.interfaces";

class SponsorsService {
  private master: MasterService;

  constructor() {
    this.master = new MasterService();
  }

  all = (websiteId: string): { promise: Promise<ISponsor[]>; cancel: any } => {
    return this.master.get<ISponsor[]>(`websites/${websiteId}/sponsors`);
  };

  find = (
    websiteId: string,
    sponsorId: string
  ): { promise: Promise<ISponsor>; cancel: any } => {
    return this.master.get<ISponsor>(
      `websites/${websiteId}/sponsors/${sponsorId}`
    );
  };

  remove = (
    websiteId: string,
    sponsorId: string
  ): { promise: Promise<any>; cancel: any } => {
    return this.master.delete(`websites/${websiteId}/sponsors/${sponsorId}`);
  };

  save = (
    websiteId: string,
    data: any
  ): { promise: Promise<any>; cancel: any } => {
    const body = { websiteId, ...data };
    let request;

    if (!!data.id) {
      const route = `websites/${websiteId}/sponsors/${data.id}`;
      request = this.master.put<ISponsor>(route, body);
    } else {
      const route = `websites/${websiteId}/sponsors`;
      request = this.master.post<ISponsor>(route, body);
    }

    return request;
  };
}

export default SponsorsService;
