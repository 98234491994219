import React, { useCallback, useRef } from "react";

import GalleryService from "services/gallery/gallery.service";
import usePerson from "hooks/usePerson";
import { DISPLAY_NOTIFICATION } from "core/redux/reducer/main.reducer";
import { useDispatch } from "react-redux";
import AddAction from "../AddAction";
import { faTrash, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { IAlbum } from "services/service.interfaces";

interface IProps {
  gallery: IAlbum;
  onRefresh: () => void;
}
const service = new GalleryService();

const Actions: React.FC<IProps> = (props: IProps) => {
  const { gallery, onRefresh } = props;
  const person = usePerson();
  const dispatch = useDispatch();
  const inputFileRef = useRef<any>();

  const handleOnUploadPictures = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.currentTarget.files) {
      return;
    }

    const arrayOfPromises = [];
    const length = e.currentTarget.files.length;

    for (let i = 0; i < length; i++) {
      const promise = uploadPicture(e.currentTarget.files[i]);
      arrayOfPromises.push(promise);
    }

    Promise.all(arrayOfPromises)
      .then(() => {
        dispatch({
          type: DISPLAY_NOTIFICATION,
          payload: {
            message: "Les modifications ont bien été effectuées",
            type: "primary",
          },
        });
      })
      .catch(() => {
        dispatch({
          type: DISPLAY_NOTIFICATION,
          payload: {
            message: "Une ou plusieurs photos n'ont pas pu être importées",
            type: "danger",
          },
        });
      })
      .finally(onRefresh);
  };

  const uploadPicture = useCallback(
    (file: File) => {
      const formData = new FormData();
      formData.append("title", "Sans titre");
      formData.append("description", "");
      formData.append("filename", file.name.replace(' ', '').normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
      formData.append("ownerId", person.player.instance.instanceId);
      formData.append("image", file);

      const { promise } = service.upload(gallery.id, formData);
      return promise;
    },
    [gallery.id, person.player.instance.instanceId]
  );

  const handleOnRemoveGallery = () => {
    if (
      !window.confirm(
        "Vous allez supprimer définitivement cette galerie. Voulez-vous continuer ?"
      )
    ) {
      return;
    }

    const { promise } = service.removeGallery(gallery.id);
    promise.then(() => {
      document.location.reload();
    });
  };
  const handleOnSetVisibleGallery = () => {
    const updatedGallery = { ...gallery };
    updatedGallery.visible = !updatedGallery.visible;
    const { promise } = service.updateGallery(gallery.id, updatedGallery);
    promise.then(() => {
      document.location.reload();
    });
  };

  return (
    <>
      <input
        ref={inputFileRef}
        type="file"
        accept="image/png, image/jpeg"
        style={{ display: "none" }}
        multiple
        onChange={handleOnUploadPictures}
      />
      <AddAction
        onClick={() => {
          inputFileRef.current.click();
        }}
      >
        Ajouter des photos
      </AddAction>
      <AddAction onClick={handleOnRemoveGallery} color="danger" icon={faTrash as any}>
        Supprimer la galerie
      </AddAction>
      <AddAction onClick={handleOnSetVisibleGallery} icon={(!gallery.visible ? faEye : faEyeSlash)  as any}>
        {gallery.visible ? "Rendre invisible" : "Rendre visible"}
      </AddAction>
    </>
  );
};

export default Actions;
