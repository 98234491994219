export const extractFormValues = (
  event: React.FormEvent<HTMLFormElement>
): any => {
  const { currentTarget } = event;
  const formData = new FormData(currentTarget);
  const keys: string[] = Array.from(formData.keys());
  const output: any = {};

  for (var key of keys) {
    output[key] = formData.get(key);
  }

  return output;
};
