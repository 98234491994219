import React from "react";

import { IUsefulLink } from "services/service.interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import UsefulLinkOverlay from "../UsefulLinkOverlay";

interface IProps {
  aLink: IUsefulLink;
  onRefresh: () => void;
}

const DisplayOneUsefulLink: React.FC<IProps> = (props: IProps) => {
  const {
    aLink: { id, title, url },
    onRefresh,
  } = props;

  return (
    <div className="display-useful-link">
      <UsefulLinkOverlay aLinkId={id} onRefresh={onRefresh} />

      <h3 className="display-useful-link--title">{title}</h3>
      <div className="display-useful-link--description">
        <a href={url} title="" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLink as any} />
          &nbsp;
          {url}
        </a>
      </div>
    </div>
  );
};

export default DisplayOneUsefulLink;
