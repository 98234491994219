import React, { useMemo } from "react";

import { IUsefulDocument } from "services/service.interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import UsefulDocumentOverlay from "../UsefulDocumentOverlay";

interface IProps {
  aDocument: IUsefulDocument;
  onRefresh: () => void;
}

const { REACT_APP_CDN_URL } = process.env;

const DisplayOneUsefulDocument: React.FC<IProps> = (props: IProps) => {
  const {
    aDocument: { id, label, url },
    onRefresh,
  } = props;

  const fileURL = useMemo(() => `${REACT_APP_CDN_URL}${url}`, [url]);

  return (
    <div className="display-useful-document">
      <UsefulDocumentOverlay aDocumentId={id} onRefresh={onRefresh} />

      <h3 className="display-useful-document--title">{label}</h3>
      <div className="display-useful-document--description">
        <a href={fileURL} title="" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLink as any} />
          <br />
          {fileURL}
        </a>
      </div>
    </div>
  );
};

export default DisplayOneUsefulDocument;
