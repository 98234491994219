import React from "react";

export const fixRenderer = (nodes: React.ReactNode[]): React.ReactNode[] => {
  if (!nodes[0]) {
    return nodes;
  }

  const children = (nodes[0] as any)?.props?.children || [];

  let newChildren = removeEmptyElements(children);
  newChildren = removeMultipleAddSection(newChildren);

  const fragment = React.createElement("div", {
    children: newChildren || [],
  });

  return [fragment];
};

const removeEmptyElements = (
  children: React.ReactNode[]
): React.ReactNode[] => {
  const output = children.filter(
    (p: any) => !(p?.props?.flexDirection && p?.props?.children?.length === 0)
  );

  return output;
};

const removeMultipleAddSection = (
  children: React.ReactNode[]
): React.ReactNode[] => {
  const toSkip: number[] = [];

  // Just here to compute indexes to remove.
  children.reduce((prev: React.ReactNode[], curr: any, index) => {
    const previousItem = prev.reverse()[0] as any;
    const previousType =
      previousItem?.props?.componentType || previousItem?.props?.label;
    const currentType = curr?.props?.componentType || curr?.props?.label;

    if (
      previousType === "Ajouter une section" &&
      previousType === currentType
    ) {
      toSkip.push(index);
      return prev;
    }

    return [...prev, curr];
  }, []);

  if (toSkip.length) {
    children.forEach((_, index) => {
      if (toSkip.includes(index)) {
        delete children[index];
      }
    });
  }

  return children;
};
