import React from "react";
import { Button } from "ffbad-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  onSubmit: (event: any) => void;
  isLoading: boolean;
}

const Form: React.FC<IProps> = (props: IProps) => {
  const { onSubmit, isLoading } = props;
  return (
    <form onSubmit={onSubmit}>
      <input
        name="login"
        type="text"
        className="login--input-login"
        placeholder="Numéro de licence"
        readOnly={isLoading}
        disabled={isLoading}
      />
      <input
        name="password"
        type="password"
        className="login--input-password"
        placeholder="Mot de passe"
        readOnly={isLoading}
        disabled={isLoading}
      />
      <Button
        buttonType="submit"
        safetySpacing={false}
        isEditor
        preventDefault={false}
        disabled={isLoading}
      >
        {isLoading && (
          <>
            <FontAwesomeIcon icon={faSpinner as any} pulse />
            &nbsp;
          </>
        )}
        Se connecter
      </Button>
    </form>
  );
};

export default Form;
