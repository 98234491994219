import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IPageComponentProperties } from "ffbad-components";

import { IElementMapping } from "components/ElementChooser/element.mappings";
import { useMainState } from "hooks";

import "./index.scss";

interface IProps {
  title: string;
  items: IElementMapping[];
  onSelectElement: (element: IPageComponentProperties) => void;
}

const DisplayAvailableElements: React.FC<IProps> = (props: IProps) => {
  const { title, items, onSelectElement } = props;
  const { websiteConfiguration } = useMainState();

  items.forEach((item) => {
    if (item.label === "Résultats inter-club") {
      if (websiteConfiguration) {
        item.json.props = {
          currentClubId: parseInt(websiteConfiguration.ownerId),
        };
      }
    }
  });

  return (
    <div className="display-elements-container">
      <h3 className="display-elements-title">{title}</h3>

      {items.map((item) => (
        <CustomLink key={item.label} onClick={() => onSelectElement(item.json)}>
          <FontAwesomeIcon icon={item.icon as any} />
          &nbsp;{item.label}
        </CustomLink>
      ))}
    </div>
  );
};

const CustomLink: React.FC<any> = (props: any) => {
  const { children, onClick } = props;
  return (
    <div className="display-elements-link" onClick={onClick}>
      {children}
    </div>
  );
};

export default DisplayAvailableElements;
