import { faFile } from "@fortawesome/free-solid-svg-icons";

import { ILink } from "components/EditorHeader/interfaces";
import { MENU_ACTIONS } from "components/PageRenderer/menuActions.mapping";
import { ROUTE_KEYS, ROUTE_PATHS } from "core/router/route.keys";

const menuItems: {
  left: ILink[];
  right: ILink[];
} = {
  left: [
    {
      label: "Ajouter",
      subLinks: [
        {
          label: "Page",
          icon: faFile,
          actionKey: MENU_ACTIONS.PAGE_TYPE_CHOOSER,
          permission: '40100000'
        },
      ],
      permission: '40100000'
    },
    {
      label: "Menu",
      actionKey: MENU_ACTIONS.MENU,
      permission: '40100000'
    },
    {
      label: "Ajout d'éléments",
      subLinks: [
        {
          label: "Photos",
          href: ROUTE_PATHS[ROUTE_KEYS.GALLERY],
          permission: '40300000'
        },
        {
          label: "Liens utiles",
          href: ROUTE_PATHS[ROUTE_KEYS.USEFUL_LINKS],
          permission: '40100000'
        },
        {
          label: "Documents",
          href: ROUTE_PATHS[ROUTE_KEYS.USEFUL_DOCUMENTS],
          permission: '40100000'
        },
        {
          label: "Actualités",
          href: ROUTE_PATHS[ROUTE_KEYS.NEWS],
          permission: '40200000'
        },
        {
          label: "Sponsors",
          href: ROUTE_PATHS[ROUTE_KEYS.SPONSORS],
          permission: '40100000'
        },
      ],
      permission: '40100000'
    },
  ],
  right: [
    {
      label: "Paramètres",
      actionKey: MENU_ACTIONS.SETTINGS,
      permission: '40100000'
    },
  ],
};

export default menuItems;
