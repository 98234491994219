import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { LEFT_MENU_STATE } from "core/redux/reducer/leftMenu.reducer";
import { buildClassName } from "utils/dom/dom.utils";
import { ILink } from "components/EditorHeader/interfaces";

import "./index.scss";
import { LocalStorageService } from "services";

interface IProps {
  links?: ILink[];
  flex?: boolean;
}
const localStorageService = new LocalStorageService();

const buildLink = (link: ILink, onActionCalled: (key: string) => void) => {
  const { label, icon, href, actionKey } = link;

  return (
    <div key={label} className="menu-action">
      {href && (
        <Link to={href} title={label}>
          {icon && <FontAwesomeIcon icon={icon} />}
          {label}
        </Link>
      )}

      {!href && (
        <div
          className="link"
          onClick={() => actionKey && onActionCalled(actionKey)}
        >
          {icon && <FontAwesomeIcon icon={icon} />}
          {label}
        </div>
      )}

      {link.subLinks && (
        <div className="main-header-hover">
          <div className="content">
            {link.subLinks.map((link) => buildLink(link, onActionCalled))}
          </div>
        </div>
      )}
    </div>
  );
};

const DisplayActions: React.FC<IProps> = (props: IProps) => {
  const { links = [], flex = false } = props;
  const dispatch = useDispatch();
  const className = buildClassName("main-header-menu-actions", [
    [flex, "flex"],
  ]);
  const [permissions, setPermissions] = useState<string[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setPermissions(localStorageService.getPermissions());
    setIsLoading(false);
  }, [])

  const handleOnActionCalled = (innerElementKey: string) => {
    dispatch({
      type: LEFT_MENU_STATE,
      payload: { open: true, innerElementKey },
    });
  };

  const isAllowed = (link: ILink) => {
    let result = false;
    permissions?.forEach((perm: any) => {
      if (perm.permissionId === link.permission) {
        result = true;
      }
    })
    return result;
  }

  return (
    <div className={className}>
      {!isLoading && links.map((link) => {
        if (isAllowed(link)) {
          return buildLink(link, handleOnActionCalled)
        }

        return null;
      })}
    </div>
  );
};

export default DisplayActions;
