import React, { useEffect, useState } from "react";

import { useGalleryService } from "hooks";
import { IAlbum, IImage } from "services/service.interfaces";
import { IFormInput } from "components/FormBuilder/interfaces";
import Select from "../Select";
import { Loading } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  field: IFormInput;
  value: string | number;
  onChange: (key: string, value: string | number) => void;
}
const { REACT_APP_CDN_URL } = process.env;

const SelectImage: React.FC<IProps> = (props: IProps) => {
  const { field, onChange } = props;
  const galleryService = useGalleryService();
  const [albums, setAlbums] = useState<IAlbum[]>([]);
  const [selectedAlbumId, setSelectedAlbumId] = useState<any>();
  const [selectedImage, setSelectedImage] = useState<any>("");
  const [images, setImages] = useState<IImage[]>([]);
  const [loading, setLoading] = useState(true);
  const [displayImagesList, setDisplayImagesList] = useState(true);

  const removeNotVisibleAlbums = (albums: IAlbum[]) => {
    return albums.filter((album) => album.visible === true);
  };

  useEffect(() => {
    const { promise, cancel } = galleryService.all();
    promise.then((albums) => {
      setAlbums(removeNotVisibleAlbums(albums));
      setLoading(false);
    });
    return () => cancel();
    // eslint-disable-next-line
  }, []);

  const handleSelectAlbum = (key: string, albumId: string | number) => {
    setSelectedAlbumId(albumId);
  };

  const handleSelectImage = (image: any) => {
    setSelectedImage(image);
    onChange("src", `${REACT_APP_CDN_URL}${image.path}`);
  };

  useEffect(() => {
    if (!selectedAlbumId) {
      return;
    }
    const { promise, cancel } = galleryService.findPictures(selectedAlbumId);
    promise.then((images) => {
      setImages(images);
      setLoading(false);
    });
    return () => cancel();
    // eslint-disable-next-line
  }, [selectedAlbumId]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div
      style={{
        display: "grid",
        flexDirection: "column",
        alignItems: "baseline",
      }}
    >
      <Select
        field={{ ...field, selectItems: getSelectAlbums(albums) }}
        value={selectedAlbumId}
        onChange={handleSelectAlbum}
        style={{ width: "340px" }}
      />
      {images.length > 0 && (
        <div
          className="imagesListLabel"
          onClick={() => setDisplayImagesList(!displayImagesList)}
        >
          <FontAwesomeIcon
            className="imagesListIcon"
            icon={(displayImagesList ? faChevronDown : faChevronUp) as any}
          />
          Liste des images
        </div>
      )}
      {displayImagesList && (
        <div className="imagesList">
          {images.map((image) => (
            <div key={image.id}>
              <div
                className={
                  selectedImage.id === image.id
                    ? "imagesList--itemSelected"
                    : "imagesList--item"
                }
                data-clickable="true"
              >
                <div
                  className="imagesList--background"
                  style={{
                    backgroundImage: `url("${REACT_APP_CDN_URL}${image.path}")`,
                  }}
                  onClick={() => {
                    if (selectedImage.id === image.id) {
                      handleSelectImage("");
                    } else {
                      handleSelectImage(image);
                    }
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const getSelectAlbums = (
  albums: IAlbum[] = []
): {
  key: string;
  label: string;
}[] => {
  const output = albums.map((album) => ({
    key: album.id,
    label: album.title,
  }));

  return [{ key: "", label: "Album" }, ...output];
};

export default SelectImage;
