import { MasterService, LocalStorageService } from "services";
import { IImage, IAlbum } from "services/service.interfaces";

class GalleryService {
  private master: MasterService;
  private localStorage: LocalStorageService;

  constructor() {
    this.master = new MasterService();
    this.localStorage = new LocalStorageService();
  }

  get ownerId(): string {
    const person = this.localStorage.getPerson();
    const { instanceId } = person.player.instance;
    return instanceId;
  }

  all = (): { promise: Promise<IAlbum[]>; cancel: any } => {
    return this.master.get<IAlbum[]>(`albums?ownerId=${this.ownerId}`);
  };

  findPictures = (
    albumId: string
  ): { promise: Promise<IImage[]>; cancel: any } => {
    return this.master.get<IImage[]>(`albums/${albumId}/images`);
  };

  removePicture = (albumId: string, imageId: string) => {
    return this.master.delete(`albums/${albumId}/images/${imageId}`);
  };

  upload = (albumId: string, formData: FormData) => {
    return this.master.postFormData(`albums/${albumId}/images`, formData);
  };

  createAlbum = (body: any) => {
    return this.master.post(`albums`, { ...body, ownerId: this.ownerId });
  };

  removeGallery = (albumId: string) => {
    return this.master.delete(`albums/${albumId}`);
  };

  updateGallery = (albumId: string, data: any) => {
    return this.master.put(`albums/${albumId}`, data);
  };
}

export default GalleryService;
