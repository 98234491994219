import React, { useEffect, useState } from "react";

import { ConfigService } from "services";
import { IPage } from "services/service.interfaces";
import usePerson from "hooks/usePerson";

import { Link } from "react-router-dom";
import { buildRoute } from "utils/route.utils";
import { ROUTE_KEYS } from "core/router/route.keys";

import "./index.scss";

interface IProps {
  visible: boolean;
}

const service = new ConfigService();

const PageChooser: React.FC<IProps> = (props: IProps) => {
  const { visible } = props;
  const person = usePerson();
  const [pages, setPages] = useState<IPage[]>([]);
  const [isVisible, setIsVisible] = useState(visible);
  const [isHover, setIsHover] = useState(false);

  useEffect(() => {
    if (!person || !Object.keys(person).length) {
      return;
    }

    const { instanceId } = person.player.instance;
    const { promise, cancel } = service.findPageByOwnerId(instanceId);
    promise.then(setPages);
    return () => cancel();
    // eslint-disable-next-line
  }, []);

  useEffect(() => setIsVisible(visible), [visible]);

  return (
    <div
      className="page-chooser"
      data-visible={isVisible || isHover}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      {pages.map((page) => (
        <div key={page.id} className="page-chooser--item">
          <Link
            to={buildRoute(ROUTE_KEYS.EDIT_PAGE, { pageId: page.id })}
            className="page-chooser--title"
            onClick={() => setIsHover(false)}
          >
            {page.label}
          </Link>
        </div>
      ))}
    </div>
  );
};

export default PageChooser;
