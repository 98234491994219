import React from "react";
import { Empty } from "components";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import "./index.scss";

const LeftMenuNoSelection: React.FC = () => {
  return (
    <div className="left-menu-no-selection">
      <Empty icon={faInfoCircle}>Sélectionnez un élément</Empty>
    </div>
  );
};

export default LeftMenuNoSelection;
