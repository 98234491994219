import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import "./index.scss";

interface IProps {
  children?: any;
}

const Step03: React.FC<IProps> = (props: IProps) => {
  const { children = "Chargement en cours..." } = props;

  return (
    <span className="loading-container">
      <FontAwesomeIcon icon={faSpinner as any} spin />
      &nbsp;{children}
    </span>
  );
};

export default Step03;
