import React from "react";

import { ISponsor } from "services/service.interfaces";
import SponsorOverlay from "../SponsorOverlay";
import { cutString } from "utils/string/string.utils";

interface IProps {
  aSponsor: ISponsor;
  onRefresh: () => void;
}

const MAX_CONTENT_LENGTH = 90;

const DisplayOneSponsor: React.FC<IProps> = (props: IProps) => {
  const {
    aSponsor: { id, label, logo, url },
    onRefresh,
  } = props;

  return (
    <div className="display-sponsor">
      <SponsorOverlay aSponsorId={id} onRefresh={onRefresh} />
      <div
        className="display-sponsor--image"
        style={{ backgroundImage: `url("${logo}")` }}
      />
      <h3 className="display-sponsor--title">{label}</h3>
      <div className="display-sponsor--description">
        {cutString(url, MAX_CONTENT_LENGTH)}
      </div>
    </div>
  );
};

export default DisplayOneSponsor;
