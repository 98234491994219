import React, { ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faTrash,
  faExchangeAlt,
} from "@fortawesome/free-solid-svg-icons";
import { ComponentTypes } from "ffbad-components";

import { IEditComponent } from "components/PageRenderer";

import "./index.scss";

interface IProps {
  // Shared directly by the PageRenderer component.
  onEditType: (component: IEditComponent) => void;
  onEdit: (component: IEditComponent) => void;
  onRemove: (componentId: string) => void;
  componentToEditId?: string;

  // Shared directly by the components library.
  children: ReactNode;
  componentId: string;
  componentType: ComponentTypes;
}

const Wrapper: React.FC<IProps> = (props: IProps) => {
  const {
    children,
    onEditType,
    onEdit,
    componentId,
    onRemove,
    componentType,
  } = props;

  const isMenuOrFooter = [
    ComponentTypes.Footer,
    ComponentTypes.Header,
    ComponentTypes.LeftMenu,
  ].includes(componentType);
  const actions = [
    {
      title: "Modifier le type",
      icon: faExchangeAlt,
      onClick: () => onEditType({ id: componentId, type: componentType }),
      display: !isMenuOrFooter,
    },
    {
      title: "Modifier l'élément",
      icon: faEdit,
      onClick: () => onEdit({ id: componentId, type: componentType }),
      display: true,
    },
    {
      title: "Supprimer l'élément",
      icon: faTrash,
      onClick: () => onRemove(componentId),
      display: !isMenuOrFooter,
    },
  ];

  return (
    <div id={`wrapper-${componentId}`} className="page-renderer-wrapper">
      <div className="wrapper-overlay">
        <div className="wrapper-overlay-actions">
          {actions
            .filter((action) => action.display)
            .map((action) => (
              <span
                key={action.title}
                title={action.title}
                onClick={action.onClick}
                className="action"
              >
                <FontAwesomeIcon icon={action.icon as any} />
              </span>
            ))}
        </div>
      </div>

      <div className="wrapper-opacity-controller">{children}</div>
    </div>
  );
};

export default Wrapper;
