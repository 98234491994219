import React from "react";

import { buildClassName, isAccessibleButtonKeys } from "utils/dom/dom.utils";

import "./index.scss";

interface IProps {
  title: string;
  image: string;
  selected: boolean;
  onSelect: () => void;
}

const Layout: React.FC<IProps> = (props: IProps) => {
  const { title, image, selected, onSelect } = props;
  const className = buildClassName("disposition-layout-container", [
    [selected, "selected"]
  ]);

  return (
    <div
      role="button"
      tabIndex={selected ? undefined : 0}
      className={className}
      onClick={onSelect}
      onKeyDown={e => {
        if (isAccessibleButtonKeys(e)) {
          onSelect();
        }
      }}
    >
      <div className="disposition-layout-image">
        <img src={image} alt={title} />
      </div>
      <div className="disposition-layout-title">{title}</div>
    </div>
  );
};

export default Layout;
