import { faEdit, faLink } from "@fortawesome/free-solid-svg-icons";

import { IFormInput } from "components/FormBuilder/interfaces";

const fields: IFormInput[] = [
  {
    id: "label",
    label: "Nom",
    placeholder: "Saisissez le nom",
    icon: faEdit,
  },
  {
    id: "url",
    label: "Site internet",
    placeholder: "http://...",
    icon: faLink,
  },
];

export default fields;
