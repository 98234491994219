import React, { useRef, useState, useEffect } from "react";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

import { FloatingIcon } from "components";

import "./index.scss";

interface IProps {
  logoUrl?: string;
  onChange: (logo: File) => void;
}

const DEFAULT_LOGO_URL = "/images/no-picture.png";
const DEFAULT_HOVER_TITLE = "Cliquez pour modifier votre logo";

const Logo: React.FC<IProps> = (props: IProps) => {
  const { logoUrl = DEFAULT_LOGO_URL, onChange } = props;
  const fileUploader = useRef<any>(null);
  const [urlToDisplay, setUrlToDisplay] = useState<string>();

  const handleOnClick = () => fileUploader.current.click();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
    const files = e.target.files;

    if (!files || !files.length) {
      return;
    }

    const aFile = files[0];
    const fileUrl = URL.createObjectURL(aFile);
    setUrlToDisplay(fileUrl);
    onChange(aFile);
  };

  useEffect(() => {
    setUrlToDisplay(logoUrl);
  }, [logoUrl]);

  return (
    <div className="step04-logo-container">
      <div
        className="logo-chooser"
        onClick={handleOnClick}
        title={DEFAULT_HOVER_TITLE}
      >
        <img src={urlToDisplay} alt="Votre logo" />
      </div>

      <FloatingIcon
        icon={faEdit as any}
        positions={{ right: -25, bottom: 0 }}
        onClick={handleOnClick}
        title={DEFAULT_HOVER_TITLE}
      />

      <input
        type="file"
        id="file"
        ref={fileUploader}
        onChange={handleOnChange}
        accept="image/*"
      />
    </div>
  );
};

export default Logo;
