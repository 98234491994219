import React, { useEffect, useState } from "react";
import { LocalStorageService } from "services";

const localStorageService = new LocalStorageService();

const Permissions: React.FC = () => {
    const [permissions, setPermissions] = useState<string[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        setPermissions(localStorageService.getPermissions());
        console.log(localStorageService.getPermissions());
        setIsLoading(false);
    }, [])

    return (<>
        {!isLoading && <div className="permissions">
            <h2>Vos permissions</h2>
            <ul>
                {permissions?.map((perm: any) => perm.appId === "4" && <li>{perm.name}</li>)}
            </ul>
        </div>}
    </>
    );
};

export default Permissions;