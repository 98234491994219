import React from "react";

interface IProps {
  src: string;
  alt?: string;
}

const FlexImage: React.FC<IProps> = (props: IProps) => {
  const { src, alt } = props;
  return <img src={src} alt={alt} style={{ width: "100%" }} />;
};

export default FlexImage;
