import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Notification } from "ffbad-components";

import Layout from "./components/Layout";
import { useNotification } from "hooks";
import { DISPLAY_NOTIFICATION } from "core/redux/reducer/main.reducer";

let mounted = false;

const withLayout = (Component: React.FC<any>) => (props: any) => {
  const dispatch = useDispatch();
  const notification = useNotification();

  useEffect(() => {
    mounted = true;
    return () => {
      mounted = false;
    };
  });

  const cleanNotificationFromStore = () => {
    if (!mounted) {
      return;
    }

    dispatch({ type: DISPLAY_NOTIFICATION, payload: null });
  };

  return (
    <>
      {notification && (
        <Notification
          type={notification.type}
          onHide={cleanNotificationFromStore}
          isEditor
        >
          {notification.message}
        </Notification>
      )}

      <Layout>
        <Component />
      </Layout>
    </>
  );
};

export default withLayout;
