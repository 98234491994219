import React from "react";

import { buildClassName } from "utils/dom/dom.utils";

import "./index.scss";

interface IProps {
  children: string;
  htmlFor?: string;
  disabled?: boolean;
}

const Label: React.FC<IProps> = (props: IProps) => {
  const { children, htmlFor, disabled = false } = props;
  const className = buildClassName("", [[disabled, "label-disabled"]]);

  return (
    <label className={className} htmlFor={htmlFor}>
      {children}
    </label>
  );
};

export default Label;
