import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import SortableTree, {
  TreeItem,
  removeNodeAtPath,
  changeNodeAtPath,
  find,
} from "react-sortable-tree";

import {
  SET_CONFIGURATION,
  DISPLAY_NOTIFICATION,
} from "core/redux/reducer/main.reducer";
import { IWebsiteConfig } from "core/interfaces.config";
import usePerson from "hooks/usePerson";
import useMainState from "hooks/useMainState";
import { MenuService } from "services";
import { randomString } from "utils/string/string.utils";
import AddElement from "./components/AddElement";
import RemoveElementButton from "./components/RemoveElementButton";
import Actions from "./components/Actions";
import DisplayPageNotInMenu from "./components/DisplayPageNotInMenu";

import "react-sortable-tree/style.css";
import "./styles.scss";
import { IPage } from "services/service.interfaces";
import MakeHomePageButton from "./components/MakeHomePageButton";
import { REFRESH_EDITOR_STATE } from "core/redux/reducer/editor.reducer";

const menuService = new MenuService();

// https://reactjsexample.com/drag-and-drop-sortable-component-for-nested-data-and-hierarchies/
const Menu: React.FC = () => {
  const dispatch = useDispatch();
  const [defaultItems, setDefaultItems] = useState<any[]>([]);
  const [items, setItems] = useState<TreeItem[]>([]);
  const { websiteConfiguration } = useMainState();
  const person = usePerson();
  const pagesNotInMenu = menuService.pagesNotInMenu(items);

  useEffect(() => {
    if (!websiteConfiguration || !person) {
      return;
    }

    menuService
      .init(websiteConfiguration, person.player.instance.instanceId)
      .then(() => {
        const tempItems = menuService.getDnDItems();

        setItems(tempItems);
        setDefaultItems(tempItems);
      });
    // eslint-disable-next-line
  }, [websiteConfiguration]);

  const handleOnSubmit = () => {
    if (!websiteConfiguration) {
      return;
    }

    const promise = menuService.persist(items);
    promise.then((updatedConfiguration: IWebsiteConfig) => {
      dispatch({
        type: DISPLAY_NOTIFICATION,
        payload: {
          message: "Le menu a bien été sauvegardé",
        },
      });

      dispatch({ type: SET_CONFIGURATION, payload: updatedConfiguration });
      dispatch({ type: REFRESH_EDITOR_STATE });
    });
  };

  const handleOnRemoveItem = (path: any) => {
    const newItems = removeNodeAtPath({
      treeData: items,
      path,
      getNodeKey,
    });
    setItems(newItems);
  };

  const handleOnSetNewHomePage = (path: any) => {
    const oldHome = find({
      treeData: items,
      getNodeKey,
      searchMethod: (item) => item.node.subtitle === "/",
    });
    let newItems = items;
    if (oldHome.matches[0]) {
      const oldHomePath = oldHome.matches[0].path;
      const oldHomeNode = { ...oldHome.matches[0].node };
      const newUrl =
        "/" +
        oldHome.matches[0].node.title
          ?.toString()
          .replace(" ", "-")
          .replace(/[^a-zA-Z-]/g, "")
          .toLowerCase();
      oldHomeNode.subtitle = newUrl;
      // oldHomeNode.internalArgs.url = newUrl;
      newItems = changeNodeAtPath({
        treeData: items,
        path: oldHomePath,
        newNode: oldHomeNode,
        getNodeKey,
      });
    }

    const newNode = items[path];
    newNode.subtitle = "/";
    // newNode.internalArgs.url = '/';
    const newItems2 = changeNodeAtPath({
      treeData: newItems,
      path,
      newNode,
      getNodeKey,
    });

    setItems(newItems2);
  };

  const handleOnAddItem = (title: string, internalArgs: any = {}) => {
    const newItem = {
      title,
      subtitle: undefined,
      expanded: true,
      internalArgs: {
        key: randomString(),
        pageId: "",
        label: title,
        url: "",
        ...internalArgs,
      },
      children: [],
    };

    if (!isItemAlreadyExists(newItem)) {
      setItems(items.concat(newItem));
    } else {
      dispatch({
        type: DISPLAY_NOTIFICATION,
        payload: {
          message: `L'élément "${newItem.title}" existe déjà !`,
          type: "danger",
        },
      });
    }
  };

  const isItemAlreadyExists = (newItem: TreeItem) => {
    let result = false;
    items.map((item) => {
      if (
        newItem.title?.toString().toUpperCase() ===
        item.title?.toString().toUpperCase()
      ) {
        result = true;
      }

      return item
    });
    return result;
  };

  const handleOnAddPage = (page: IPage) => {
    const url = menuService.generateUrlFromLabel(page);
    const newItem = {
      title: page.label,
      subtitle: url,
      expanded: true,
      internalArgs: {
        key: randomString(),
        pageId: page.id,
        label: page.label,
        url,
      },
      children: [],
    };

    setItems(items.concat(newItem));
  };

  const handleOnCancel = () => {
    setItems(defaultItems);

    dispatch({
      type: DISPLAY_NOTIFICATION,
      payload: {
        message: "Le menu a bien été rétabli",
      },
    });
  };

  return (
    <section className="edit-menu">
      <h2>Modification du menu</h2>

      <AddElement onAdd={handleOnAddItem} />

      {pagesNotInMenu.length !== 0 && (
        <>
          <h3>Pages à rajouter</h3>

          {pagesNotInMenu.map((page) => (
            <DisplayPageNotInMenu
              key={page.id}
              page={page}
              handleOnAdd={() => handleOnAddPage(page)}
            />
          ))}
        </>
      )}

      <h3>Votre menu</h3>

      <Actions
        onSubmit={handleOnSubmit}
        onCancel={handleOnCancel}
        hideCancelButton
      />

      {items && items.length !== 0 && (
        <SortableTree
          maxDepth={2}
          scaffoldBlockPxWidth={25}
          treeData={items}
          onChange={setItems}
          generateNodeProps={({ node, path }) => ({
            buttons: [
              <MakeHomePageButton
                onClick={() => handleOnSetNewHomePage(path)}
                selected={node.subtitle === "/"}
              />,
              <RemoveElementButton onClick={() => handleOnRemoveItem(path)} />,
            ],
          })}
        />
      )}
    </section>
  );
};

export default Menu;

const getNodeKey = (data: any) => data.treeIndex;
