import React, { useState } from "react";

import { IFormInput, IFormInputTypes } from "components/FormBuilder/interfaces";
import { inputIsValid } from "utils/form/form.resolver";

interface IProps {
  field: IFormInput;
  value: string | number;
  onChange: (key: string, value: string | number) => void;
}

const DEFAULT_TYPE: IFormInputTypes = "text";

const Input: React.FC<IProps> = (props: IProps) => {
  const { field, value, onChange } = props;
  const [isValid, setIsValid] = useState(true);

  const handleOnChange = (e: any) => {
    let tempValue = e.currentTarget.value;

    if (tempValue) {
      switch (field.type) {
        case "number":
          tempValue = Number(tempValue);

          if (field.max && tempValue > field.max) {
            tempValue = field.max;
          } else if (field.min && tempValue <= field.min) {
            tempValue = field.min;
          }

          break;
        default:
        case "text":
          break;
      }
    }

    onChange(field.id, tempValue);

    const isValid = inputIsValid(tempValue, field.resolvers);
    setIsValid(isValid);
  };

  return (
    <input
      id={field.id}
      type={field.type || DEFAULT_TYPE}
      min={field.min}
      max={field.max}
      step={field.step || 1}
      placeholder={field.placeholder}
      onChange={handleOnChange}
      required={field.required}
      value={value}
      readOnly={field.readonly}
      className={`flex-1${isValid ? "" : " input-not-valid"}`}
    />
  );
};

export default Input;
