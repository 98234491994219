import React, { useState, useEffect } from "react";

import { PageService } from "services";
import { ICreateWizardStep03 } from "core/interfaces/createWizard/createWizard.interface";
import { Loading, ClickableCard } from "components";

import "./index.scss";

const service = new PageService();

interface IProps {
  defaultData: ICreateWizardStep03;
  onChange: (data: ICreateWizardStep03) => void;
}

const Step03: React.FC<IProps> = (props: IProps) => {
  const { defaultData, onChange } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [predefinedPages, setPredefinedPages] = useState<any[]>([]);

  const handleOnSelectionChange = (item: any, selected: boolean) => {
    const newData = { ...defaultData };
    const exists =
      newData.predefinedPages.find((pageId) => item.id === pageId) !==
      undefined;

    if (selected && !exists) {
      newData.predefinedPages.push(item.id);
    } else if (!selected && exists) {
      newData.predefinedPages = newData.predefinedPages.filter(
        (pageId) => pageId !== item.id
      );
    }

    onChange(newData);
  };

  useEffect(() => {
    const { promise, cancel } = service.findPredefined();
    promise
      .then((response) => {
        setPredefinedPages(response);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));

    return () => cancel();
  }, []);

  return (
    <div className="step-container step-container-step03">
      {isLoading && <Loading />}

      {!isLoading && (
        <div className="predefined-pages-container">
          {predefinedPages.map((item) => (
            <ClickableCard
              key={item.label}
              title={item.label}
              description={item.description}
              onSelectionChange={(selected) =>
                handleOnSelectionChange(item, selected)
              }
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Step03;
