import React, { useState, useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { IPageProperties, Modal } from "ffbad-components";

import { usePageBuilder, isPrivateRoute, useMainState } from "hooks";
import { withLayout, EditorHeader, PageRenderer, Loading } from "components";
import buildDefaultPageProperties, {
  DEFAULT_ADD_SECTION_ID,
} from "./defaultPageProperties";
import {
  CLEAR_LEFT_MENU_STATE,
  SET_LEFT_MENU_ACTION,
} from "core/redux/reducer/leftMenu.reducer";
import {
  DISPLAY_NOTIFICATION,
  SET_CONFIGURATION,
} from "core/redux/reducer/main.reducer";
import { MENU_ACTIONS } from "components/PageRenderer/menuActions.mapping";
import { SET_EXISTING_COMPONENT_ID } from "core/redux/reducer/editor.reducer";
import usePerson from "hooks/usePerson";
import { ROUTE_KEYS } from "core/router/route.keys";
import { buildRoute } from "utils/route.utils";
import SelectNewHome from "./components/SelectNewHome";
import { IWebsiteConfig } from "core/interfaces.config";
import { applyWebsiteConfiguration } from "utils/dom/dom.utils";
import {
  configService,
  DEFAULT_PAGE_LABEL,
  isNewPageFromPredefined,
  newPageIsValid,
  newPageLabelIsValid,
  pageService,
  hasAlreadyMainTitle,
  removeSectionWithoutChildren,
} from "./create-page.utils";

const CreatePage: React.FC = () => {
  const dispatch = useDispatch();
  const pageBuilder = usePageBuilder();
  pageBuilder.properties.generateTitle = true;
  const history = useHistory();
  const { pageId } = useParams<{ pageId: string }>();
  const person = usePerson();
  const { websiteConfiguration } = useMainState();
  const [defaultPageProperties, setDefaultPageProperties] = useState<
    IPageProperties
  >();
  const [isLoading, setIsLoading] = useState(true);
  const [canPublish, setCanPublish] = useState(true);
  const [canDelete, setCanDelete] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedPageId, setSelectedPageId] = useState("");
  isPrivateRoute(history);

  const handleOnPublish = () => {
    const components = pageBuilder.cleanForPublish();
    const finalPage: IPageProperties = {
      ...defaultPageProperties,
      ownerId: person.player.instance.instanceId,
      components,
      label: pageBuilder.properties.label,
      description: pageBuilder.properties.description,
    };

    let promise: Promise<any>;
    finalPage.components = removeSectionWithoutChildren(finalPage.components);

    if (
      pageBuilder.properties.generateTitle &&
      !hasAlreadyMainTitle(finalPage.components)
    ) {
      finalPage.components?.splice(2, 0, {
        id: "pageMainTitle",
        type: 1009,
        props: {
          content: pageBuilder.properties.label || "",
        },
        parentId: "top",
      });
    } else if (
      !pageBuilder.properties.generateTitle &&
      hasAlreadyMainTitle(finalPage.components)
    ) {
      finalPage.components = finalPage.components?.filter(
        (component) => component.id !== "pageMainTitle"
      );
    }

    if (isNewPageFromPredefined() || !pageId) {
      if (!newPageIsValid(finalPage)) {
        dispatch({
          type: DISPLAY_NOTIFICATION,
          payload: {
            message: "Veuillez saisir un nom et une description à votre page",
            type: "danger",
          },
        });
        return;
      }

      if (!newPageLabelIsValid(finalPage)) {
        dispatch({
          type: DISPLAY_NOTIFICATION,
          payload: {
            message: "Veuillez saisir un nom différent",
            type: "danger",
          },
        });
        return;
      }

      promise = pageService.create({ ...finalPage, id: undefined }).promise;
    } else {
      promise = pageService.update(pageId, finalPage).promise;
    }

    promise
      .then((response) => {
        dispatch({
          type: DISPLAY_NOTIFICATION,
          payload: {
            message: "Les modifications ont bien été sauvegardées",
            type: "primary",
          },
        });

        window.location.href = buildRoute(ROUTE_KEYS.EDIT_PAGE, {
          pageId: response.id,
        });
      })
      .catch(() => {
        dispatch({
          type: DISPLAY_NOTIFICATION,
          payload: {
            message: "Les modifications n'ont pas été sauvegardées",
            type: "danger",
          },
        });
      });
  };

  const handleOnOpenModal = () => {
    setModalVisible(true);
    const wrapperOverlay = document.getElementsByClassName(
      "wrapper-overlay"
    ) as HTMLCollectionOf<HTMLElement>;
    if (wrapperOverlay) {
      for (let item of wrapperOverlay as any) {
        item.style.display = "none";
      }
    }
  };

  const handleOnCloseModal = () => {
    setModalVisible(false);
    const wrapperOverlay = document.getElementsByClassName(
      "wrapper-overlay"
    ) as HTMLCollectionOf<HTMLElement>;
    if (wrapperOverlay) {
      for (let item of wrapperOverlay as any) {
        item.style.display = "";
      }
    }
  };

  const updateConfiguration = (homepage: boolean) => {
    const newConfiguration: IWebsiteConfig = {
      ...(websiteConfiguration as IWebsiteConfig),
    };
    if (homepage) {
      newConfiguration.configuration.homePage.pageId = selectedPageId;
      newConfiguration.configuration.menu.items.forEach((item) => {
        if (item.pageId === pageId) {
          item.pageId = selectedPageId;
        }
      });
    } else {
      newConfiguration.configuration.menu.items = newConfiguration.configuration.menu.items.filter(
        (item) => item.pageId !== pageId
      );
    }

    const { promise } = configService.updateWebsiteConfiguration(
      newConfiguration
    );
    promise.then((updatedConfiguration) => {
      if (homepage) {
        dispatch({
          type: DISPLAY_NOTIFICATION,
          payload: {
            message: "La page d'accueil a bien été changée",
          },
        });
      }

      applyWebsiteConfiguration(updatedConfiguration);
      dispatch({ type: SET_CONFIGURATION, payload: updatedConfiguration });
      deletePage();
    });
  };

  const deletePage = () => {
    const promise = pageService.delete(pageId).promise;
    setIsLoading(true);
    promise
      .then(() => {
        dispatch({
          type: DISPLAY_NOTIFICATION,
          payload: {
            message: "La page a bien été supprimée",
            type: "primary",
          },
        });
        setIsLoading(false);
        history.push(buildRoute(ROUTE_KEYS.CREATE_NEW_PAGE));
      })
      .catch(() => {
        dispatch({
          type: DISPLAY_NOTIFICATION,
          payload: {
            message: "La page n'a pas pu etre supprimée",
            type: "danger",
          },
        });
        setIsLoading(false);
      });
  };

  const handleOnDelete = () => {
    if (pageId === websiteConfiguration?.configuration.homePage.pageId) {
      handleOnOpenModal();
    } else {
      updateConfiguration(false);
    }
  };

  const handleOnAddSection = useCallback(
    (componentId: string) => {
      dispatch({
        type: SET_EXISTING_COMPONENT_ID,
        payload: componentId,
      });

      dispatch({
        type: SET_LEFT_MENU_ACTION,
        payload: {
          open: true,
          innerElementKey: MENU_ACTIONS.SECTION_CHOOSER,
          props: {
            canPublish: canPublish,
            canDelete: canDelete,
            onPublish: handleOnPublish,
            onDelete: handleOnDelete,
          },
        },
      });

      setCanPublish(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const handleOnAddItem = useCallback(
    (componentId: string) => {
      dispatch({ type: SET_EXISTING_COMPONENT_ID, payload: componentId });
      dispatch({
        type: SET_LEFT_MENU_ACTION,
        payload: {
          open: true,
          innerElementKey: MENU_ACTIONS.ELEMENT_CHOOSER,
          props: {
            canPublish: canPublish,
            canDelete: canDelete,
            onPublish: handleOnPublish,
            onDelete: handleOnDelete,
          },
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  useEffect(() => {
    if (!pageId) {
      const tempDefaultPage = buildDefaultPageProperties(handleOnAddSection);

      if (tempDefaultPage.components) {
        dispatch({
          type: SET_EXISTING_COMPONENT_ID,
          payload: DEFAULT_ADD_SECTION_ID,
        });
      }

      setDefaultPageProperties(tempDefaultPage);
      setIsLoading(false);
      return;
    }

    const { promise, cancel } = pageService.findById(pageId);
    promise
      .then((response) => {
        setDefaultPageProperties(response);
        setIsLoading(false);
        setCanDelete(true);
        dispatch({ type: CLEAR_LEFT_MENU_STATE });
        pageBuilder.properties.generateTitle = hasAlreadyMainTitle(
          response.components
        );
      })
      .catch(({ message }) => {
        dispatch({
          type: DISPLAY_NOTIFICATION,
          payload: { message, type: "danger" },
        });

        history.push("/");
      });

    return () => cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId, dispatch, handleOnAddSection, history]);

  const handleOnModalSuccess = () => {
    handleOnCloseModal();
    updateConfiguration(true);
  };

  return (
    <>
      <EditorHeader
        currentPageLabel={
          (defaultPageProperties && defaultPageProperties.label) ||
          DEFAULT_PAGE_LABEL
        }
      />

      {!isLoading && (
        <PageRenderer
          defaultPageProperties={defaultPageProperties}
          onAddSectionClick={handleOnAddSection}
          onAddItemClick={handleOnAddItem}
          canPublish={canPublish}
          canDelete={canDelete}
          onPublish={handleOnPublish}
          onDelete={handleOnDelete}
        />
      )}
      {isLoading && <Loading />}

      <Modal
        visible={modalVisible}
        title="Attention"
        onSuccess={handleOnModalSuccess}
        onClose={() => handleOnCloseModal()}
      >
        <SelectNewHome
          currentPageId={pageId}
          setSelectedPageId={setSelectedPageId}
          ownerId={websiteConfiguration?.ownerId || ""}
        />
      </Modal>
    </>
  );
};

export default withLayout(CreatePage);
