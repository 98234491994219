import React, { useMemo } from "react";

import { INews } from "services/service.interfaces";
import { cutString, stripHtml } from "utils/string/string.utils";
import NewsOverlay from "../NewsOverlay";

interface IProps {
  aNews: INews;
  onRefresh: () => void;
}

const MAX_CONTENT_LENGTH = 90;
const { REACT_APP_CDN_URL } = process.env;

const DisplayOneNews: React.FC<IProps> = (props: IProps) => {
  const {
    aNews: { id, title, image, shortContent, highlighted },
    onRefresh,
  } = props;

  const imageUrl = useMemo(
    () => (image.includes("http") ? image : `${REACT_APP_CDN_URL}${image}`),
    [image]
  );

  return (
    <div className="display-news">
      <NewsOverlay
        aNewsId={id}
        isInCarrousel={highlighted}
        onRefresh={onRefresh}
      />
      <div
        className="display-news--image"
        style={{ backgroundImage: `url("${imageUrl}")` }}
      />
      <h3 className="display-news--title">{title}</h3>
      <div className="display-news--description">
        {cutString(
          stripHtml(shortContent.replace(/(\/\w+>)/g, "$1 ")),
          MAX_CONTENT_LENGTH
        )}
      </div>
    </div>
  );
};

export default DisplayOneNews;
