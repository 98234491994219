import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./index.scss";

interface IProps {
  icon: any;
}

const Icon: React.FC<IProps> = (props: IProps) => {
  const { icon } = props;

  return <FontAwesomeIcon className="input-icon" icon={icon} />;
};

export default Icon;
