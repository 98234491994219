import React, { useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";

import { isPrivateRoute, usePerson } from "hooks";
import { ROUTE_KEYS, ROUTE_PATHS } from "core/router/routes.config";
import { PageService, ConfigService, LocalStorageService } from "services";
import { buildRoute } from "utils/route.utils";
import { withLayout } from "components";

const service = new PageService();
const configService = new ConfigService();
const localStorageService = new LocalStorageService();

const Home: React.FC = () => {
  const history = useHistory();
  isPrivateRoute(history);
  const person = usePerson();

  const redirect = useCallback(() => {
    if (!person || !person.player || !person.player.instance) {
      history.push(ROUTE_PATHS[ROUTE_KEYS.LOGIN]);
      return;
    }

    configService
      .getByOwnerId(person.player.instance.instanceId)
      .promise.then((payload) => {
        localStorageService.set("wid", payload.id);
        history.push(ROUTE_PATHS[ROUTE_KEYS.CREATE_NEW_PAGE]);
      })
      .catch(() => {
        history.push(ROUTE_PATHS[ROUTE_KEYS.WIZARD]);
      });
  }, [history, person]);

  useEffect(() => {
    if (!person || !person.player || !person.player.instance) {
      return;
    }

    const { instanceId } = person.player.instance;
    const { promise, cancel } = service.findByOwnerId(instanceId);

    promise
      .then((pages) => {
        // First page will ALWAYS be the home page. See back sources if you want some informations.
        const [firstPage] = pages;

        if (!firstPage) {
          redirect();
          return;
        }

        history.push(
          buildRoute(ROUTE_KEYS.EDIT_PAGE, {
            pageId: firstPage.id,
          })
        );
      })
      .catch(() => {
        redirect();
      });

    return () => cancel();
  }, [person, history, redirect]);

  return <></>;
};

export default withLayout(Home);
