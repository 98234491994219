import React, { useState, useCallback, useEffect } from "react";

import { useUsefulLinkService } from "hooks";
import useMainState from "hooks/useMainState";
import { IUsefulLink } from "services/service.interfaces";
import { Loading } from "components";
import DisplayOneUsefulLink from "../DisplayOneUsefulLink";
import AddButton from "../AddButton";

interface IProps {}

const YourUsefulLinks: React.FC<IProps> = (props: IProps) => {
  const service = useUsefulLinkService();
  const { websiteConfiguration } = useMainState();
  const [links, setLinks] = useState<IUsefulLink[]>([]);
  const [loading, setLoading] = useState(true);

  const refresh = useCallback(() => {
    if (!websiteConfiguration) {
      return;
    }

    setLoading(true);

    const { promise } = service.all(websiteConfiguration.id);

    promise.then((response) => {
      setLinks(response);
      setLoading(false);
    });
    // eslint-disable-next-line
  }, [websiteConfiguration]);

  useEffect(refresh, []);

  return (
    <div className="useful-links__your-useful-links">
      <h1>Vos liens utiles</h1>

      {loading && <Loading />}

      <AddButton />

      {!loading && (
        <div className="useful-links__your-useful-links--list">
          {links.map((aLink) => (
            <DisplayOneUsefulLink
              key={aLink.id}
              aLink={aLink}
              onRefresh={refresh}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default YourUsefulLinks;
