import React, { useState } from "react";
import { Button } from "ffbad-components";
import { useDispatch } from "react-redux";

import { DISPLAY_NOTIFICATION } from "core/redux/reducer/main.reducer";
import GalleryService from "services/gallery/gallery.service";
import { FormBuilder } from "components";
import fields from "./fields.definition";

interface IProps {
  onRefresh: () => void;
}
const service = new GalleryService();

const GalleryForm: React.FC<IProps> = (props: IProps) => {
  const { onRefresh } = props;
  const [data, setData] = useState({});
  const dispatch = useDispatch();

  const handleOnSubmit = () => {
    const { promise } = service.createAlbum(data);
    promise
      .then(() => {
        onRefresh();
        dispatch({
          type: DISPLAY_NOTIFICATION,
          payload: { message: "La galerie a bien été créée", type: "primary" },
        });
      })
      .catch(() => {
        dispatch({
          type: DISPLAY_NOTIFICATION,
          payload: {
            message: "La galerie n'a pas pu être créée",
            type: "danger",
          },
        });
      });
  };

  return (
    <>
      <h2>Création d'une galerie</h2>

      <FormBuilder items={fields} defaultData={data} onChange={setData} />

      <Button onClick={handleOnSubmit} safetySpacing={false} isEditor>
        Créer !
      </Button>
    </>
  );
};

export default GalleryForm;
