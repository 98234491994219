import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { IPageComponentProperties } from "ffbad-components/";

import buildSectionMapping, { ISectionMapping } from "./section.mappings";
import {
  SET_EXISTING_COMPONENT_ID,
  REFRESH_EDITOR_STATE,
} from "core/redux/reducer/editor.reducer";
import { FlexImage } from "components";
import {
  SET_LEFT_MENU_ACTION,
} from "core/redux/reducer/leftMenu.reducer";
import { MENU_ACTIONS } from "components/PageRenderer/menuActions.mapping";
import { usePageBuilder, useEditorState } from "hooks";

interface IProps {
  canPublish?: boolean;
  canDelete?: boolean;

  // Handlers.
  onPublish: () => void;
  onDelete?: () => void;
}

const SectionChooser: React.FC<IProps> = (props: IProps) => {
  const { canPublish = false, onPublish, canDelete = false, onDelete } = props;
  const dispatch = useDispatch();
  const pageBuilder = usePageBuilder();
  const editorState = useEditorState();
  const [sections, setSections] = useState<ISectionMapping[]>([]);

  useEffect(() => {
    const handleOnSelectSectionPart = (elementId: any) => {
      dispatch({ type: SET_EXISTING_COMPONENT_ID, payload: elementId });
      dispatch({
        type: SET_LEFT_MENU_ACTION,
        payload: {
          open: true,
          innerElementKey: MENU_ACTIONS.ELEMENT_CHOOSER,
          props: {
            canPublish: canPublish,
            canDelete: canDelete,
            onPublish: onPublish,
            onDelete: onDelete,
          },
        },
      });
    };

    const tempSections = buildSectionMapping(handleOnSelectSectionPart);
    setSections(tempSections);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleOnSelectSection = (components: IPageComponentProperties[]) => {
    const [parentComponentToAdd, ...children] = components;
    if (!editorState.existingComponentId || !parentComponentToAdd) {
      return;
    }

    pageBuilder.replace(editorState.existingComponentId, parentComponentToAdd);
    pageBuilder.add(children);

    dispatch({
      type: SET_LEFT_MENU_ACTION,
      payload: { innerElementKey: MENU_ACTIONS.NO_SELECTION },
    });
    dispatch({ type: REFRESH_EDITOR_STATE });
  };

  return (
    <>
      <h2>Ajout d'une section</h2>
      <p>Sélectionnez la section que vous souhaitez ajouter :</p>

      {sections.map((section) => (
        <div
          key={section.image}
          className="cursor-pointer"
          title="Sélectionner cette section"
          onClick={() => handleOnSelectSection(section.json)}
        >
          <FlexImage src={section.image} />
        </div>
      ))}
    </>
  );
};

export default SectionChooser;
