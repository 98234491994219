export const facebookUrlIsValid = (url: string): boolean => {
  const regex = /https:\/\/www.facebook.com\/(.*){1,}/gi;
  return regex.test(url);
};

export const twitterUrlIsValid = (url: string): boolean => {
  const regex = /https:\/\/twitter.com\/(.*){1,}/gi;
  return regex.test(url);
};

export const instagramUrlIsValid = (url: string): boolean => {
  const regex = /https:\/\/instagram.com\/(.*){1,}/gi;
  return regex.test(url);
};

export const isRequired = (data: any): boolean => {
  return data && data !== null && data !== undefined;
};

export const inputIsValid = (
  data: any,
  resolvers: ((data: any) => boolean)[] = []
): boolean => {
  for (const resolver of resolvers) {
    if (!resolver(data)) {
      return false;
    }
  }
  return true;
};
