import { ComponentTypes, IPageComponentProperties } from "ffbad-components";
import { randomString } from "utils/string/string.utils";

export interface ISectionMapping {
  image: string;
  json: IPageComponentProperties[];
}

const buildSectionMapping = (
  handler: (elementId: string) => void
): ISectionMapping[] => {
  const section01Id = "section01" + randomString();
  const section02Id = "section02" + randomString();
  const section03Id = "section03" + randomString();
  const section04Id = "section04" + randomString();
  const section05Id = "section05" + randomString();
  const section06Id = "section06" + randomString();
  return [
    {
      image: "/images/sections/01.png",
      json: [
        {
          id: section01Id,
          type: ComponentTypes.MultipleColumnsLayout,
          props: {
            flexDirection: "column",
          },
        },
        { ...generateAddElementArgs(handler), parentId: section01Id },
      ],
    },
    {
      image: "/images/sections/02.png",
      json: [
        {
          id: section02Id,
          type: ComponentTypes.MultipleColumnsLayout,
          props: {
            flexDirection: "row",
          },
        },
        {
          id: section02Id + "-1",
          type: ComponentTypes.MultipleColumnsLayout,
          props: {
            flexDirection: "column",
          },
          parentId: section02Id,
        },
        {
          id: section02Id + "-2",
          type: ComponentTypes.MultipleColumnsLayout,
          props: {
            flexDirection: "column",
          },
          parentId: section02Id,
        },
        { ...generateAddElementArgs(handler), parentId: section02Id + "-1" },
        { ...generateAddElementArgs(handler), parentId: section02Id + "-2" },
      ],
    },
    {
      image: "/images/sections/03.png",
      json: [
        {
          id: section03Id,
          type: ComponentTypes.MultipleColumnsLayoutOneBig,
          props: { flexDirection: "row", biggerIndex: 1 },
        },
        {
          id: section03Id + "-1",
          type: ComponentTypes.MultipleColumnsLayout,
          props: {
            flexDirection: "column",
          },
          parentId: section03Id,
        },
        {
          id: section03Id + "-2",
          type: ComponentTypes.MultipleColumnsLayout,
          props: {
            flexDirection: "column",
          },
          parentId: section03Id,
        },
        { ...generateAddElementArgs(handler), parentId: section03Id + "-1" },
        { ...generateAddElementArgs(handler), parentId: section03Id + "-2" },
      ],
    },
    {
      image: "/images/sections/04.png",
      json: [
        {
          id: section04Id,
          type: ComponentTypes.MultipleColumnsLayoutOneBig,
          props: { flexDirection: "row", biggerIndex: 0 },
        },
        {
          id: section04Id + "-1",
          type: ComponentTypes.MultipleColumnsLayout,
          props: {
            flexDirection: "column",
          },
          parentId: section04Id,
        },
        {
          id: section04Id + "-2",
          type: ComponentTypes.MultipleColumnsLayout,
          props: {
            flexDirection: "column",
          },
          parentId: section04Id,
        },
        { ...generateAddElementArgs(handler), parentId: section04Id + "-1" },
        { ...generateAddElementArgs(handler), parentId: section04Id + "-2" },
      ],
    },
    {
      image: "/images/sections/05.png",
      json: [
        {
          id: section05Id,
          type: ComponentTypes.MultipleColumnsLayout,
          props: { flexDirection: "row" },
        },
        {
          id: section05Id + "-1",
          type: ComponentTypes.MultipleColumnsLayout,
          props: {
            flexDirection: "column",
          },
          parentId: section05Id,
        },
        {
          id: section05Id + "-2",
          type: ComponentTypes.MultipleColumnsLayout,
          props: {
            flexDirection: "column",
          },
          parentId: section05Id,
        },
        {
          id: section05Id + "-3",
          type: ComponentTypes.MultipleColumnsLayout,
          props: {
            flexDirection: "column",
          },
          parentId: section05Id,
        },
        { ...generateAddElementArgs(handler), parentId: section05Id + "-1" },
        { ...generateAddElementArgs(handler), parentId: section05Id + "-2" },
        { ...generateAddElementArgs(handler), parentId: section05Id + "-3" },
      ],
    },
    {
      image: "/images/sections/06.png",
      json: [
        {
          id: section06Id,
          type: ComponentTypes.MultipleColumnsLayout,
          props: { flexDirection: "row" },
        },
        {
          id: section06Id + "-1",
          type: ComponentTypes.MultipleColumnsLayout,
          props: {
            flexDirection: "column",
          },
          parentId: section06Id,
        },
        {
          id: section06Id + "-2",
          type: ComponentTypes.MultipleColumnsLayout,
          props: {
            flexDirection: "column",
          },
          parentId: section06Id,
        },
        {
          id: section06Id + "-3",
          type: ComponentTypes.MultipleColumnsLayout,
          props: {
            flexDirection: "column",
          },
          parentId: section06Id,
        },
        {
          id: section06Id + "-4",
          type: ComponentTypes.MultipleColumnsLayout,
          props: {
            flexDirection: "column",
          },
          parentId: section06Id,
        },
        { ...generateAddElementArgs(handler), parentId: section06Id + "-1" },
        { ...generateAddElementArgs(handler), parentId: section06Id + "-2" },
        { ...generateAddElementArgs(handler), parentId: section06Id + "-3" },
        { ...generateAddElementArgs(handler), parentId: section06Id + "-4" },
      ],
    },
  ];
};

const generateAddElementArgs = (handler: (elementId: string) => void) => {
  const elementId = randomString();
  return {
    id: elementId,
    type: ComponentTypes.AddElement,
    props: {
      label: "Ajouter un élément",
      onClick: () => handler(elementId),
    },
  };
};

export default buildSectionMapping;
