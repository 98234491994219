import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { useDispatch } from "react-redux";

import { CLEAR_LEFT_MENU_STATE } from "core/redux/reducer/leftMenu.reducer";
import { ROUTE_PATHS, ROUTE_KEYS } from "core/router/route.keys";
import { withLayout, EditorHeader } from "components";
import LeftMenu from "components/PageRenderer/components/LeftMenu";
import YourNews from "./components/YourNews";
import NewsForm from "./components/NewsForm";

import "./styles.scss";

const News: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: CLEAR_LEFT_MENU_STATE });
  }, [dispatch]);

  return (
    <>
      <EditorHeader
        currentPageLabel="Actualités"
      />

      <div className="page-renderer">
        <LeftMenu />

        <section className="page-renderer-container">
          <div className="news">
            <Switch>
              <Route
                path={ROUTE_PATHS[ROUTE_KEYS.NEWS]}
                component={YourNews}
                exact={true}
              />
              <Route
                path={ROUTE_PATHS[ROUTE_KEYS.NEWS_CREATE]}
                component={NewsForm}
              />
              <Route
                path={ROUTE_PATHS[ROUTE_KEYS.NEWS_EDIT]}
                component={NewsForm}
              />
            </Switch>
          </div>
        </section>
      </div>
    </>
  );
};

export default withLayout(News);
