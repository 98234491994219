import React, { useEffect, useState, ReactNode } from "react";
import {
  IBuildOptions,
  ComponentTypes,
  IPageProperties,
} from "ffbad-components";
import { useDispatch } from "react-redux";

import { MENU_ACTIONS } from "./menuActions.mapping";
import {
  SET_COMPONENT_TO_EDIT,
  REFRESH_EDITOR_STATE,
  SET_EDITOR_STATE,
} from "core/redux/reducer/editor.reducer";
import { SET_LEFT_MENU_ACTION } from "core/redux/reducer/leftMenu.reducer";
import Wrapper from "./components/Wrapper";
import LeftMenu from "./components/LeftMenu";
import { addClassTo } from "utils/dom/dom.utils";
import { usePageBuilder, useEditorState, useMainState } from "hooks";
import { DEFAULT_ADD_SECTION_ID } from "pages/CreatePage/defaultPageProperties";

import "ffbad-components/build/assets/css/bundle.css";
import "./index.scss";
import { fixRenderer } from "./page-renderer.utils";

type IProps = {
  defaultPageProperties?: IPageProperties;
  onAddSectionClick?: (componentId: string) => void;
  onAddItemClick?: (componentId: string) => void;
  canPublish?: boolean;
  canDelete?: boolean;

  // Handlers.
  onPublish: () => void;
  onDelete?: () => void;
};

export type IEditComponent = {
  id: string;
  type: ComponentTypes;
};

const EDITING_CLASSNAME = "editing";

const PageRenderer: React.FC<IProps> = (props: IProps) => {
  const {
    defaultPageProperties,
    onAddSectionClick,
    onAddItemClick,
    canPublish = false,
    onPublish,
    canDelete = false,
    onDelete,
  } = props;
  const dispatch = useDispatch();
  const editorState = useEditorState();
  const pageBuilder = usePageBuilder();
  const [elements, setElements] = useState<ReactNode[]>([]);
  const [hasAddedWrappers, setHasAddedWrappers] = useState(false);
  const { websiteConfiguration } = useMainState();

  useEffect(() => {
    if (!defaultPageProperties) {
      return;
    }

    const handleOnOpenComponent = (component: IEditComponent) => {
      const elementClassName = `wrapper-${component && component.id}`;
      addClassTo(elementClassName, EDITING_CLASSNAME);

      dispatch({
        type: SET_COMPONENT_TO_EDIT,
        payload: component,
      });
      dispatch({
        type: SET_LEFT_MENU_ACTION,
        payload: {
          open: true,
          innerElementKey: MENU_ACTIONS.EDIT_COMPONENT,
          props: {
            canPublish: canPublish,
            canDelete: canDelete,
            onPublish: onPublish,
            onDelete: onDelete,
          },
        },
      });
    };

    const handleOnWantToChangeType = (component: IEditComponent) => {
      const elementClassName = `wrapper-${component && component.id}`;
      addClassTo(elementClassName, EDITING_CLASSNAME);

      dispatch({
        type: SET_EDITOR_STATE,
        payload: {
          existingComponentId: component.id,
          componentToEdit: component,
          isEditType: true,
          newComponentJSON: undefined,
        },
      });

      dispatch({
        type: SET_LEFT_MENU_ACTION,
        payload: {
          open: true,
          innerElementKey: MENU_ACTIONS.ELEMENT_CHOOSER,
          props: {
            canPublish: canPublish,
            canDelete: canDelete,
            onPublish: onPublish,
            onDelete: onDelete,
          },
        },
      });
    };

    const addWrappers = () => {
      const isDefaultPageProperties =
        defaultPageProperties.components?.find(
          (aComponent) => aComponent.id === DEFAULT_ADD_SECTION_ID
        ) !== undefined;

      if (isDefaultPageProperties || hasAddedWrappers) {
        return;
      }

      setHasAddedWrappers(true);
      pageBuilder.setEditableMode({
        onClickCallback: (componentId: string, isLight: boolean) => {
          if (isLight && onAddItemClick) {
            onAddItemClick(componentId);
            return;
          }

          if (onAddSectionClick) {
            onAddSectionClick(componentId);
          }
        },
      });
    };

    const builderOption: IBuildOptions = {
      wrapper: Wrapper,
      wrapperProps: {
        onEdit: handleOnOpenComponent,
        onRemove: (componentId: string) => {
          pageBuilder.remove(componentId);
          dispatch({ type: REFRESH_EDITOR_STATE });
        },
        onEditType: handleOnWantToChangeType,
      },
    };

    pageBuilder.init(
      {
        ...defaultPageProperties,
        menuSide: websiteConfiguration?.configuration.menu.side,
      },
      builderOption
    );
    addWrappers();

    const tempElements = pageBuilder.build();
    setElements(tempElements);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    defaultPageProperties,
    dispatch,
    pageBuilder,
    onAddSectionClick,
    onAddItemClick,
    hasAddedWrappers,
  ]);

  useEffect(() => {
    return () => {
      pageBuilder.initialized = false;
    };
  }, [pageBuilder.initialized]);

  useEffect(() => {
    const tempElements = pageBuilder.build();
    setElements(tempElements);
  }, [editorState.forceRefreshToken, pageBuilder]);

  useEffect(() => setHasAddedWrappers(false), [defaultPageProperties]);

  const fixed = fixRenderer(elements);

  return (
    <div className="page-renderer">
      <LeftMenu
        canPublish={canPublish}
        canDelete={canDelete}
        onPublish={onPublish}
        onDelete={onDelete}
      />

      <section className="page-renderer-container">
        <main>{fixed}</main>
        {/* <main>{elements}</main> */}
      </section>
    </div>
  );
};

export default PageRenderer;
