import React from "react";
import DisplayActionButtons from "./components/DisplayActionButtons";
import PageDefinition from "./components/PageDefinition";

import "./styles.scss";
interface IProps {
  canPublish?: boolean;
  canDelete?: boolean;

  // Handlers.
  onPublish: () => void;
  onDelete?: () => void;
}
const PageSettings: React.FC<IProps> = (props: IProps) => {
  const { canPublish = false, onPublish, canDelete = false, onDelete } = props;
  return (
    <>
      <section className="settings">
        <PageDefinition />
        <div className="displayActionButtons">
          {canDelete && <DisplayActionButtons onDelete={onDelete} />}
          <DisplayActionButtons onPublish={onPublish} disabled={!canPublish} />
        </div>
      </section>
    </>
  );
};

export default PageSettings;
