export const randomString = (length: number = 10): string => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

export const nestedPropsToFlat = (props: any = {}): any => {
  const output: any = {};

  Object.keys(props).forEach((key) => {
    const property = props[key];

    if (typeof property === "object") {
      // Top level only, no need to check deeper.
      Object.keys(property).forEach((nestedKey) => {
        const value = props[key][nestedKey];
        output[`${key}.${nestedKey}`] = value;
      });
    } else {
      output[key] = props[key];
    }
  });

  return output;
};

export const cutString = (str: string, length: number): string => {
  if (str.length <= length) {
    return str;
  }
  return str.substring(0, length) + "...";
};

export const stripHtml = (html: string): string => {
  const tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
};
