import React from "react";
import { Button } from "ffbad-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faUndo } from "@fortawesome/free-solid-svg-icons";

interface IProps {
  onSubmit: () => void;
  onCancel: () => void;
  hideCancelButton: boolean;
}

const Actions: React.FC<IProps> = (props: IProps) => {
  const { onSubmit, onCancel, hideCancelButton } = props;

  return (
    <div className="edit-menu__buttons">
      <Button
        onClick={onSubmit}
        isEditor
        safetySpacing={false}
        title="Sauvegarder les modifications"
      >
        <FontAwesomeIcon icon={faSave as any} />
      </Button>

      {!hideCancelButton && (
        <Button
          onClick={onCancel}
          isEditor
          safetySpacing={false}
          title="Annuler les modifications"
        >
          <FontAwesomeIcon icon={faUndo as any} />
        </Button>
      )}
    </div>
  );
};

export default Actions;
