import React, { useEffect } from "react";

import { ICreateWizardStep04 } from "core/interfaces/createWizard/createWizard.interface";
import { getColorsFromFile, getColorsFromUrl } from "utils/file/file.utils";
import Logo from "./components/Logo";

import "./index.scss";

const { _env } = window as any;
const getEnv = (key: string) =>
  (_env && _env[`REACT_APP_${key}`]) || process.env[`REACT_APP_${key}`] || "";
const api_url = getEnv("API_URL");

interface IProps {
  defaultData: ICreateWizardStep04;
  onChange: (data: ICreateWizardStep04) => void;
  logoUrl?: string;
}

const unableToFetchGraphics = (err: any) => {
  alert(
    "Impossible de récupérer la palette graphique à partir de votre logo..."
  );
  console.log("Error:", err);
};

const Step04: React.FC<IProps> = (props: IProps) => {
  const { logoUrl, onChange } = props;

  const handleOnLogoChange = (newFile: File) => {
    getColorsFromFile(newFile)
      .then((colors) => onChange({ newFile, colors }))
      .catch(unableToFetchGraphics);
  };

  useEffect(() => {
    if (!logoUrl) {
      return;
    }

    const logoUrlDetails = logoUrl.split('/')
    const logoName = logoUrlDetails[logoUrlDetails.length - 1]

    getColorsFromUrl(`${api_url}poona/logo/${logoName}`)
      .then((colors) => onChange({ colors }))
      .catch(unableToFetchGraphics);
    // eslint-disable-next-line
  }, [logoUrl]);

  return (
    <div className="step-container step-container-step04">
      <Logo logoUrl={logoUrl} onChange={handleOnLogoChange} />

      <p>
        Utilisez les couleurs de votre logo ou d’une image du club pour créer
        une palette de couleurs qui sera utilisée pour générer le thème du site
        de votre club.
      </p>
      <p>
        Ces couleurs seront modifiables à tout moment depuis la configuration de
        votre site.
      </p>
    </div>
  );
};

export default Step04;
