import React from "react";

import WizardTitle from "./components/WizardTitle";
import { IWizardStep } from "components/FullscreenWizard";

import "./index.scss";

interface IProps {
  step: IWizardStep;
}

const WizardItem: React.FC<IProps> = (props: IProps) => {
  const { step } = props;

  return (
    <div className="fullscreen-wizard-item">
      <WizardTitle step={step} />

      <div className="fullscreen-wizard-right">{step.content}</div>
    </div>
  );
};

export default WizardItem;
