export const LEFT_MENU_STATE: string = "leftMenuState";
export const CLEAR_LEFT_MENU_STATE: string = "clearLeftMenuState";
export const SET_LEFT_MENU_ACTION: string = "setLeftMenuAction";

export interface ILeftMenuState {
  open: boolean;
  innerElementKey?: string;
  props: {};
}

const initialState: ILeftMenuState = {
  open: false,
  innerElementKey: undefined,
  props: {}
};

const editorReducer = (state: ILeftMenuState = initialState, action: any) => {
  switch (action.type) {
    case LEFT_MENU_STATE:
      return { ...state, ...action.payload };
    case CLEAR_LEFT_MENU_STATE:
      return {
        ...state,
        open: false,
        innerElementKey: null,
        props: {}
      };
    case SET_LEFT_MENU_ACTION:
      return {
        ...state,
        open: true,
        innerElementKey: action.payload.innerElementKey,
        props: action.payload.props
      };
    default:
      return state;
  }
};

export default editorReducer;
