class FileService {
  bypassCorsUrlForLocalOnly: string = "https://cors-anywhere.herokuapp.com/";

  downloadFile = (url: string): Promise<any> => {
    /*const finalUrl =
      process.env.REACT_APP_ENV === "dev"
        ? `${this.bypassCorsUrlForLocalOnly}${url}`
        : url;*/

    return fetch(url);
  };
}

export default FileService;
