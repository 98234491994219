import { KeyCodes } from "core/enums/keyCodes.enum";
import { IWebsiteConfig } from "core/interfaces.config";

export const buildClassName = (
  initialClass: string,
  args: (string | boolean | undefined)[][]
): string => {
  const classNames: string[] = [initialClass];

  if (args.length) {
    args.forEach(([shouldBeAdded, name]) => {
      if (!shouldBeAdded) {
        return;
      }

      classNames.push(name as string);
    });
  }

  return classNames.join(" ");
};

export const isAccessibleButtonKeys = (e: any): boolean => {
  const output = e.keyCode === KeyCodes.ENTER || e.keyCode === KeyCodes.SPACE;
  if (output) {
    e.preventDefault();
  }
  return output;
};

export const isAccessibleCheckboxKeys = (e: any): boolean => {
  const output = e.keyCode === KeyCodes.SPACE;
  if (output) {
    e.preventDefault();
  }
  return output;
};

export const addClassTo = (elementId: string, className: string) => {
  removeClassForMatchingElements(className);

  const element = document.getElementById(elementId);
  if (!element) {
    return;
  }

  element.classList.add(className);
};

export const removeClassTo = (elementId: string, className: string) => {
  const element = document.getElementById(elementId);
  if (!element) {
    return;
  }

  element.classList.remove(className);
};

export const removeClassForMatchingElements = (className: string) => {
  const elements = Array.from(document.getElementsByClassName(className));
  elements.forEach((element) => element.classList.remove(className));
};

export const applyWebsiteConfiguration = (config: IWebsiteConfig) => {
  const {
    configuration: {
      colors: { primary, secondary },
    },
  } = config;

  updateWebsiteColor("primary", primary);
  updateWebsiteColor("secondary", secondary);
};

export const updateWebsiteColor = (
  type: "primary" | "secondary" | "text",
  color: string
) => {
  const documentStyle = document.documentElement.style;
  documentStyle.setProperty(`--color-${type}`, color);
};
