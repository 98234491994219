import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";

import { IFormInput } from "components/FormBuilder/interfaces";

const fields: IFormInput[] = [
  {
    id: "title",
    label: "Nom",
    placeholder: "Saisissez le nom de votre galerie",
    icon: faEdit,
  },
  {
    id: "description",
    label: "Description",
    placeholder: "Saisissez une description (optionnelle)",
    type: "textarea",
    icon: faEdit,
  },
  {
    id: "visible",
    label: "Rendre visible sur le site généré",
    icon: faEye,
    type: "checkbox",
  },
];

export default fields;
