import { ICreateWizard } from "core/interfaces/createWizard/createWizard.interface";
import { MasterService } from "services";
import { IWebsiteConfig } from "core/interfaces.config";
import { IPage } from "services/service.interfaces";

class ConfigService {
  private master: MasterService;

  constructor() {
    this.master = new MasterService();
  }

  getPredefinedPages = (): {
    promise: Promise<{ id: string; label: string; description: string }[]>;
    cancel: any;
  } => {
    return this.master.get<any>(`pages?predefined`);
  };

  setWebsiteConfiguration = (
    data: ICreateWizard,
    instanceId: string
  ): { promise: Promise<any>; cancel: () => void } => {
    const websiteConfiguration = {
      ownerId: instanceId,
      domainNames: data.step01.siteUrl ? [data.step01.siteUrl, data.step01.defaultUrl] : [data.step01.defaultUrl],
      predefinedPages: data.step03.predefinedPages,
      configuration: {
        social: {
          facebook: data.step02.clubFacebook,
          twitter: data.step02.clubTwitter,
          instagram: data.step02.clubInstagram,
        },
        colors: {
          primary: data.step04.colors ? data.step04.colors[0] : "",
          secondary: data.step04.colors ? data.step04.colors[1] : "",
          text: "#000000",
        },
        tonalite: data.step06.tonalite,
        menu: {
          side: data.step07.disposition ? data.step07.disposition.key : "top",
          items: [],
        },
      },
    };

    const { newFile } = data.step04;
    if (newFile) {
      try {
        this.uploadTempFile("club", "update_logo", newFile).then(({ key }) => {
          this.updateLogo(instanceId, key);
        });
      } catch {
        throw new Error("Impossible de changer votre logo.");
      }
    }

    return this.master.post<any>(`websites`, websiteConfiguration);
  };

  getByOwnerId = (
    ownerId: string
  ): {
    promise: Promise<IWebsiteConfig>;
    cancel: any;
  } => {
    return this.master.get<IWebsiteConfig>(`websites/${ownerId}?key=ownerId`);
  };

  findPageByOwnerId = (
    ownerId: string
  ): {
    promise: Promise<IPage[]>;
    cancel: any;
  } => {
    return this.master.get<IPage[]>(`pages?ownerId=${ownerId}`);
  };

  updateWebsiteConfiguration = (
    configuration: IWebsiteConfig
  ): { promise: Promise<IWebsiteConfig>; cancel: any } => {
    return this.master.post<IWebsiteConfig>(
      `websites/${configuration.id}`,
      configuration,
      {},
      "PUT"
    );
  };

  uploadTempFile = (
    key: string,
    fileKey: string,
    file: File
  ): Promise<{ key: string }> => {
    return new Promise((resolve, reject) => {
      this.toBase64(file)
        .then((content) => {
          const body = { key, fileKey, content };

          this.master
            .post<{ key: string }>(`poona/registration/upload`, body, {
              headers: {
                "Content-Type": "application/json",
              },
            })
            .promise.then(({ key }) => resolve({ key }))
            .catch(reject);
        })
        .catch(reject);
    });
  };

  private toBase64 = (file: File): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });
  };

  private updateLogo = (instanceId: string, key: string) => {
    return this.master.post<any>(
      `/poona/instance/${instanceId}/logo`,
      { LogoKey: key },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  };
}

export default ConfigService;
