import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { CLEAR_LEFT_MENU_STATE } from "core/redux/reducer/leftMenu.reducer";
import { ROUTE_KEYS } from "core/router/route.keys";
import usePerson from "hooks/usePerson";
import { buildRoute } from "utils/route.utils";
import { ConfigService } from "services";

import "./index.scss";

interface IProps {}

const service = new ConfigService();
let mounted = false;

const DisplaySelection: React.FC<IProps> = (props: IProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const person = usePerson();
  const [websitePages, setWebsitePages] = useState<
    { id: string; label: string }[]
  >([]);
  const [predefinedPages, setPredefinedPages] = useState<
    { id: string; label: string; description: string }[]
  >([]);

  useEffect(() => {
    mounted = true;
    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    const arrayOfPromises = [
      service.findPageByOwnerId(person.player.instance.instanceId),
      service.getPredefinedPages(),
    ];

    Promise.all(arrayOfPromises.map((a) => a.promise))
      .then((data) => {
        if(!mounted){
          return;
        }
        
        const [tempWebsitePages, tempPredefinedPages] = data;
        setWebsitePages(tempWebsitePages);
        setPredefinedPages(tempPredefinedPages as any);
      })
      .catch((err) => console.error("TODO: error", err));
  }, [person.player.instance.instanceId]);

  const handleOnSelect = (pageId: string, type: "duplicate" | "predefined") => {
    const url = buildRoute(ROUTE_KEYS.CREATE_PAGE, {
      type,
      pageId,
    });

    history.push(url);
    dispatch({ type: CLEAR_LEFT_MENU_STATE });
  };

  return (
    <div className="display-selection-container">
      <CustomLink
        onClick={() => {
          history.push(buildRoute(ROUTE_KEYS.CREATE_NEW_PAGE));
          dispatch({ type: CLEAR_LEFT_MENU_STATE });
        }}
      >
        <FontAwesomeIcon icon={faPlus as any} />
        &nbsp;Nouvelle page
      </CustomLink>

      <h3 className="display-selection-title">Dupliquer page existante</h3>

      {websitePages.map((link) => (
        <CustomLink
          key={link.label}
          onClick={() => handleOnSelect(link.id, "duplicate")}
        >
          <FontAwesomeIcon icon={faPlus as any} />
          &nbsp;{link.label}
        </CustomLink>
      ))}

      <h3 className="display-selection-title">Utiliser une page prédéfinie</h3>

      {predefinedPages.map((link) => (
        <CustomLink
          key={link.label}
          onClick={() => handleOnSelect(link.id, "predefined")}
        >
          <FontAwesomeIcon icon={faPlus as any} />
          &nbsp;{link.label}
        </CustomLink>
      ))}
    </div>
  );
};

const CustomLink: React.FC<any> = (props: any) => {
  const { children, onClick } = props;
  return (
    <div className="display-selection-link" onClick={onClick}>
      {children}
    </div>
  );
};

export default DisplaySelection;
