import React from "react";

import Button from "components/Button";

interface IProps {
    onPublish?: () => void;
    onDelete?: () => void;
    disabled?: boolean;
}

const DisplayActionButtons: React.FC<IProps> = (props: IProps) => {
    const { onPublish, disabled = false, onDelete } = props;

    return (
        <div className="button-container">
            {onDelete && <Button type="danger" onClick={onDelete} >
                Supprimer
            </Button>}
            {onPublish && <Button onClick={onPublish} disabled={disabled}>
                Sauvegarder
            </Button>}
        </div>
    );
};

export default DisplayActionButtons;
