import React, { useState, useEffect } from "react";

import { ICreateWizardStep07 } from "core/interfaces/createWizard/createWizard.interface";
import Layout from "./component/Layout";

import "./index.scss";

interface IProps {
  defaultData: ICreateWizardStep07;
  onChange: (data: ICreateWizardStep07) => void;
}

const DISPOSITIONS = [
  {
    key: "top",
    title: "Horizontal",
    image: "/images/layout-horizontal.png",
  },
  {
    key: "left",
    title: "Vertical",
    image: "/images/layout-vertical.png",
  },

];
const DEFAULT_SELECTED_DISPOSITION = DISPOSITIONS[0].key;

const Step07: React.FC<IProps> = (props: IProps) => {
  const { defaultData, onChange } = props;
  const [selectedDisposition, setSelectedDisposition] = useState(
    DEFAULT_SELECTED_DISPOSITION
  );

  useEffect(() => {
    if (!defaultData || !defaultData.disposition) {
      return;
    }

    setSelectedDisposition(defaultData.disposition.key);
  }, [defaultData]);

  const handleOnSelect = (disposition: any) => {
    setSelectedDisposition(disposition.key);
    onChange({ disposition });
  };

  return (
    <div className="step-container step-container-step07">
      {DISPOSITIONS.map((disposition) => (
        <Layout
          {...disposition}
          selected={selectedDisposition === disposition.key}
          onSelect={() => handleOnSelect(disposition)}
        />
      ))}
    </div>
  );
};

export default Step07;
