import React from "react";
import Button from "components/Button";
import { useHistory } from "react-router-dom";

import { ROUTE_KEYS, ROUTE_PATHS } from "core/router/routes.config";
import { LocalStorageService } from "services";
import { isPrivateRoute } from "hooks";
import "./index.scss";

interface IProps { }
const localStorageService = new LocalStorageService();

const Logout: React.FC<IProps> = (props: IProps) => {
  const history = useHistory();
  isPrivateRoute(history);

  const handleOnLogOut = () => {
    localStorageService.signOut()
    history.push(ROUTE_PATHS[ROUTE_KEYS.LOGIN]);
  }

  return (
    <div className="logout">
      <Button onClick={handleOnLogOut} >Déconnexion</Button>
    </div>
  );
};

export default Logout;
