import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import { isAccessibleButtonKeys } from "utils/dom/dom.utils";
import WizardItem from "./components/WizardItem";
import BottomNavigation from "./components/BottomNavigation";

import "./index.scss";
import { LocalStorageService } from "services";
import { useHistory } from "react-router-dom";
import { isPrivateRoute } from "hooks";
import { ROUTE_KEYS, ROUTE_PATHS } from "core/router/routes.config";

interface IProps {
  index?: number;
  steps: IWizardStep[];
}

export interface IWizardStep {
  title: string;
  description?: string;
  info?: string;
  content: any;
  canSkipStep?: boolean;
  nextButtonLabel?: string;
  canClickOnNextButton?: () => boolean;
  hideLayout?: boolean;
}

const DEFAULT_STEP_INDEX = 0;
const localStorageService = new LocalStorageService();

const FullscreenWizard: React.FC<IProps> = (props: IProps) => {
  const { steps, index = DEFAULT_STEP_INDEX } = props;
  const [currentStepIndex, setCurrentStepIndex] = useState(index);
  const [currentStep, setCurrentStep] = useState(steps[0]);
  const history = useHistory();
  isPrivateRoute(history);

  useEffect(() => {
    setCurrentStep(steps[currentStepIndex]);
  }, [steps, currentStepIndex]);

  const handleOnNext = () => {
    const step =
      currentStepIndex + 1 > steps.length - 1
        ? steps.length - 1
        : currentStepIndex + 1;
    setCurrentStepIndex(step);
  };

  const handleOnLogOut = () => {
    localStorageService.signOut()
    history.push(ROUTE_PATHS[ROUTE_KEYS.LOGIN]);
  }

  const handleOnPrevious = () => {
    const step = currentStepIndex - 1 < 0 ? 0 : currentStepIndex - 1;
    if (currentStepIndex === 0) {
      handleOnLogOut();
    }
    setCurrentStepIndex(step);
  };

  if (currentStep && currentStep.hideLayout) {
    return <>{currentStep.content}</>;
  }

  return (
    <div className="fullscreen-wizard-container">
      <div className="fullscreen-wizard-logo" />

      <WizardItem step={currentStep} />

      <div
        role="button"
        tabIndex={0}
        className="fullscreen-wizard-back"
        onClick={handleOnPrevious}
        onKeyDown={e => {
          if (isAccessibleButtonKeys(e)) {
            handleOnPrevious();
          }
        }}
      >
        <FontAwesomeIcon icon={faChevronLeft as any} />
        &nbsp;Retour
      </div>

      <BottomNavigation
        canSkipStep={currentStep.canSkipStep}
        onNext={handleOnNext}
        nextButtonLabel={currentStep.nextButtonLabel}
        canClickOnNextButton={
          currentStep.canClickOnNextButton
            ? currentStep.canClickOnNextButton()
            : true
        }
      />
    </div>
  );
};

export default FullscreenWizard;
