import React, { useEffect, useState } from "react";
import { IPage } from "services/service.interfaces";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { ConfigService } from "services";

interface IProps {
    ownerId: string;
    setSelectedPageId: (pageId: string) => void;
    currentPageId: string;
}
const configService = new ConfigService();

const SelectNewHome: React.FC<IProps> = (props: IProps) => {
    const { ownerId, setSelectedPageId, currentPageId } = props
    const [pages, setPages] = useState<IPage[]>();

    useEffect(() => {
        const { promise } = configService.findPageByOwnerId(ownerId);
        promise.then((pages: IPage[]) => {
            setPages(pages.filter((page) => page.id !== currentPageId));
            if (pages[0]) {
                setSelectedPageId(pages[0].id);
            }
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnPageChange = (event: any) => {
        setSelectedPageId(event.target.value)
    }

    return (
        <>
            <p>
                <FontAwesomeIcon icon={faExclamationTriangle as any} />
                &nbsp;Vous allez supprimer la page d'accueil de votre site.<br />
                Si c'est bien votre intention alors choisissez une nouvelle page<br /> qui la remplacera puis appuyez sur "OK!".
                <br />Sinon fermez cette modale.
            </p>
            <select onChange={handleOnPageChange}>
                {pages?.map(page => {
                    return <option key={page.id} value={page.id}>{page.label}</option>
                })}
            </select>
        </>
    );
};

export default SelectNewHome;
